import { forEach as _forEach, map as _map } from 'lodash'

import Entity from '_entities/base/Entity'

import ServiceElementCalendarEvent from '_entities/service/ServiceElementCalendarEvent'
import ServiceElementCatalog from '_entities/service/ServiceElementCatalog'
import ServiceElementCheckbox from '_entities/service/ServiceElementCheckbox'
import ServiceElementCheckboxTag from '_entities/service/ServiceElementCheckboxTag'
import ServiceElementDate from '_entities/service/ServiceElementDate'
import ServiceElementDateTime from '_entities/service/ServiceElementDateTime'
import ServiceElementDropdown from '_entities/service/ServiceElementDropdown'
import ServiceElementFile from '_entities/service/ServiceElementFile'
import ServiceElementPushMessage from '_entities/service/ServiceElementPushMessage'
import ServiceElementRadio from '_entities/service/ServiceElementRadio'
import ServiceElementRadioWithImage from '_entities/service/ServiceElementRadioWithImage'
import ServiceElementSendMessage from '_entities/service/ServiceElementSendMessage'
import ServiceElementText from '_entities/service/ServiceElementText'
import ServiceElementTextInput from '_entities/service/ServiceElementTextInput'
import ServiceElementTime from '_entities/service/ServiceElementTime'
import ServiceElementTitle1 from '_entities/service/ServiceElementTitle1'
import ServiceElementTitle2 from '_entities/service/ServiceElementTitle2'
import ServiceElementUploadImage from '_entities/service/ServiceElementUploadImage'
import ServiceElementUploadFiles from '_entities/service/ServiceElementUploadFiles'
import ServiceSubsection from '_entities/service/ServiceSubsection'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'

import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketTypeLink from '_entities/ticket/TicketTypeLink'

class Service extends Entity {
    get schema() {
        return {
            buildings: ['buildings', [], (value) => value.map((item) => this.getEntityValue(BuildingLink, item))],
            companies: ['companies', [], (value) => value.map((item) => this.getEntityValue(CompanyLink, item))],
            id: ['id', undefined, (value) => value], // UUID
            image: ['image', undefined, (value) => this.getEntityValue(Photo, value)],
            imageDetail: ['image_detail', undefined, (value) => this.getEntityValue(Photo, value)],
            isActive: ['is_active', (value) => !!value], // Boolean
            isNew: ['is_new', undefined, (value) => !!value], // Boolean
            isRated: ['is_rated', undefined, (value) => !!value], // Boolean
            name: ['name', undefined, (value) => value], // String
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
            serviceType: ['service_type', undefined, (value) => value], // String
            showTicketDatePlannedCompletion: ['show_ticket_date_planned_completion', false, (value) => !!value],
            subsections: ['subsections', [], (value) => value.map((item) => this.getEntityValue(ServiceSubsection, item))],
            ticketDepartment: ['ticket_department', undefined, (value) => this.getEntityValue(TicketDepartmentLink, value)],
            ticketKind: ['ticket_kind', undefined, (value) => this.getEntityValue(TicketKindLink, value)],
            ticketType: ['ticket_type', undefined, (value) => this.getEntityValue(TicketTypeLink, value)],
            // Info
            description: ['description', undefined, (value) => value], // String
            documents: ['documents', [], (value) => value.map((item) => this.getEntityValue(Doc, item))],
            imageGallery: ['image_gallery', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
            subtitle: ['subtitle', undefined, (value) => value], // String
            // Constructor
            companyReceiver: ['company_receiver', undefined, (value) => this.getEntityValue(CompanyLink, value)],
            elements: ['elements', [], (value) => value.map((item) => this.getElementValue(item))],
            isAutoInvoice: ['is_auto_invoice', undefined, (value) => !!value], // Boolean
            paymentRequired: ['payment_required', undefined, (value) => !!value], // Boolean
            waitingTimePayment: ['waiting_time_payment', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        companies: 'Компании',
        id: 'ID',
        image: 'Изображение',
        imageDetail: 'Изображение (детально)',
        isActive: 'Активен',
        isNew: 'Новинка',
        isRated: 'Учитывать в CSI',
        name: 'Название',
        order: 'Порядок',
        serviceType: 'Тип',
        showTicketDatePlannedCompletion: 'Передавать дату планируемого выполнения в МПЖ',
        subsections: 'Разделы',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketType: 'Тип',
        // Info
        description: 'Описание',
        documents: 'Документы',
        imageGallery: 'Галерея',
        subtitle: 'Подзаголовок',
        // Constructor
        companyReceiver: 'Компания получатель платежа',
        elements: 'Элементы',
        isAutoInvoice: 'Порядок формирования счета',
        paymentRequired: 'Требуется оплата',
        waitingTimePayment: 'Время ожидания оплаты, минут',
    }

    static invoiceVariants = [
        { text: 'Счет выставляется администратором', value: false },
        { text: 'Счет выставляется автоматически', value: true },
    ]

    getElementValue(item) {
        switch (item.element_type) {
            case 'calendar_event':
                return new ServiceElementCalendarEvent(item)
            case 'catalog':
                return new ServiceElementCatalog(item)
            case 'checkbox':
                return new ServiceElementCheckbox(item)
            case 'checkbox_tag':
                return new ServiceElementCheckboxTag(item)
            case 'date':
                return new ServiceElementDate(item)
            case 'datetime':
                return new ServiceElementDateTime(item)
            case 'dropdown':
                return new ServiceElementDropdown(item)
            case 'files':
                return new ServiceElementFile(item)
            case 'radiobutton':
                return new ServiceElementRadio(item)
            case 'radiobutton_with_image':
                return new ServiceElementRadioWithImage(item)
            case 'text':
                return new ServiceElementText(item)
            case 'textinput':
                return new ServiceElementTextInput(item)
            case 'time':
                return new ServiceElementTime(item)
            case 'title_1':
                return new ServiceElementTitle1(item)
            case 'title_2':
                return new ServiceElementTitle2(item)
            case 'upload_images':
                return new ServiceElementUploadImage(item)
            case 'upload_files':
                return new ServiceElementUploadFiles(item)
            case 'push_message':
                return new ServiceElementPushMessage(item)
            case 'send_message':
                return new ServiceElementSendMessage(item)
        }
    }

    toServer() {
        const info = {}

        if (this.serviceType === 'info') {
            info.subtitle = this.subtitle
            info.description = this.description
            info.image_gallery = _map(this.imageGallery, (item) => item.id)
            info.documents = _map(this.documents, (item) => item.id)
        }

        const constructor = {}

        if (this.serviceType === 'constructor') {
            constructor.company_receiver = this.companyReceiver?.id
            constructor.is_auto_invoice = this.isAutoInvoice
            constructor.payment_required = this.paymentRequired
            constructor.waiting_time_payment = this.waitingTimePayment

            const elements = []
            _forEach(this.elements, (element, index) => {
                // TODO: element.toServer(element) > element.toServer()
                element.order = index + 1
                elements.push(element.toServer(element))
            })
            constructor.elements = elements
        }

        return {
            buildings: _map(this.buildings, (item) => item.id),
            companies: _map(this.companies, (item) => item.id),
            image: this.image?.id,
            image_detail: this.imageDetail?.id,
            is_active: !!this.isActive,
            is_new: !!this.isNew,
            is_rated: !!this.isRated,
            name: this.name,
            order: this.order,
            service_type: this.serviceType,
            show_ticket_date_planned_completion: this.showTicketDatePlannedCompletion,
            subsections: _map(this.subsections, (item) => item.id),
            ticket_department: this.ticketDepartment?.id || null,
            ticket_kind: this.ticketKind?.id || null,
            ticket_type: this.ticketType?.id || null,
            // Info
            ...info,
            // Constructor
            ...constructor,
        }
    }

    get filterName() {
        const companies = _map(this.companies, (company) => {
            return company.name
        })

        const buildings = _map(this.buildings, (building) => {
            return building.name
        })
        let name = this.name
        if (companies.length) {
            name += ` (${companies.join(', ')})`
        }
        if (buildings.length) {
            name += ` (${buildings.join(', ')})`
        }

        return name
    }
}

export default Service
