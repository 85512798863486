import { request } from '_api/base/behemoth/client'

export const createTicketType = async (params) => {
    return await request('POST', 'ticket/type/', params)
}

export const deleteTicketType = async (ticketTypeId) => {
    return await request('DELETE', `ticket/type/${ticketTypeId}/`)
}

export const getTicketTypes = async (params) => {
    return await request('GET', 'ticket/type/', params)
}

export const getTicketTypeById = async (ticketTypeId) => {
    return await request('GET', `ticket/type/${ticketTypeId}/`)
}

export const updateTicketType = async (ticketTypeId, params) => {
    return await request('PUT', `ticket/type/${ticketTypeId}/`, params)
}
