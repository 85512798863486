import Entity from '_entities/base/EntityNew'

export const BuildingLinkSchema = {
    name: 'BuildingLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        shortName: { type: 'string' },
    },
}

class BuildingLink extends Entity {
    get schema() {
        return BuildingLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        shortName: 'Короткое название',
    }

    get dataMap() {
        return {
            short_name: 'shortName',
        }
    }

    get displayName() {
        return this.shortName || this.name
    }
}

export default BuildingLink
