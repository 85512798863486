import Entity from '_entities/base/Entity'

class ServiceSection extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            name: ['name', undefined, (value) => value], // String
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }

    toServer() {
        return {
            name: this.name,
            order: this.order,
        }
    }
}

export default ServiceSection
