import { getSectionById } from '_api/services/section'
import { request } from '_api/base/behemoth/client'

import TicketRoute from '_entities/ticket/TicketRoute'

//
// Routes
// -----------------------------------------------------------------------------
export const addTicketRoute = async (params) => {
    return await request('POST', 'router/ticket_route/', params)
}

export const editTicketRoute = async (routeId, params) => {
    return await request('PUT', `router/ticket_route/${routeId}/`, params)
}

export const getTicketRouteById = async (routeId) => {
    let ticketRoute = null
    const response = await request('GET', `router/ticket_route/${routeId}/`)

    if (response) {
        // заполняем подъезды полными сущностями, поскольку на их данных производится восствновление этажности при загрузке маршрута не редактирование
        if (response.section_values.length > 0) {
            for (let el = 0; el < response.section_values.length; el++) {
                const sectionId = response.section_values[el]?.id

                if (sectionId) {
                    const someSection = await getSectionById(sectionId)
                    if (someSection) {
                        response.section_values[el] = someSection
                    }
                }
            }
        }

        ticketRoute = new TicketRoute(response)
    }

    return ticketRoute
}

export const deleteTicketRoute = async (routeId) => {
    return await request('DELETE', `router/ticket_route/${routeId}/`)
}

export const getTicketRoutes = async (params) => {
    return await request('GET', 'router/ticket_route/', params)
}
