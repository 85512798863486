import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/Entity'

import Photo from '_entities/media/Photo'
import TicketDepartment from '_entities/ticket/TicketDepartment'
import TicketKind from '_entities/ticket/TicketKind'
import TicketType from '_entities/ticket/TicketType'

class ServiceElementVariant extends Entity {
    get schema() {
        return {
            id: ['id', uuidV4(), (value) => value], // UUID
            image: ['image', undefined, (value) => new Photo(value)], // Photo
            order: ['order', undefined, (value) => this.getIntegerValue(value)], // Integer
            ticketDepartment: ['ticket_department', undefined, (value) => new TicketDepartment(value)],
            ticketKind: ['ticket_kind', undefined, (value) => new TicketKind(value)],
            ticketType: ['ticket_type', undefined, (value) => new TicketType(value)],
            title: ['title', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        id: 'ID',
        image: 'Изображение',
        order: 'Порядок',
        ticketDepartment: 'Отдел',
        ticketKind: 'Вид',
        ticketType: 'Тип',
        title: 'Заголовок',
    }

    toServer(data) {
        return {
            id: data?.id,
            title: data?.title,
            order: data?.order,
            image: data?.image?.id,
            ticket_department: data.ticketDepartment?.id || null,
            ticket_kind: data.ticketKind?.id || null,
            ticket_type: data.ticketType?.id || null,
        }
    }
}

export default ServiceElementVariant
