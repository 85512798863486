import Entity from '_entities/base/Entity'

import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'

class StoryElement extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            title: ['title', undefined, (value) => value], // String
            text: ['text', undefined, (value) => value], // String
            buttonName: ['button_name', undefined, (value) => value],
            entity: ['entity', undefined, (value) => value],
            entityId: ['entity_id', undefined, (value) => value],
            link: ['link', undefined, (value) => value], // String
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
            storyType: ['story_type', undefined, (value) => value], // String
            image: ['image', undefined, (value) => this.getEntityValue(Photo, value)],
            video: ['video', undefined, (value) => this.getEntityValue(Doc, value)],
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        text: 'Текст',
        buttonName: 'Наименование кнопки',
        entity: 'Наименование сущности',
        entityId: 'ID сущности',
        link: 'Ссылка',
        order: 'Сортировка',
        storyType: 'Тип',
        image: 'Изображение',
        video: 'Видео',
    }

    static storyTypes = [
        {
            code: 'informational',
            text: 'Информационный',
        },
        {
            code: 'service',
            text: 'Сервис',
        },
        {
            code: 'news',
            text: 'Новость',
        },
        {
            code: 'link',
            text: 'Ссылка',
        },
        {
            code: 'video',
            text: 'Видео',
        },
        {
            code: 'poll',
            text: 'NPS опрос',
        },
    ]

    toServer() {
        return {
            title: this.title,
            text: this.text,
            button_name: this.buttonName,
            entity_id: this.entityId,
            entity: this.storyType === 'service' || this.storyType === 'news' ? this.storyType : null,
            link: this.link,
            order: this.order,
            story_type: this.storyType,
            image: this.image?.id,
            video: this.video?.id,
        }
    }
}

export default StoryElement
