import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import PipeLink from '_entities/pipe/PipeLink'
import PremiseLink from '_entities/premise/PremiseLink'
import SectionLink from '_entities/section/SectionLink'

export const PushMessageLocationSchema = {
    name: 'PushMessageLocation',
    properties: {
        floors: { type: 'integer[]', default: [] },
        pipes: { type: 'PipeLink[]', entity: PipeLink, default: [] },
        premises: { type: 'PremiseLink[]', entity: PremiseLink, default: [] },
        sections: { type: 'SectionLink[]', entity: SectionLink, default: [] },
    },
}

class PushMessageLocation extends Entity {
    get schema() {
        return PushMessageLocationSchema
    }

    static fieldLabels = {
        floors: 'Этажи',
        pipes: 'Стояки',
        premises: 'Помещения',
        sections: 'Подъезды',
    }

    toArray() {
        return {
            floors: this.floors ? this.floors : [],
            pipes: this.pipes ? _map(this.pipes, (pipe) => pipe.id) : [],
            premises: this.premises ? _map(this.premises, (premise) => premise.id) : [],
            sections: this.sections ? _map(this.sections, (section) => section.id) : [],
        }
    }
}

export default PushMessageLocation
