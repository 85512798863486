import API from '_api'

import PushMessage from '_entities/pushMessage/PushMessage'

const actions = {
    async fetchPushMessages({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchPushMessagesRequest')

            try {
                const response = await API.services.newsletter.getPushMessages(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new PushMessage(result))
                })

                commit('fetchPushMessagesSuccess', { items })
            } catch (e) {
                commit('fetchPushMessagesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
