import Entity from '_entities/base/EntityNew'

import BuildingLink from '_entities/building/BuildingLink'
import Photo from '_entities/media/Photo'
import PipeLink from '_entities/pipe/PipeLink'
import PremiseTypeLink from '_entities/premise/PremiseTypeLink'
import ProjectLink from '_entities/project/ProjectLink'
import SectionLink from '_entities/section/SectionLink'

export const PremiseSchema = {
    name: 'Premise',
    primaryKey: 'id',
    properties: {
        building: { type: 'BuildingLink', entity: BuildingLink },
        extId: { type: 'string' },
        finishingType: { type: 'string' },
        floor: { type: 'int' },
        fullArea: { type: 'float' },
        id: { type: 'string' },
        isCornerWindows: { type: 'bool' },
        isDuplex: { type: 'bool' },
        isFrontGarden: { type: 'bool' },
        isHighCeiling: { type: 'bool' },
        isPanoramic: { type: 'bool' },
        isView: { type: 'bool' },
        layout: { type: 'Photo', entity: Photo },
        name: { type: 'string' },
        number: { type: 'string' },
        order: { type: 'int' },
        pipes: { type: 'PipeLink[]', entity: PipeLink },
        premiseType: { type: 'PremiseTypeLink', entity: PremiseTypeLink },
        premiseTypeShort: { type: 'string' },
        project: { type: 'ProjectLink', entity: ProjectLink },
        redecoratingType: { type: 'string' },
        roomsCount: { type: 'int' },
        shortName: { type: 'string' },
        section: { type: 'SectionLink', entity: SectionLink },
    },
}
class Premise extends Entity {
    get schema() {
        return PremiseSchema
    }

    get dataMap() {
        return {
            ext_id: 'extId',
            finishing_type: 'finishingType',
            full_area: 'fullArea',
            is_front_garden: 'isFrontGarden',
            is_corner_windows: 'isCornerWindows',
            is_duplex: 'isDuplex',
            is_high_ceiling: 'isHighCeiling',
            is_panoramic: 'isPanoramic',
            is_view: 'isView',
            rooms_count: 'roomsCount',
            premise_type: 'premiseType',
            premise_type_short: 'premiseTypeShort',
            redecorating_type: 'redecoratingType',
            short_name: 'shortName',
        }
    }

    static fieldLabels = {
        building: 'Здание',
        extId: 'Внешний ID',
        finishingType: 'Тип отделки',
        floor: 'Этаж',
        fullArea: 'Полная площадь',
        id: 'ID',
        isCornerWindows: 'Угловые окна',
        isDuplex: 'Двухуровневое помещение',
        isFrontGarden: 'Палисадник',
        isHighCeiling: 'Высокий потолок',
        isPanoramic: 'Панорамное помещение',
        isView: 'Видовое помещение',
        layout: 'Планировка',
        name: 'Адрес',
        number: 'Номер',
        order: 'Порядок сортировки',
        pipes: 'Стояки',
        premiseType: 'Тип помещения',
        project: 'ЖК',
        redecoratingType: 'Тип ремонта',
        roomsCount: 'Кол-во комнат',
        section: 'Секция',
        shortName: 'Короткий адрес',
    }
}

export default Premise
