import Entity from '_entities/base/Entity'

class CompanyRequisite extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            inn: ['inn', undefined, (value) => value], // String
            kpp: ['kpp', undefined, (value) => value], // String
            bic: ['bic', undefined, (value) => value], // String
            fullName: ['full_name', undefined, (value) => value], // String
            shortName: ['short_name', undefined, (value) => value], // String
            nameAccount: ['name_account', undefined, (value) => value], // String
            legalAddress: ['legal_address', undefined, (value) => value], // String
            checkingAccount: ['checking_account', undefined, (value) => value], // String
            correspondentAccount: ['correspondent_account', undefined, (value) => value], // String
            bankName: ['bank_name', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        id: 'ID',
        inn: 'ИНН',
        kpp: 'КПП',
        bic: 'БИК',
        fullName: 'Полное наименование',
        shortName: 'Короткое наименование',
        nameAccount: 'Название счета',
        legalAddress: 'Юр. адрес',
        checkingAccount: 'Расчетный счет',
        correspondentAccount: 'к/с',
        bankName: 'Название банка',
    }

    toServer(data) {
        return {
            id: data?.id,
            inn: data?.inn,
            kpp: data?.kpp,
            bic: data?.bic,
            full_name: data?.fullName,
            short_name: data?.shortName,
            name_account: data?.nameAccount,
            legal_address: data?.legalAddress,
            checking_account: data?.checkingAccount,
            correspondent_account: data?.correspondentAccount,
            bank_name: data?.bankName,
        }
    }
}

export default CompanyRequisite
