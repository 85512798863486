<template>
    <UIHorizontalScroll>
        <v-data-table
            :headers="headers"
            :items="pushMessages"
            :loading="isFetching"
            :options.sync="options"
            :server-items-length="total"
        >
            <template v-slot:item.actions="{ item }">
                <div class="ml-5">
                    <v-btn
                        class="mr-2"
                        icon
                        title="Создать по образцу"
                        @click="copyItem(item.id, true)"
                    >
                        <v-icon v-text="'$clone'" />
                    </v-btn>
                    <v-btn
                        class="mr-2"
                        icon
                        title="Редактировать"
                        @click="editItem(item.id)"
                    >
                        <v-icon v-text="'$edit'" />
                    </v-btn>
                </div>
            </template>

            <template v-slot:item.date="{ item }">
                <div class="text-no-wrap">
                    {{ getDateFormatted(item.date) }}
                </div>
            </template>

            <template v-slot:item.type="{ item }">
                <div class="text-no-wrap">
                    {{ getTypeText(item.type) }}
                </div>
            </template>

            <template v-slot:item.company="{ item }">
                <div v-if="item.company" class="text-no-wrap">
                    {{ item.company.name }}
                </div>
            </template>

            <template v-slot:item.messageAuthor="{ item }">
                <div v-if="item.messageAuthor" class="text-no-wrap">
                    {{ item.messageAuthor.fullName }}
                </div>
            </template>
        </v-data-table>
    </UIHorizontalScroll>
</template>

<script>
    import { find as _find, forEach as _forEach } from 'lodash'

    import API from '_api'

    import PushMessage from '_entities/pushMessage/PushMessage'

    import { formatDate } from '@/plugins/moment'

    import UIHorizontalScroll from '_ui/layout/UIHorizontalScroll'

    export default {
        name: 'CPushMessageDataTable',

        components: {
            UIHorizontalScroll,
        },

        data: () => ({
            pushMessages: [],
            errors: [],
            isFetching: false,
            options: {
                page: 1,
                itemsPerPage: 15,
            },
            total: 0,
        }),

        computed: {
            fieldLabels() {
                return PushMessage.fieldLabels
            },

            headers() {
                const headers = [
                    { text: 'Действия', value: 'actions' },
                    { value: 'title' },
                    { value: 'date' },
                    { value: 'type' },
                    { value: 'company' },
                    { value: 'messageAuthor' },
                ]

                _forEach(headers, (header) => {
                    if (!header.text) {
                        header.text = this.fieldLabels[header.value]
                    }
                })

                return headers
            },
        },

        watch: {
            options: {
                handler() {
                    this.fetchData()
                },
                deep: true,
            },
        },

        mounted() {
            this.fetchData()
        },

        methods: {
            fetchData() {
                if (!this.isFetching) {
                    this.isFetching = true

                    const { itemsPerPage, page, sortBy, sortDesc } = this.options

                    const params = {
                        page,
                        page_size: itemsPerPage,
                    }

                    if (sortBy?.length) {
                        params.ordering = (sortDesc[0] ? '-' : '') + sortBy[0]
                    }

                    API.services.newsletter.getPushMessages(params)
                        .then((response) => {
                            const { results, count } = response

                            const pushMessages = []
                            results.forEach((result) => {
                                pushMessages.push(new PushMessage(result))
                            })

                            this.total = count
                            this.pushMessages = pushMessages
                        })
                        .catch((e) => {
                            this.errors = e
                        })
                        .finally(() => {
                            this.isFetching = false
                        })
                }
            },

            getDateFormatted(unix) {
                return formatDate(unix, 'LLL')
            },

            getTypeText(typeCode) {
                const type = _find(PushMessage.types, (item) => item.code === typeCode)

                return type?.name
            },

            editItem(pushMessageId) {
                this.$router.push({
                    name: 'NewslettersPushEdit',
                    params: {
                        pushMessageId,
                    },
                })
            },

            copyItem(pushMessageId, isCopy) {
                this.$router.push({
                    name: 'NewslettersPushEdit',
                    params: {
                        pushMessageId,
                        isCopy,
                    },
                })
            },
        },
    }
</script>
