import API from '_api'

import Section from '_entities/section/Section'

const actions = {
    async fetchSections({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchSectionsRequest')

            try {
                const response = await API.services.section.getSections(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new Section(result))
                })

                commit('fetchSectionsSuccess', { items })
            } catch (e) {
                commit('fetchSectionsError', e)
            }
        }
    },
}

export default actions
