import Vue from 'vue'

const mutations = {
    fetchTicketTypesClear(state) {
        state.items = {}
    },

    fetchTicketTypesRequest(state) {
        state.isFetching = true
    },

    fetchTicketTypesData(state, { items }) {
        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchTicketTypesSuccess(state) {
        state.isFetching = false
        state.errors = []
    },

    fetchTicketTypesError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    addedTicketType(state, item) {
        Vue.set(state.items, item.id, item)
    },

    removeTicketType(state, item) {
        Vue.delete(state.items, item.id)
    },
}

export default mutations
