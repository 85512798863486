import Entity from '_entities/base/EntityNew'

export const ProjectLinkSchema = {
    name: 'ProjectLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
    },
}

class ProjectLink extends Entity {
    get schema() {
        return ProjectLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
    }
}

export default ProjectLink
