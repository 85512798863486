import Entity from '_entities/base/Entity'
import Photo from '_entities/media/Photo'
import ServiceDiscountCard from '_entities/service/ServiceDiscountCard'

class ServicePromotion extends Entity {
    get schema() {
        return {
            discountCard: ['discount_card', undefined, (value) => this.getEntityValue(ServiceDiscountCard, value)],
            id: ['id', undefined, (value) => value], // UUID
            originalImage: ['original_image', undefined, (value) => this.getEntityValue(Photo, value)],
            previewImage: ['preview_image', undefined, (value) => this.getEntityValue(Photo, value)],
        }
    }

    static fieldLabels = {
        discountCard: 'Дисконтная карта',
        id: 'ID',
        originalImage: 'Оригинал',
        previewImage: 'Анонс',
    }

    toServer() {
        return {
            discount_card: this.discountCard?.id,
            original_image: this.originalImage?.id,
            preview_image: this.previewImage?.id,
        }
    }
}

export default ServicePromotion
