import getters from './getters'

const state = {
    isFetching: false,
    errors: [],
    items: {
        builder: {
            id: 'builder',
            name: 'Застройщик',
        },
        service_company: {
            id: 'service_company',
            name: 'Управляющая компания',
        },
    },
}

export default {
    namespaced: true,
    state,
    getters,
}
