<template>
    <v-app :style="{background: $vuetify.theme.themes[theme].background}">
        <template v-if="isInitializing">
            <CContainer center fluid class="secondary">
                <v-progress-circular indeterminate color="white" />
            </CContainer>
        </template>
        <template v-else>
            <CNavigationDrawer v-if="isHomeScreen" />

            <CSystemBar />

            <CAppBar v-if="isHomeScreen" />

            <FNotificationCenter v-if="isHomeScreen" />

            <v-main>
                <router-view />
            </v-main>

            <CSnackbar />

            <v-dialog v-model="offLine" persistent max-width="290">
                <v-card>
                    <v-card-title class="headline">Network error</v-card-title>

                    <v-card-text>Приложение продолжит работу после соединения с Интернетом.</v-card-text>
                </v-card>
            </v-dialog>

            <FIncomingCallDialog />
        </template>
    </v-app>
</template>

<script>
    import { mapState } from 'vuex'

    import CAppBar from '_common/components/CAppBar'
    import CContainer from '_common/components/CContainer'
    import CNavigationDrawer from '_common/components/CNavigationDrawer'
    import CSnackbar from '_common/components/CSnackbar'
    import CSystemBar from '_common/components/CSystemBar'

    import FIncomingCallDialog from '_features/incomingCall/components/FIncomingCallDialog'
    import FNotificationCenter from '_features/notificationCenter/components/FNotificationCenter'

    export default {
        name: 'App',

        components: {
            CAppBar,
            CContainer,
            CNavigationDrawer,
            CSnackbar,
            CSystemBar,
            FIncomingCallDialog,
            FNotificationCenter,
        },

        data: () => ({
            isInitializing: true,
            isPermissionsChecked: false,
            isDataLoaded: false,
            routeFixTimerId: null,
        }),

        computed: {
            ...mapState('global', {
                offLine: (state) => !state.onLine,
            }),

            ...mapState('auth', {
                isSignedOut: (state) => !!state.isSignedOut,
                isAuthorized: (state) => !!state.accessToken,
                userId: (state) => state.userId,
            }),

            theme() {
                return this.$vuetify.theme.dark ? 'dark' : 'light'
            },

            isAuthScreen() {
                return this.$route.matched.some((route) => route.name === 'Auth')
            },

            isHomeScreen() {
                return this.$route.matched.some((route) => route.name === 'Home')
            },

            isEmptyRoute() {
                // В некоторых случаях переадресация на новой вкладке ломается.
                // В этом случае isHomeScreen определяется некорректно и переадресацию на домашнюю страницу делать не нужно.
                // TODO: Исправить мистическое поведение Vue Router
                return this.$route.name === null
            },
        },

        watch: {
            isInitializing: {
                handler(isInitializing) {
                    if (!isInitializing) {
                        this.checkAuthorization()
                    }
                },
                immediate: true,
            },
            isSignedOut: {
                handler(isSignedOut) {
                    if (isSignedOut) {
                        this.toAuth()
                    }
                },
                immediate: true,
            },
            isAuthorized: {
                handler() {
                    if (this.isAuthorized) {
                        console.log('ar')
                        this.loadData()
                    }
                },
                immediate: true,
            },

            isDataLoaded: {
                handler(isDataLoaded) {
                    if (isDataLoaded) {
                        console.log('dl')
                        this.checkPermissions()
                    }
                },
                immediate: true,
            },

            isPermissionsChecked: {
                handler(isPermissionsChecked) {
                    if (isPermissionsChecked) {
                        console.log('pch')
                        this.isInitializing = false
                    }
                },
                immediate: true,
            },
        },

        // Application initialization. Step 2 - App created
        created() {
            this.bootstrap()
        },

        destroyed() {
            clearTimeout(this.routeFixTimerId)
        },

        methods: {
            async bootstrap() {
                // Application initialization. Step 3 - Listen Software Update
                // await this.listenSoftwareUpdate()
                // Application initialization. Step 4 - Listen OnLine State
                await this.listenOnLineState()
                // Application initialization. Step 5 - Restoring Access
                await this.$store.dispatch('auth/restoreAccess')

                await new Promise((resolve) => setTimeout(() => resolve(), 500))

                if (!this.isAuthorized) {
                    this.isInitializing = false
                }
            },

            listenSoftwareUpdate() {
                document.addEventListener('serviceWorkerUpdated', this.onServiceWorkerUpdated, { once: true })

                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (this.$store.state.softwareUpdate.isUpdating) {
                        return
                    }

                    this.$store.commit('softwareUpdate/setUpdating', true)

                    window.location.reload()
                })
            },

            onServiceWorkerUpdated(event) {
                // Обновление найдено
                this.$store.dispatch('softwareUpdate/setServiceWorkerRegistration', event.detail)
            },

            listenOnLineState() {
                this.$store.commit('global/setOnLineState', navigator.onLine)

                window.addEventListener('online', () => {
                    this.$store.commit('global/setOnLineState', navigator.onLine)
                })

                window.addEventListener('offline', () => {
                    this.$store.commit('global/setOnLineState', navigator.onLine)
                })
            },

            async loadData() {
                const promises = [
                    this.$store.dispatch('ticketTypes/fetchTicketTypes'),
                    this.$store.dispatch('permissions/fetchPermissions'),
                    this.$store.dispatch('ticketStages/fetchTicketStages'),
                    this.$store.dispatch('buildings/fetchBuildings'),
                    this.$store.dispatch('queue/fetchQueues'),
                    this.$store.dispatch('auth/fetchCurrentWorker'),
                ]

                await Promise.all(promises)

                await this.$store.dispatch('calls/fetchQueueStatus', { workerId: this.userId })
                await this.$store.dispatch('timeManagement/getCurrentTimeEntry', this.userId)

                this.isDataLoaded = true
            },

            hasPermission(permissions) {
                return this.$store.getters['permissions/hasPermission'](permissions)
            },

            checkPermissions() {
                const permissions = this.$route?.meta?.permissions

                const isAllowed = permissions ? this.hasPermission(permissions) : true

                if (!isAllowed) {
                    console.log('al')

                    this.$router.push({ name: 'Home' })
                }

                this.isPermissionsChecked = true
            },

            checkAuthorization() {
                // Fix для маршрутизации
                // После того, как isInitializing === false происходит рендер шаблона (в template срабатывает v-else)
                // и соотв. происходит рендер компонента router-view и происходит переход на нужный маршрут
                // Параллельно с этой логикой запускается checkAuthorization
                // Проблема в том, что рендер и метод checkAuthorization запускаются параллельно
                // По этому добавлен таймер, чтобы рендер отработал быстрее и this.$route был правильный
                this.routeFixTimerId = setTimeout(() => {
                    if (!this.isHomeScreen && !this.isEmptyRoute) {
                        this.$router.push({ name: 'Home' })
                    }
                }, 2000)
            },

            // -----------------------------------------------------------------

            toAuth() {
                if (!this.isAuthScreen) {
                    this.$router.push({ name: 'Auth' })
                }
            },
        },
    }
</script>
