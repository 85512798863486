import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementTitle2 extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'title_2', () => 'title_2'],
        }
    }
}

export default ServiceElementTitle2
