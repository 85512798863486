import API from '_api'

import TicketRoute from '_entities/ticket/TicketRoute'

const actions = {
    async fetchTicketRoutes({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchTicketRoutesRequest')

            try {
                const response = await API.services.router.getTicketRoutes(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new TicketRoute(result))
                })

                commit('fetchTicketRoutesSuccess', { items })
            } catch (e) {
                commit('fetchTicketRoutesError', e)
            }
        }
    },
}

export default actions
