import { request } from '_api/base/behemoth/client'

export const getCurrentTimeEntry = async (userId, params) => {
    return await request('GET', `time_management/current_time_entry/${userId}/`, params)
}

export const startWork = async (params) => {
    return await request('POST', 'time_management/start_work/', params)
}

export const finishWork = async (params) => {
    return await request('POST', 'time_management/finish_work/', params)
}
