import Entity from '_entities/base/EntityNew'

export const QuizStatQuestionAnswerSchema = {
    name: 'QuizStatQuestionAnswer',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' }, // UUID
        text: { type: 'string' },
        value: { type: 'int' },
        valueCount: { type: 'int' },
    },
}

class QuizStatQuestionAnswer extends Entity {
    get schema() {
        return QuizStatQuestionAnswerSchema
    }

    static fieldLabels = {
        id: 'ID',
        text: 'Текст ответа',
        value: 'Процент от общего количества ответов на вопрос',
        valueCount: 'Количество ответов на вопрос',
    }
}

export default QuizStatQuestionAnswer
