import { filter as _filter } from 'lodash'

import { request } from '_api/base/behemoth/client'

export const getAccounts = async (params) => {
    return await request('GET', 'v2/account/', params)
}

export const getAccountDetail = async (accountId) => {
    const response = await request('GET', `v2/account/${accountId}/`)

    // Для корректной инициализации сущности, данные поля должны быть гарантированно массивами
    if (response) {
        response.calls = response.calls || []
        response.meters = response.meters || []
        response.payments = response.payments || []
        response.residents = response.residents || []
        response.storage_history = response.storage_history || []
        response.tickets = response.tickets || []
    }

    return response
}

export const getPersonalAccountDetailOnPremise = async (premise) => {
    const optionsFilter = { sorting: 'created_at', premise }
    const { results } = await getAccounts(optionsFilter)
    const personalAccount = _filter(results, (item) => item.is_active)

    const accountDetail = personalAccount[0]?.id && await getAccountDetail(personalAccount[0].id)

    return accountDetail
}

export const getPersonalAccountById = async (accountId, params) => {
    return await request('GET', `account/${accountId}`, params)
}

export const getAccountsByUserId = async (userId, params) => {
    return await request('GET', `account/${userId}/accounts/`, params)
}

export const addAllowedPerson = async ({ accountId, data }) => {
    return await request('POST', `account/${accountId}/allowed_person/`, data)
}

export const removeAllowedPerson = async (accountId, data) => {
    return await request('DELETE', `account/${accountId}/allowed_person/`, data)
}

export const getExternalAccount = async (accountExtId) => {
    return await request('GET', `account/external/${accountExtId}/`)
}

export const getExternalAccountServices = async (accountExtId) => {
    return await request('GET', `account/${accountExtId}/service/`)
}
