import { request } from '_api/base/behemoth/client'

export const getTicketStages = async (params) => {
    return await request('GET', 'ticket/stage/', params)
}

export const getTicketStageById = async (ticketStageId) => {
    return await request('GET', `ticket/stage/${ticketStageId}/`)
}

export const changeTicketStage = async (ticketId, params) => {
    return await request('PUT', `ticket/${ticketId}/stage/`, params)
}
