import { request } from '_api/base/behemoth/client'

export const createTicketDepartment = async (params) => {
    return await request('POST', 'ticket/department/', params)
}

export const deleteTicketDepartment = async (ticketDepartmentId) => {
    return await request('DELETE', `ticket/department/${ticketDepartmentId}/`)
}

export const getTicketDepartments = async (params) => {
    return await request('GET', 'ticket/department/', params)
}

export const getTicketDepartmentById = async (ticketDepartmentId) => {
    return await request('GET', `ticket/department/${ticketDepartmentId}/`)
}

export const updateTicketDepartment = async (ticketDepartmentId, params) => {
    return await request('PUT', `ticket/department/${ticketDepartmentId}/`, params)
}
