import { map as _map } from 'lodash'

import API from '_api'

import CsiFeedback from '_entities/csiFeedback/CsiFeedback'
import Ticket from '_entities/ticket/Ticket'

const actions = {
    async fetchTicket({ commit }, { ticketId, currentWorkerId, canViewFeedback, canViewApproval }) {
        commit('fetchTicketRequest')

        try {
            let ticket

            const ticketResponse = await API.services.ticket.getTicketById(ticketId)

            if (ticketResponse) {
                let userResponse
                let stageResponse
                // Заполнение родительской заявки
                if (ticketResponse.parent_ticket) {
                    // Получаем данные исполнителя
                    if (ticketResponse.parent_ticket.executor?.id) {
                        userResponse = await API.services.worker.getWorkerById(ticketResponse.parent_ticket.executor.id)
                        ticketResponse.parent_ticket.executor = userResponse
                    }
                    // Получаем данные стадии
                    if (ticketResponse.parent_ticket.ticket_stage) {
                        stageResponse = await API.services.ticketStage.getTicketStageById(ticketResponse.parent_ticket.ticket_stage)
                        ticketResponse.parent_ticket.ticket_stage = stageResponse
                    }
                }

                // Получение дочерних заявок
                const ticketChildsResponse = await API.services.ticket.getTicketChildrenById(ticketId)
                if (ticketChildsResponse) {
                    for (const childTicket of ticketChildsResponse) {
                        // Получаем данные исполнителя
                        if (childTicket.executor?.id) {
                            userResponse = await API.services.worker.getWorkerById(childTicket.executor.id)
                            childTicket.executor = userResponse
                        }

                        // Получаем данные стадии
                        if (childTicket.ticket_stage) {
                            stageResponse = await API.services.ticketStage.getTicketStageById(childTicket.ticket_stage)
                            childTicket.ticket_stage = stageResponse
                        }
                    }

                    ticketResponse.child_tickets = ticketChildsResponse
                }

                ticket = new Ticket(ticketResponse)

                // Получение отзывов заявки
                if (canViewFeedback) {
                    const csiFeedbackResponse = await API.services.analytics.getCSIFeedbacks({
                        page: 1,
                        page_size: Number.MAX_SAFE_INTEGER,
                        ticket__in: ticketId,
                    })

                    if (csiFeedbackResponse?.results) {
                        ticket.csiFeedback = _map(csiFeedbackResponse.results, (result) => new CsiFeedback(result))
                    }
                }

                // Получение заявок на смену даты выполнения
                if (canViewApproval) {
                    const approvalResponse = await API.services.approval.getApprovals({
                        entity_type__in: 'Ticket',
                        entity_id__in: ticketId,
                        applicant__in: currentWorkerId,
                        status__in: 'open',
                    })

                    if (approvalResponse?.results && approvalResponse?.results.length > 0) {
                        ticket.approval = approvalResponse.results[0]
                    }
                }
            }

            commit('fetchTicketSuccess', { ticket })
        } catch (e) {
            commit('fetchTicketError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
