import DiscountValue from '_entities/service/discountCard/DiscountValue'
import Entity from '_entities/base/Entity'
import Photo from '_entities/media/Photo'
import ServiceLink from '_entities/service/ServiceLink'
import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'
import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

class ServiceDiscountCard extends Entity {
    get schema() {
        return {
            cardColor: ['card_color', undefined, (value) => value],
            company: ['company', undefined, (value) => this.getEntityValue(ServicePartnerCompany, value)],
            category: ['category', undefined, (value) => this.getEntityValue(ServiceDiscountCardCategory, value)],
            currentPromotions: ['current_promotions', undefined, (value) => value],
            description: ['description', undefined, (value) => value],
            discountAmount: ['discount_amount', undefined, (value) => value],
            discountValue: ['discount_value', undefined, (value) => this.getEntityValue(DiscountValue, value)],
            id: ['id', undefined, (value) => value], // UUID
            name: ['name', undefined, (value) => value],
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
            service: ['service', undefined, (value) => this.getEntityValue(ServiceLink, value)],
            shopLocation: ['shop_location', undefined, (value) => this.getEntityValue(Photo, value)],
        }
    }

    static fieldLabels = {
        cardColor: 'Цвет карточки',
        company: 'Компания',
        category: 'Категория',
        currentPromotions: 'Текущие акции',
        description: 'Описание',
        discountAmount: 'Размер скидки',
        discountValue: 'Значение дисконтной карты',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
        service: 'Сервис',
        shopLocation: 'Расположение магазина',
    }

    toServer() {
        return {
            card_color: this.cardColor,
            company: this.company?.id,
            category: this.category?.id,
            current_promotions: this.currentPromotions,
            description: this.description,
            discount_amount: this.discountAmount,
            discount_value: this.discountValue?.toServer(),
            name: this.name,
            service: this.service?.id,
            shop_location: this.shopLocation?.id,
        }
    }
}

export default ServiceDiscountCard
