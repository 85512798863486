import { forEach as _forEach, orderBy as _orderBy } from 'lodash'

import { request } from '_api/base/behemoth/client'

export const getAnalytics = async (params) => {
    return await request('GET', 'analytics/', params)
}

export const getAnalyticsExcel = async (params) => {
    return await request('GETFILE', 'analytics/excel/', params)
}

export const getAnalyticsWatches = async (params) => {
    return await request('GET', 'analytics/news/', params)
}

export const getApplicantFeedback = async (params) => {
    return await request('GET', 'applicant-feedback/', params)
}

// CSI

export const getCSI = async (params) => {
    return await request('GET', 'feedback/csi/', params)
}

export const getAnalyticsCSIExcel = async (params) => {
    return await request('GETFILE', 'feedback/csi/excel/', params)
}

export const getCSIFeedbacks = async (params) => {
    return await request('GET', 'feedback/csi_feedback/', params)
}

export const getCSIFeedbacksExcel = async (params) => {
    return await request('GETFILE', 'feedback/csi_feedback/excel/', params)
}

export const getChartCSIFeedbacks = async (params) => {
    const ratings = []
    const ratingNames = ['positive', 'neutral', 'negative']
    const dictComments = { negative: {}, neutral: {}, positive: {} }
    const answerIds = {}
    let summRatings = 0
    let tickets = 0

    const response = await request('GET', 'feedback/csi_feedback/', params)

    const { results, count } = response

    // формирование структуры данных для компонента диаграмы
    if (results && results.length > 0) {
        // считаем количество оценок и суммарное количество баллов
        _forEach(results, (feedback) => {
            ratings[feedback.rating] ? ratings[feedback.rating]++ : ratings[feedback.rating] = 1
            summRatings += feedback.rating

            if (feedback.ticket) {
                tickets++
            }

            // считаем статистику по отмеченным пунктам опроса
            _forEach(feedback.feedback_points, (point) => {
                let count = dictComments[point.rating][point.name]

                count ? count++ : count = 1

                dictComments[point.rating][point.name] = count
                answerIds[point.name] = point.id
            })
        })

        // вычисление статистики
        const countRatings = ratings.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
        const averageRating = (summRatings / countRatings).toFixed(1)

        // формирование общего ответа
        const result = {
            diagram: {
                rating: averageRating,
                sections: [],
                tickets,
                feedbacks: count,
            },
            blocks: [],
        }

        _forEach(ratingNames, (ratingName, index) => {
            result.blocks.push({
                name: ratingName,
                caption: ratingName[index],
                answers: [],
            })
        })

        // информация по каждой оценке
        _forEach(ratings, (rating, index) => {
            if (rating) {
                result.diagram.sections.push({
                    name: index,
                    count: rating,
                    percent: ((rating / countRatings) * 100).toFixed(1),
                })
            }
        })

        // комментарии по каждому рейтингу
        _forEach(ratingNames, (ratingName, index) => {
            for (const comment in dictComments[ratingName]) {
                result.blocks[index].answers.push({
                    text: comment,
                    count: dictComments[ratingName][comment],
                    percent: 0,
                    id: answerIds[comment],
                })
            }

            // сортировка комментариев по убыванию популярности
            result.blocks[index].answers = _orderBy(result.blocks[index].answers, ['count'], ['asc'])
        })

        return result
    }

    return null
}

// NPS

export const getNPSFeedback = async (params) => {
    return await request('GET', 'feedback/nps/', params)
}

export const getNPSPivot = async (params) => {
    const response = await request('GET', 'feedback/nps_pivot/', params)

    if (response) {
        const { result } = response

        const pivots = []
        _forEach(result, (project) => {
            pivots.push({
                name: project.project_name,
                accountsCount: project.project_accounts_count,
                feedbacksCount: project.project_feedbacks_count,
                rating: project.project_rating,
                passedPercent: project.project_passed_percent,
                kind: 'project',
            })
            _forEach(project.buildings, (building) => {
                pivots.push({
                    name: building.building_name,
                    accountsCount: building.building_accounts_count,
                    feedbacksCount: building.building_feedbacks_count,
                    rating: building.building_rating,
                    passedPercent: building.building_passed_percent,
                    kind: 'building',
                })
            })
        })

        return {
            count: pivots.length,
            results: pivots,
        }
    }

    return null
}
