import API from '_api'

import Service from '_entities/service/Service'

const actions = {
    clearServices({ commit }) {
        commit('clearServices')
    },

    async fetchServices({ commit }, params) {
        commit('fetchServicesRequest')
        try {
            const response = await API.services.service.getServices(params)

            const { results } = response

            const items = []
            results.forEach((result) => {
                items.push(new Service(result))
            })

            commit('fetchServicesSuccess', { items })
        } catch (e) {
            commit('fetchServicesError', e)

            return Promise.reject(e)
        }
    },

    async deleteService({ commit }, params) {
        commit('deleteServiceRequest')

        try {
            const serviceId = params?.serviceId || null
            if (serviceId) {
                await API.services.service.deleteService(serviceId)
            }

            commit('deleteServiceSuccess', { serviceId })
        } catch (e) {
            commit('deleteServiceError', e)
        }
    },
}

export default actions
