<template>
    <v-autocomplete
        v-bind="$attrs"
        v-model="value"
        clearable
        :disabled="disabled"
        :hide-details="hideDetails"
        :items="companies"
        item-value="id"
        item-text="name"
        :label="label"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        return-object
    />
</template>

<script>
    import { filter as _filter, forEach as _forEach } from 'lodash'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'FCCompaniesSelect',

        props: {
            disabled: { type: Boolean },
            filter: { type: Object },
            hideDetails: { type: Boolean },
            label: { type: String, default: 'Компания' },
            multiple: { type: Boolean },
            value: { type: Object },
        },

        computed: {
            ...mapState('companies', {
                isFetching: (state) => state.isFetching,
            }),

            ...mapGetters({
                getCompanies: 'companies/getCompanies',
                getCompanyById: 'companies/getCompanyById',
            }),

            companies() {
                let companies = this.getCompanies

                if (this.filter) {
                    if (typeof this.filter === 'function') {
                        //
                    } else {
                        _forEach(this.filter, (filterItem) => {
                            companies = _filter(companies, filterItem)
                        })
                    }
                }

                return companies
            },
        },

        watch: {
            // v-model
            value() {
                // if (this.value?.id) {
                //     if (this.value?.shortName) {
                //         console.log('set company to store')
                //     } else {
                //         // Есть ID, нет Name
                //         // TODO: fetch companies with filter: companyId
                //         console.log('fetch companies with filter: companyId')
                //
                //         this.fetchData()
                //     }
                // }

                this.onInput()
            },
        },

        // TODO: переделать
        mounted() {
            this.fetchData()
        },

        methods: {
            // v-model
            onInput() {
                this.$emit('input', this.value)
            },

            fetchData() {
                this.$store.dispatch('companies/fetchCompanies')
            },
        },
    }
</script>
