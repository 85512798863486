import { request } from '_api/base/behemoth/client'

export const uploadPhoto = async (params) => {
    return await request('POST', 'file/image/', params, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const uploadDoc = async (params) => {
    return await request('POST', 'file/file/', params, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const uploadS3Doc = async (ticketId, params) => {
    return await request('POST', `file/ticket_attachment/${ticketId}/`, params, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

export const downloadS3Doc = async (fileId) => {
    return await request('GETFILE', `file/attachment/${fileId}/`)
}
