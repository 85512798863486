import moment from 'moment'
import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/Entity'

class ServiceElementCalendarEventItem extends Entity {
    get schema() {
        return {
            id: ['id', uuidV4(), (value) => value], // UUID
            date: ['date', undefined, (value) => moment(value).startOf('day').unix()],
            time: ['time', undefined],
            count: ['count', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    toServer(data) {
        return {
            id: data?.id,
            date: moment.unix(data.date).locale('ru').format('YYYY-MM-DD'),
            time: data?.time,
            count: data?.count,
        }
    }
}

export default ServiceElementCalendarEventItem
