import { map as _map } from 'lodash'
import Entity from '_entities/base/Entity'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import StoryElement from '_entities/story/StoryElement'
import Photo from '_entities/media/Photo'

class Story extends Entity {
    get schema() {
        return {
            buildings: ['buildings', [], (value) => value.map((item) => this.getEntityValue(BuildingLink, item))],
            companies: ['companies', [], (value) => value.map((item) => this.getEntityValue(CompanyLink, item))],
            elements: ['elements', [], (value) => value.map((item) => this.getEntityValue(StoryElement, item))],
            endDate: ['end_date', undefined, (value) => this.getIntegerValue(value)],
            icon: ['icon', undefined, (value) => this.getEntityValue(Photo, value)],
            id: ['id', undefined, (value) => value], // UUID
            isActive: ['is_active', undefined, (value) => !!value], // Boolean
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
            title: ['title', undefined, (value) => value], // String
            startDate: ['start_date', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        buildings: 'Дома',
        companies: 'Компании',
        elements: 'Элементы сторис',
        endDate: 'Дата окончания',
        icon: 'Иконка',
        id: 'ID',
        isActive: 'Активность',
        order: 'Сортировка',
        title: 'Заголовок',
        startDate: 'Дата публикации',
    }

    toServer() {
        return {
            buildings: _map(this.buildings, (item) => item.id),
            companies: _map(this.companies, (item) => item.id),
            elements: _map(this.elements, (item) => item.toServer()),
            end_date: this.endDate,
            icon: this.icon?.id,
            is_active: this.isActive,
            order: this.order,
            title: this.title,
            start_date: this.startDate,
        }
    }
}

export default Story
