import API from '_api'

const actions = {
    async fetchPermissions({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchPermissionsRequest')

            try {
                const response = await API.services.permission.getPermissions(params)

                const { results } = response

                commit('fetchPermissionsSuccess', { items: results })
            } catch (e) {
                commit('fetchPermissionsError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
