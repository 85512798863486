const DocumentNavigator = () => import('_navigations/Home/Document/DocumentNavigator')

const PremiseDocumentsScreen = () => import('_screens/Home/Document/PremiseDocumentsScreen')
const PremiseDocumentCreateScreen = () => import('_screens/Home/Document/PremiseDocumentCreateScreen')
const PremiseDocumentDetailScreen = () => import('_screens/Home/Document/PremiseDocumentDetailScreen')

const IncomingDocumentsScreen = () => import('_screens/Home/Document/IncomingDocumentsScreen')
const IncomingDocumentCreateScreen = () => import('_screens/Home/Document/IncomingDocumentCreateScreen')
const IncomingDocumentDetailScreen = () => import('_screens/Home/Document/IncomingDocumentDetailScreen')

const OutgoingDocumentsScreen = () => import('_screens/Home/Document/OutgoingDocumentsScreen')
const OutgoingDocumentCreateScreen = () => import('_screens/Home/Document/OutgoingDocumentCreateScreen')
const OutgoingDocumentDetailScreen = () => import('_screens/Home/Document/OutgoingDocumentDetailScreen')

const CommercialAppealDocumentCreateScreen = () => import('_screens/Home/Document/CommercialAppealDocumentCreateScreen')
const CommercialAppealDocumentDetailScreen = () => import('_screens/Home/Document/CommercialAppealDocumentDetailScreen')
const CommercialAppealDocumentScreen = () => import('_screens/Home/Document/CommercialAppealDocumentsScreen')

const AppealDocumentsScreen = () => import('_screens/Home/Document/AppealDocumentsScreen')
const AppealDocumentCreateScreen = () => import('_screens/Home/Document/AppealDocumentCreateScreen')
const AppealDocumentDetailScreen = () => import('_screens/Home/Document/AppealDocumentDetailScreen')

const documentsRoutes = []

documentsRoutes.push({
    path: 'document',
    component: DocumentNavigator,
    children: [
        {
            path: 'premise-documents',
            name: 'PremiseDocuments',
            component: PremiseDocumentsScreen,
            meta: {
                screenOptions: {
                    title: 'Документы по квартире',
                },
            },
        },
        {
            path: 'premise-document/create/',
            name: 'PremiseDocumentCreate',
            component: PremiseDocumentCreateScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'premise-document/:premiseDocumentId/',
            name: 'PremiseDocumentDetail',
            component: PremiseDocumentDetailScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документ по квартире',
                },
            },
        },

        {
            path: 'incoming-documents',
            name: 'IncomingDocuments',
            component: IncomingDocumentsScreen,
            meta: {
                screenOptions: {
                    title: 'Входящие',
                },
            },
        },
        {
            path: 'incoming-document/create/',
            name: 'IncomingDocumentCreate',
            component: IncomingDocumentCreateScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'incoming-document/:incomingDocumentId/',
            name: 'IncomingDocumentDetail',
            component: IncomingDocumentDetailScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Входящий документ',
                },
            },
        },

        {
            path: 'outgoing-documents',
            name: 'OutgoingDocuments',
            component: OutgoingDocumentsScreen,
            meta: {
                screenOptions: {
                    title: 'Исходящие',
                },
            },
        },
        {
            path: 'outgoing-document/create/',
            name: 'OutgoingDocumentCreate',
            component: OutgoingDocumentCreateScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'outgoing-document/:outgoingDocumentId/',
            name: 'OutgoingDocumentDetail',
            component: OutgoingDocumentDetailScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Исходящий документ',
                },
            },
        },

        {
            path: 'commercial-appeal-document',
            name: 'CommercialAppealDocuments',
            component: CommercialAppealDocumentScreen,
            meta: {
                screenOptions: {
                    title: 'Коммерческое обращения',
                },
            },
        },
        {
            path: 'commercial-appeal-document/create/',
            name: 'CommercialAppealDocumentCreate',
            component: CommercialAppealDocumentCreateScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'commercial-appeal-document/:commercialAppealDocumentId/',
            name: 'CommercialAppealDocumentDetail',
            component: CommercialAppealDocumentDetailScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Коммерческое обращения',
                },
            },
        },

        {
            path: 'appeal-documents',
            name: 'AppealDocuments',
            component: AppealDocumentsScreen,
            meta: {
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'appeal-document/create/',
            name: 'DocumentAppealCreate',
            component: AppealDocumentCreateScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
        {
            path: 'appeal-document/:appealDocumentId/',
            name: 'DocumentAppealDetail',
            component: AppealDocumentDetailScreen,
            meta: {
                showBack: true,
                screenOptions: {
                    title: 'Документы',
                },
            },
        },
    ],
})

export default documentsRoutes
