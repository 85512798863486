import { forEach as _forEach } from 'lodash'

import Doc from '_entities/media/Doc'
import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementFile extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'files', () => 'files'],
            files: ['files', [], (value) => value.map((item) => new Doc(item))],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        files: 'Файлы',
    }

    toServer(data) {
        const fileIds = []

        _forEach(data?.files, (file) => {
            fileIds.push(file.id)
        })

        return {
            ...super.toServer(data),
            files: fileIds,
        }
    }
}

export default ServiceElementFile
