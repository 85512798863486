import API from '_api'

import Camera from '_entities/camera/Camera'

const actions = {
    clearCameras({ commit }) {
        commit('clearCameras')
    },

    async fetchCameras({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchCamerasRequest')

            try {
                const response = await API.services.camera.getCameras(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new Camera(result))
                })

                commit('fetchCamerasSuccess', { items })
            } catch (e) {
                commit('fetchCamerasError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
