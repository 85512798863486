import getters from './getters'
import mutations from './mutations'

const state = {
    onLine: false,
    navigationDrawer: {
        isShown: true,
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    // actions,
}
