import { filter as _filter, map as _map } from 'lodash'

import moment from 'moment'

import Entity from '_entities/base/Entity'

import CityLink from '_entities/city/CityLink'
import MeterType from '_entities/meter/MeterType'
import ProjectLink from '_entities/project/ProjectLink'
import SectorLink from '_entities/sector/SectorLink'
import ServiceLink from '_entities/service/ServiceLink'
import Photo from '_entities/media/Photo'

/*
export const BuildingSchema = {
    name: 'Building',
    primaryKey: 'id',
    properties: {
        buildingType: { type: 'string' },
        constructionDate: { type: 'int' },
        constructionProgress: { type: 'int' },
        constructionStartDate: { type: 'int' },
        finishDate: { type: 'int' },
        id: { type: 'string' },
        image: { type: 'Photo', optioinal: true, entity: Photo },
        isDone: { type: 'bool', default: false },
        keyService: { type: 'Service', optioinal: true, entity: Service },
        meterTypesOnDirectContract: { type: 'MeterType[]', entity: MeterType, default: [] },
        name: { type: 'string' },
        order: { type: 'int' },
        project: { type: 'Project', entity: Project },
        shortName: { type: 'string', optioinal: true },
    },
}
*/

class Building extends Entity {
    get schema() {
        return {
            dateStartAcceptance: ['date_start_acceptance', undefined, (value) => value],
            dateFinishAcceptance: ['date_finish_acceptance', undefined, (value) => value],
            buildingType: ['building_type', undefined, (value) => value],
            city: ['city', undefined, (value) => this.getEntityValue(CityLink, value)],
            constructionDate: ['construction_date', undefined, (value) => this.getIntegerValue(value)],
            constructionProgress: ['construction_progress', undefined, (value) => this.getIntegerValue(value)],
            constructionStartDate: ['construction_start_date', undefined, (value) => this.getIntegerValue(value)],
            gp: ['gp', undefined, (value) => value], // String
            finishDate: ['finish_date', undefined, (value) => this.getIntegerValue(value)],
            id: ['id', undefined, (value) => value], // UUID
            image: ['image', undefined, (value) => this.getEntityValue(Photo, value)],
            internalName: ['internal_name', undefined, (value) => value], // String
            isAvailableForAccountPayment: ['is_available_for_account_payment', undefined, (value) => !!value], // Boolean
            isDone: ['is_done', undefined, (value) => !!value], // Boolean
            keyService: ['key_service', undefined, (value) => this.getEntityValue(ServiceLink, value)],
            meterTypesOnDirectContract: [
                'meter_types_on_direct_contract',
                [],
                (value) => value.map((item) => this.getEntityValue(MeterType, item)),
            ],
            name: ['name', undefined, (value) => value], // String
            order: ['order', undefined, (value) => this.getIntegerValue(value)],
            project: ['project', undefined, (value) => this.getEntityValue(ProjectLink, value)],
            sector: ['sector', undefined, (value) => this.getEntityValue(SectorLink, value)],
            shortName: ['short_name', undefined, (value) => value], // String
            wikiLink: ['wiki_link', undefined, (value) => value],
        }
    }

    get dataMap() {
        return {
            date_start_acceptance: 'dateStartAcceptance',
            date_finish_acceptance: 'dateFinishAcceptance',
            building_type: 'buildingType',
            construction_date: 'constructionDate',
            construction_progress: 'constructionProgress',
            construction_start_date: 'constructionStartDate',
            finish_date: 'finishDate',
            internal_name: 'internalName',
            is_available_for_account_payment: 'isAvailableForAccountPayment',
            is_done: 'isDone',
            key_service: 'keyService',
            meter_types_on_direct_contract: 'meterTypesOnDirectContract',
            short_name: 'shortName',
            wiki_link: 'wikiLink',
        }
    }

    static fieldLabels = {
        dateStartAcceptance: 'Дата начала приемки',
        dateFinishAcceptance: 'Дата окончания приемки',
        buildingType: 'Тип здания',
        city: 'Город',
        constructionDate: 'Срок сдачи',
        constructionProgress: 'Готовность здания (%)',
        constructionStartDate: 'Дата начала строительства',
        finishDate: 'Дата окончания строительства',
        gp: 'ГП',
        id: 'ID',
        image: 'Изображение',
        internalName: 'Внутреннее название',
        isAvailableForAccountPayment: 'Доступна оплата',
        isDone: 'Готовность',
        keyService: 'Сервис выдачи ключей',
        meterTypesOnDirectContract: 'Типы счётчиков на прямом договоре',
        name: 'Название',
        order: 'Порядок',
        project: 'ЖК',
        sector: 'Сектор',
        shortName: 'Короткое название',
        wikiLink: 'Ссылка на карточку дома в wiki',
    }

    toServer() {
        return {
            date_start_acceptance: this.dateStartAcceptance ? moment.unix(this.dateStartAcceptance) : null,
            date_finish_acceptance: this.dateFinishAcceptance ? moment.unix(this.dateFinishAcceptance) : null,
            building_type: this.buildingType,
            city: this.city?.id || null,
            construction_date: this.constructionDate,
            construction_start_date: this.constructionStartDate,
            finish_date: this.finishDate,
            gp: this.gp,
            image: this.image?.id || null,
            internal_name: this.internalName,
            is_available_for_account_payment: this.isAvailableForAccountPayment,
            key_service: this.keyService?.id || null,
            meter_types_on_direct_contract: _filter(_map(this.meterTypesOnDirectContract, (item) => item?.id), (o) => !!o),
            project: this.project?.id || null,
            sector: this.sector?.id || null,
            wiki_link: this.wikiLink || null,
        }
    }

    get buildingTypeName() {
        const buildingTypeNames = {
            building: 'Жилой дом',
            parking: 'Паркинг',
        }
        return buildingTypeNames[this.buildingType] || ''
    }

    get displayName() {
        return this.shortName || this.name
    }
}

export default Building
