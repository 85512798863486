import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementCheckboxTag extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'checkbox_tag', () => 'checkbox_tag'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementCheckboxTag
