import { PERMISSIONS } from '_features/permissions/constants'

const ProjectsNavigator = () => import('_screens/Home/Projects/ProjectsNavigator')

const ProjectsScreen = () => import('_screens/Home/Projects/ProjectsScreen')
const ProjectEditScreen = () => import('_screens/Home/Projects/ProjectEditScreen')

const projectsRoutes = [
    {
        path: 'projects',
        component: ProjectsNavigator,
        children: [
            {
                path: '',
                name: 'Projects',
                component: ProjectsScreen,
                meta: {
                    screenOptions: {
                        title: 'ЖК',
                    },
                    permissions: [PERMISSIONS.CAN_VIEW_LOCATION_PROJECT],
                },
            },
            {
                path: 'edit/:projectId?',
                name: 'ProjectEdit',
                component: ProjectEditScreen,
                meta: {
                    showBack: true,
                    screenOptions: {
                        title: 'ЖК',
                    },
                    permissions: [PERMISSIONS.CAN_CHANGE_LOCATION_PROJECT],
                },
            },
        ],
    },
]

export default projectsRoutes
