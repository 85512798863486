import Entity from '_entities/base/Entity'

import BuildingLink from '_entities/building/BuildingLink'

/*
export const SectionSchema = {
    name: 'Section',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
        building: { type: 'Building', entity: Building },
    },
}
*/

class Section extends Entity {
    get schema() {
        return {
            building: ['building', undefined, (value) => this.getEntityValue(BuildingLink, value)],
            floors: ['floors', undefined, (value) => value.map((item) => this.getIntegerValue(item))],
            id: ['id', undefined, (value) => value], // UUID
            name: ['name', undefined, (value) => value], // String
            order: ['order', undefined, (value) => this.getIntegerValue(value)], // Integer
        }
    }

    static fieldLabels = {
        building: 'Дом',
        floor: 'Этажи',
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }
}

export default Section
