import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementRadioWithImage extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'radiobutton_with_image', () => 'radiobutton_with_image'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementRadioWithImage
