import Entity from '_entities/base/Entity'

class CompanyPhone extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            code: ['code', undefined, (value) => value], // String
            phone: ['phone', undefined, (value) => value], // String
            commentForClient: ['comment_for_client', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        id: 'ID',
        code: 'Код',
        phone: 'Телефон',
        commentForClient: 'Комментарий для клиента',
    }

    toServer() {
        return {
            id: this.id,
            code: this.code,
            phone: this.phone,
            comment_for_client: this.commentForClient,
        }
    }

    static codes = [
        {
            id: 'client_communication',
            name: 'Для связи с клиентами',
        },
    ]

    getPhoneText() {
        return this.phone
    }
}

export default CompanyPhone
