import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ru from 'vuetify/es5/locale/ru'
import light from './theme'

import { library as faLibrary } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
    faAnalytics as falAnalytics,
    faAngleLeft as falAngleLeft,
    faAngleRight as falAngleRight,
    faArrowAltFromBottom as falArrowAltFromBottom,
    faArrowAltToBottom as falArrowAltToBottom,
    faArrowDown as falArrowDown,
    faArrowLeft as falArrowLeft,
    faArrowRight as falArrowRight,
    faArrowsAlt as falArrowsAlt,
    faBars as falBars,
    faBan as falBan,
    faBell as falBell,
    faBook as falBook,
    faBoxesAlt as falBoxesAlt,
    faBuilding as falBuilding,
    faCalendarDay as falCalendarDay,
    faCamera as falCamera,
    faCaretDown as falCaretDown,
    faCaretUp as falCaretUp,
    faChartPie as falChartPie,
    faCheck as falCheck,
    faCheckDouble as falCheckDouble,
    faCheckCircle as falCheckCircle,
    faCircle as falCircle,
    faCity as falCity,
    faClipboardList as falClipboardList,
    faClock as falClock,
    faClone as falClone,
    faCode as falCode,
    faCog as falCog,
    faComments as falComments,
    faEdit as falEdit,
    faEllipsisH as falEllipsisH,
    faEllipsisV as falEllipsisV,
    faExclamationTriangle as falExclamationTriangle,
    faExternalLinkAlt as falExternalLinkAlt,
    faFile as falFile,
    faFileInvoice as falFileInvoice,
    faFolder as falFolder,
    faFolderOpen as falFolderOpen,
    faGripLines as falGripLines,
    faHashtag as falHashtag,
    faHistory as falHistory,
    faHome as falHome,
    faHouse as falHouse,
    faHouseUser as falHouseUser,
    faInboxIn as falInboxIn,
    faInboxOut as falInboxOut,
    faImage as falImage,
    faKey as falKey,
    faLink as falLink,
    faList as falList,
    faNewspaper as falNewspaper,
    faMailBulk as falMailBulk,
    faMinus as falMinus,
    faMoneyBill as falMoneyBill,
    faPaperPlane as falPaperPlane,
    faPause as falPause,
    faPaperclip as falPaperclip,
    faParking as falParking,
    faPencilPaintbrush as falPencilPaintbrush,
    faPeopleArrows as falPeopleArrows,
    faPhoneAlt as falPhoneAlt,
    faPhoneArrowRight as falPhoneArrowRight,
    faPhoneOffice as falPhoneOffice,
    faPhoneSlash as falPhoneSlash,
    faPlay as falPlay,
    faPlug as falPlug,
    faPlus as falPlus,
    faQuestion as falQuestion,
    faRubleSign as falRubleSign,
    faSave as falSave,
    faSearch as falSearch,
    faSms as falSms,
    faSort as falSort,
    faStop as falStop,
    faSquare as falSquare,
    faStar as falStar,
    faTimes as falTimes,
    faTools as falTools,
    faTrash as falTrash,
    faTrashRestore as falTrashRestore,
    faUser as falUser,
    faUsers as falUsers,
    faUserTie as falUserTie,
    faUserCircle as falUserCircle,
    faVideo as falVideo,
    faVolume as falVolume,
    faVolumeMute as falVolumeMute,
    faWarehouse as falWarehouse,
} from '@fortawesome/pro-light-svg-icons'
import {
    faCheckCircle as fasCheckCircle,
    faCheckSquare as fasCheckSquare,
    faDotCircle as fasDotCircle,
    faMinusCircle as fasMinusCircle,
    faPhone as fasPhone,
    faPhoneArrowDownLeft as fasPhoneArrowDownLeft,
    faPhoneMissed as fasPhoneMissed,
    faPlusCircle as fasPlusCircleSolid,
    faRotateLeft as fasRotateLeft,
    faStar as fasStar,

} from '@fortawesome/pro-solid-svg-icons'

const makeIconComponentDeclaration = (icon) => {
    faLibrary.add(icon)

    return {
        component: FontAwesomeIcon,
        props: {
            icon: [icon.prefix, icon.iconName],
        },
    }
}

const MY_ICONS = {
    // Vuetify
    complete: '...',
    cancel: '...',
    close: makeIconComponentDeclaration(falTimes),
    delete: makeIconComponentDeclaration(falTimes), // delete (e.g. v-chip close)
    clear: makeIconComponentDeclaration(falTimes),
    book: makeIconComponentDeclaration(falBook),
    success: 'success',
    info: 'info',
    warning: 'warning',
    error: makeIconComponentDeclaration(falExclamationTriangle),
    prev: makeIconComponentDeclaration(falAngleLeft),
    next: makeIconComponentDeclaration(falAngleRight),
    checkboxOn: makeIconComponentDeclaration(fasCheckSquare),
    checkboxOff: makeIconComponentDeclaration(falSquare),
    checkboxIndeterminate: '...',
    delimiter: 'delimiter', // for carousel
    sort: makeIconComponentDeclaration(falArrowDown),
    expand: makeIconComponentDeclaration(falCaretDown),
    menu: makeIconComponentDeclaration(falEllipsisV),
    more: makeIconComponentDeclaration(falEllipsisH),
    subgroup: makeIconComponentDeclaration(falCaretDown),
    dropdown: makeIconComponentDeclaration(falCaretDown),
    radioOn: makeIconComponentDeclaration(fasDotCircle),
    radioOff: makeIconComponentDeclaration(falCircle),
    edit: makeIconComponentDeclaration(falEdit),
    ratingEmpty: makeIconComponentDeclaration(falStar),
    ratingFull: makeIconComponentDeclaration(fasStar),
    ratingHalf: 'ratingHalf',
    loading: 'loading',
    first: 'first',
    last: 'last',
    unfold: makeIconComponentDeclaration(falSort),
    file: makeIconComponentDeclaration(falFile),
    // Other
    analytics: makeIconComponentDeclaration(falAnalytics),
    arrowAltFromBottom: makeIconComponentDeclaration(falArrowAltFromBottom),
    arrowAltToBottom: makeIconComponentDeclaration(falArrowAltToBottom),
    arrowLeft: makeIconComponentDeclaration(falArrowLeft),
    arrowRight: makeIconComponentDeclaration(falArrowRight),
    arrowsAlt: makeIconComponentDeclaration(falArrowsAlt),
    bars: makeIconComponentDeclaration(falBars),
    ban: makeIconComponentDeclaration(falBan),
    bell: makeIconComponentDeclaration(falBell),
    boxesAlt: makeIconComponentDeclaration(falBoxesAlt),
    building: makeIconComponentDeclaration(falBuilding),
    calendarDay: makeIconComponentDeclaration(falCalendarDay),
    camera: makeIconComponentDeclaration(falCamera),
    chartPie: makeIconComponentDeclaration(falChartPie),
    caretUp: makeIconComponentDeclaration(falCaretUp),
    check: makeIconComponentDeclaration(falCheck),
    checkDouble: makeIconComponentDeclaration(falCheckDouble),
    checkCircle: makeIconComponentDeclaration(falCheckCircle),
    checkCircleSolid: makeIconComponentDeclaration(fasCheckCircle),
    city: makeIconComponentDeclaration(falCity),
    clipboardList: makeIconComponentDeclaration(falClipboardList),
    clock: makeIconComponentDeclaration(falClock),
    clone: makeIconComponentDeclaration(falClone),
    code: makeIconComponentDeclaration(falCode),
    cog: makeIconComponentDeclaration(falCog),
    comments: makeIconComponentDeclaration(falComments),
    externalLinkAlt: makeIconComponentDeclaration(falExternalLinkAlt),
    fileInvoice: makeIconComponentDeclaration(falFileInvoice),
    folder: makeIconComponentDeclaration(falFolder),
    folderOpen: makeIconComponentDeclaration(falFolderOpen),
    gripLines: makeIconComponentDeclaration(falGripLines),
    history: makeIconComponentDeclaration(falHistory),
    hashtag: makeIconComponentDeclaration(falHashtag),
    home: makeIconComponentDeclaration(falHome),
    house: makeIconComponentDeclaration(falHouse),
    houseUser: makeIconComponentDeclaration(falHouseUser),
    inboxOut: makeIconComponentDeclaration(falInboxOut),
    inboxIn: makeIconComponentDeclaration(falInboxIn),
    image: makeIconComponentDeclaration(falImage),
    key: makeIconComponentDeclaration(falKey),
    link: makeIconComponentDeclaration(falLink),
    list: makeIconComponentDeclaration(falList),
    newspaper: makeIconComponentDeclaration(falNewspaper),
    mailBulk: makeIconComponentDeclaration(falMailBulk),
    minus: makeIconComponentDeclaration(falMinus),
    minusCircleSolid: makeIconComponentDeclaration(fasMinusCircle),
    moneyBill: makeIconComponentDeclaration(falMoneyBill),
    paperPlane: makeIconComponentDeclaration(falPaperPlane),
    paperclip: makeIconComponentDeclaration(falPaperclip),
    parking: makeIconComponentDeclaration(falParking),
    pause: makeIconComponentDeclaration(falPause),
    pencilPaintbrush: makeIconComponentDeclaration(falPencilPaintbrush),
    peopleArrows: makeIconComponentDeclaration(falPeopleArrows),
    phoneSolid: makeIconComponentDeclaration(fasPhone),
    phoneAlt: makeIconComponentDeclaration(falPhoneAlt),
    phoneInSolid: makeIconComponentDeclaration(fasPhoneArrowDownLeft),
    phoneMissedSolid: makeIconComponentDeclaration(fasPhoneMissed),
    phoneArrowRight: makeIconComponentDeclaration(falPhoneArrowRight),
    phoneOffice: makeIconComponentDeclaration(falPhoneOffice),
    phoneSlash: makeIconComponentDeclaration(falPhoneSlash),
    play: makeIconComponentDeclaration(falPlay),
    plug: makeIconComponentDeclaration(falPlug),
    plus: makeIconComponentDeclaration(falPlus),
    plusCircleOutline: makeIconComponentDeclaration(fasPlusCircleSolid),
    question: makeIconComponentDeclaration(falQuestion),
    rotateLeft: makeIconComponentDeclaration(fasRotateLeft),
    rubleSign: makeIconComponentDeclaration(falRubleSign),
    search: makeIconComponentDeclaration(falSearch),
    sms: makeIconComponentDeclaration(falSms),
    stop: makeIconComponentDeclaration(falStop),
    tools: makeIconComponentDeclaration(falTools),
    trash: makeIconComponentDeclaration(falTrash),
    trashRestore: makeIconComponentDeclaration(falTrashRestore),
    user: makeIconComponentDeclaration(falUser),
    users: makeIconComponentDeclaration(falUsers),
    userTie: makeIconComponentDeclaration(falUserTie),
    userCircle: makeIconComponentDeclaration(falUserCircle),
    video: makeIconComponentDeclaration(falVideo),
    volume: makeIconComponentDeclaration(falVolume),
    volumeMute: makeIconComponentDeclaration(falVolumeMute),
    save: makeIconComponentDeclaration(falSave),
    warehouse: makeIconComponentDeclaration(falWarehouse),
}

Vue.use(Vuetify)

export default new Vuetify({
    lang: {
        locales: { ru },
        current: 'ru',
    },
    icons: {
        values: MY_ICONS,
    },
    theme: {
        themes: { light },
    },
})
