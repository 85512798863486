import Entity from '_entities/base/Entity'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'
import CameraGroupLink from '_entities/camera/CameraGroupLink'
import Photo from '_entities/media/Photo'

/*
export const CameraSchema = {
    name: 'Camera',
    primaryKey: 'id',
    properties: {
        building: { type: 'Building', entity: Building },
        company: { type: 'Company', entity: Company },
        group: { type: 'CameraGroup', entity: CameraGroup },
        id: { type: 'int' },
        link: { type: 'string' },
        name: { type: 'string' },
        photo: { type: 'Photo', entity: Photo },
    },
}
*/

class Camera extends Entity {
    get schema() {
        return {
            building: ['building', undefined, (value) => this.getEntityValue(BuildingLink, value)],
            company: ['company', undefined, (value) => this.getEntityValue(CompanyLink, value)],
            group: ['group', undefined, (value) => this.getEntityValue(CameraGroupLink, value)],
            id: ['id', undefined, (value) => this.getIntegerValue(value)],
            link: ['link', undefined, (value) => value], // String
            name: ['name', undefined, (value) => value], // String
            photo: ['photo', undefined, (value) => this.getEntityValue(Photo, value)],
        }
    }

    static fieldLabels = {
        company: 'Компания',
        building: 'Дом',
        group: 'Группа',
        id: 'ID',
        link: 'Камера',
        name: 'Название',
        photo: 'Фото',
    }

    toServer() {
        return {
            building: this.building?.id,
            company: this.company?.id,
            group: this.group?.id || null,
            link: this.link,
            name: this.name,
            photo: this.photo?.id,
        }
    }
}

export default Camera
