<template>
    <v-system-bar
        :app="isDevelopment || isStage"
        :color="colorComputed"
        fixed
        :height="height"
        :style="styleComputed"
    >
        <v-container
            v-if="isDevelopment || isStage"
            center
            fluid
            class="text-center"
            :style="devTextStyle"
        >
            <template v-if="isDevelopment">DEV</template>
            <template v-if="isStage">STAGE</template>
        </v-container>
    </v-system-bar>
</template>

<script>
    export default {
        name: 'CSystemBar',

        computed: {
            isDevelopment() {
                return process.env.NODE_ENV === 'development'
            },
            isStage() {
                return process.env.NODE_ENV === 'staging'
            },

            height() {
                if (this.isDevelopment || this.isStage) {
                    return 50
                }

                return 200
            },

            colorComputed() {
                if (this.isDevelopment) {
                    return 'black'
                }
                if (this.isStage) {
                    return 'yellow'
                }

                return 'primary darken-2'
            },

            styleComputed() {
                if (this.isDevelopment || this.isStage) {
                    return {
                        top: '0px',
                    }
                }

                return {
                    top: `-${this.height - 1}px`,
                }
            },

            devTextStyle() {
                const baseStyle = {
                    'font-size': '1.5rem',
                    'font-weight': 'bold',
                }

                if (this.isDevelopment) {
                    return {
                        ...baseStyle,
                        color: 'white',
                    }
                }

                return {
                    ...baseStyle,
                    color: 'black',
                }
            },
        },
    }
</script>
