<template>
    <UIPage>
        <UIPageHeader>
            <template v-slot:actions>
                <v-btn color="primary" @click="addPushMessage()">
                    Добавить
                </v-btn>
            </template>
        </UIPageHeader>

        <CSection>
            <v-card>
                <CPushMessageDataTable />
            </v-card>
        </CSection>
    </UIPage>
</template>

<script>
    import CPushMessageDataTable from '_features/pushMessages/components/CPushMessagesDataTable'

    import CSection from '_common/components/CSection'

    import UIPage from '_ui/layout/UIPage'
    import UIPageHeader from '_ui/layout/UIPageHeader'

    export default {
        name: 'PushMessagesScreen',

        components: {
            CPushMessageDataTable,
            CSection,
            UIPage,
            UIPageHeader,
        },

        methods: {
            addPushMessage() {
                this.$router.push({
                    name: 'NewslettersPushEdit',
                })
            },
        },
    }
</script>
