import API from '_api'

import News from '_entities/news/News'

const actions = {
    async fetchNewsList({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchNewsListRequest')

            try {
                const response = await API.services.news.getNewsList(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new News(result))
                })

                commit('fetchNewsListSuccess', { items })
            } catch (e) {
                commit('fetchNewsListError', e)
            }
        }
    },
}

export default actions
