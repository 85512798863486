import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementUploadFiles extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'upload_files', () => 'upload_files'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementUploadFiles
