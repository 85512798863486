import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import API from '_api'
import { request } from '_api/base/behemoth/clientKokoc'

export const getOutgoingDocuments = async (params) => {
    const response = await request('GET', 'documents/OutgoingDocument', params)
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companySenderIds = _map(responseChunk, ({ company_sender_id }) => company_sender_id)
        const ticketIds = _map(responseChunk, ({ ticket_id }) => ticket_id)

        const companySenders = await API.services.company.getCompanies({
            id__in: _compact(companySenderIds).join(','),
        })

        const tickets = await API.services.ticket.getTickets({
            id__in: _compact(ticketIds).join(','),
        })

        for (const response of responseChunk) {
            response.companySender = companySenders.results.find((companySender) => companySender.id === response.company_sender_id)
            response.ticket = tickets.results.find((ticket) => ticket.id === response.ticket_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getOutgoingDocumentById = async (outgoingDocumentId) => {
    const response = await request('GET', `documents/OutgoingDocument/${outgoingDocumentId}`)
    const errors = []

    if (response.company_sender_id) {
        response.companySender = await API.services.company.getCompanyById(response.company_sender_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.compiler_user_id) {
        response.compilerUser = await API.services.user.getUserById(response.compiler_user_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.signatory_user_id) {
        response.signatoryUser = await API.services.user.getUserById(response.signatory_user_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.ticket_id) {
        response.ticket = await API.services.ticket.getTicketById(response.ticket_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updateOutgoingDocument = async (outgoingDocumentId, data) => {
    return await request('PATCH', `documents/OutgoingDocument/${outgoingDocumentId}`, data)
}

export const createOutgoingDocument = async (data) => {
    return await request('POST', 'documents/createOutgoingDocument', data)
}

export const deleteOutgoingDocument = async (outgoingDocumentId) => {
    return await request('DELETE', `documents/OutgoingDocument/${outgoingDocumentId}`)
}

export const getOutgoingDocumentBlank = async (companyId) => {
    const errors = []
    const response = await request(
        'POST',
        'documents/OutgoingDocument/getBlank',
        {
            company_sender_id: companyId,
        },
    )

    if (response.company_sender_id) {
        response.companySender = await API.services.company.getCompanyById(response.company_sender_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}
