import API from '_api'

import TicketKind from '_entities/ticket/TicketKind'

const actions = {
    async fetchTicketKinds({ commit }, params) {
        commit('fetchTicketKindsRequest')

        const _params = {
            ...params,
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
        }

        try {
            const response = await API.services.ticketKind.getTicketKinds(_params)

            const { results } = response

            const items = []
            results.forEach((result) => {
                items.push(new TicketKind(result))
            })

            commit('fetchTicketKindsData', { items })
            commit('fetchTicketKindsSuccess')
        } catch (e) {
            commit('fetchTicketKindsError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
