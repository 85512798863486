import API from '_api'

import Company from '_entities/company/Company'

const actions = {
    async fetchCompanies({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchCompaniesRequest')

            try {
                const response = await API.services.company.getCompanies(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new Company(result))
                })

                commit('fetchCompaniesSuccess', { items })
            } catch (e) {
                commit('fetchCompaniesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
