import API from '_api'

import Quiz from '_entities/quiz/Quiz'
import QuizStatQuestion from '_entities/quiz/QuizStatQuestion'

const actions = {
    async fetchQuiz({ commit }, { quizId }) {
        commit('fetchQuizRequest')

        try {
            const response = await API.services.quiz.getQuizById(quizId)

            const item = response ? new Quiz(response) : null

            commit('fetchQuizSuccess', { item })
        } catch (e) {
            commit('fetchQuizError', e)

            return Promise.reject(e)
        }
    },
    async saveQuiz({ commit }, params) {
        commit('updateQuizRequest')

        try {
            const quizId = params?.quizId
            const data = params?.data

            if (quizId) {
                await API.services.quiz.updateQuiz({ quizId, data })
            } else {
                await API.services.quiz.createQuiz({ data })
            }

            commit('updateQuizSuccess')
        } catch (e) {
            commit('updateQuizError', e)

            return Promise.reject(e)
        }
    },
    async fetchQuizStat({ commit }, { quizId }) {
        commit('fetchQuizStatRequest')

        try {
            commit('clearQuizStat')
            const response = await API.services.quiz.getQuizStat(quizId)

            const { questions } = response
            const items = []
            questions.forEach((question) => {
                items.push(new QuizStatQuestion(question))
            })
            commit('fetchQuizStatSuccess', { items })

            const item = response ? new Quiz(response) : null
            commit('fetchQuizSuccess', { item })
        } catch (e) {
            commit('fetchQuizStatError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
