import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementDate extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'date', () => 'date'],
            timezone: ['timezone', null, (value) => value],
        }
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            timezone: this.timezone,
        }
    }
}

export default ServiceElementDate
