import { map as _map } from 'lodash'

import Entity from '_entities/base/Entity'

import Photo from '_entities/media/Photo'
import UserLink from '_entities/user/UserLink'

class ServicePartnerCompany extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            logo: ['logo', undefined, (value) => this.getEntityValue(Photo, value)],
            name: ['name', undefined, (value) => value],
            users: ['users', [], (value) => value.map((item) => this.getEntityValue(UserLink, item))],
        }
    }

    static fieldLabels = {
        id: 'ID',
        logo: 'Логотип',
        name: 'Название',
        users: 'Ползователи',
    }

    toServer() {
        return {
            logo: this.logo?.id,
            name: this.name,
            users: _map(this.users, (user) => {
                return user.id
            }),
        }
    }
}

export default ServicePartnerCompany
