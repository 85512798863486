import { request } from '_api/base/behemoth/client'

// PushMessages

export const getPushMessages = async (params) => {
    return await request('GET', 'newsletter/push_message/', params)
}

export const getPushMessageById = async (pushMessageId) => {
    return await request('GET', `newsletter/push_message/${pushMessageId}/`)
}

export const createPushMessage = async (data) => {
    return await request('POST', 'newsletter/push_message/', data)
}

export const updatePushMessage = async (pushMessageId, data) => {
    return await request('PUT', `newsletter/push_message/${pushMessageId}/`, data)
}

// NPS

export const createNPSPushMessage = async (data) => {
    return await request('POST', 'newsletter/nps_message/', data)
}

export const updateNPSPushMessage = async (pushMessageId, data) => {
    return await request('PUT', `newsletter/nps_message/${pushMessageId}/`, data)
}
