import { forEach as _forEach } from 'lodash'

import Entity from '_entities/base/Entity'
import Photo from '_entities/media/Photo'

class DiscountValue extends Entity {
    get schema() {
        return {
            barCode: ['bar_code', undefined, (value) => this.getEntityValue(Photo, value)],
            id: ['id', undefined, (value) => value], // UUID
            promoCode: ['promo_code', undefined, (value) => value],
            qrCode: ['qr_code', undefined, (value) => this.getEntityValue(Photo, value)],
            type: ['type', undefined, (value) => value],
        }
    }

    static fieldLabels = {
        barCode: 'Штрих-код',
        id: 'ID',
        promoCode: 'Промокод',
        qrCode: 'QR-код',
        type: 'Тип',
    }

    static types = [
        {
            id: 'bar_code',
            name: 'Штрих-код',
        },
        {
            id: 'promo_code',
            name: 'Промокод',
        },
        {
            id: 'qr_code',
            name: 'QR-код',
        },
    ]

    toServer() {
        const result = {
            bar_code: this.barCode?.id,
            id: this.id,
            promo_code: this.promoCode,
            qr_code: this.qrCode?.id,
            type: this.type,
        }

        _forEach(this.constructor.types, (type) => {
            if (type.id !== this.type) {
                result[type.id] = null
            }
        })

        return result
    }
}

export default DiscountValue
