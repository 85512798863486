import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'
import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'

export const TicketKindSchema = {
    name: 'TicketKind',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        id: { type: 'string' },
        instruction: { type: 'Doc', entity: Doc, optional: true },
        isEmergency: { type: 'bool' },
        name: { type: 'string' },
        order: { type: 'int' },
        ticketDepartment: { type: 'TicketDepartmentLink', entity: TicketDepartmentLink },
    },
}

class TicketKind extends Entity {
    get schema() {
        return TicketKindSchema
    }

    get dataMap() {
        return {
            is_emergency: 'isEmergency',
            ticket_department: 'ticketDepartment',
        }
    }

    static fieldLabels = {
        code: 'Код',
        id: 'ID',
        instruction: 'Инструкция',
        isEmergency: 'Аварийная',
        name: 'Название',
        order: 'Порядок',
        ticketDepartment: 'Отдел заявки',
    }

    toArray() {
        return {
            ...super.toArray(),
            instruction: this.instruction?.id || null,
            ticket_department: this.ticketDepartment?.id,
        }
    }
}

export default TicketKind
