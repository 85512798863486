import { request } from '_api/base/behemoth/client'

export const getCompanies = async (params) => {
    return await request('GET', 'department/company/', params)
}

export const getCompanyById = async (companyId) => {
    return await request('GET', `department/company/${companyId}/`)
}

export const updateCompany = async ({ companyId, data }) => {
    return await request('PUT', `department/company/${companyId}/`, data)
}
