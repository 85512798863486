import Entity from '_entities/base/EntityNew'

import TicketStageLink from '_entities/ticket/TicketStageLink'
import UserWorker from '_entities/user/UserWorker'

export const TicketLinkSchema = {
    name: 'TicketLink',
    primaryKey: 'id',
    properties: {
        datePlannedCompletion: { type: 'int' }, // UNIX
        executor: { type: 'UserWorker', entity: UserWorker },
        id: { type: 'string' },
        number: { type: 'int' },
        ticketStage: { type: 'TicketStageLink', entity: TicketStageLink },
        title: { type: 'string' },
    },
}

class TicketLink extends Entity {
    get schema() {
        return TicketLinkSchema
    }

    get dataMap() {
        return {
            date_planned_completion: 'datePlannedCompletion',
            ticket_stage: 'ticketStage',
        }
    }

    static fieldLabels = {
        datePlannedCompletion: 'Дата планируемого выполнения',
        executor: 'Исполнитель',
        id: 'ID',
        number: 'Номер',
        ticketStage: 'Стадия',
        title: 'Заголовок',
    }
}

export default TicketLink
