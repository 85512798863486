import axios from 'axios'

const client = axios.create({
    baseURL: process.env.VUE_APP_API_KOKOC_BASE_URL,
})

client.interceptors.request.use((config) => {
    // Do something before request is sent
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) {
        config.headers.Authorization = `Token ${accessToken}`
    }

    return config
}, (e) => {
    // Do something with request error
    return Promise.reject(e)
})

export const request = async (type, url, params, clientParams = {}) => {
    try {
        let response
        switch (type) {
            case 'GET':
                response = await client.get(url, params)
                break
            case 'GETFILE':
                response = await client.get(url, { responseType: 'blob', params })
                break
            case 'POST':
                response = await client.post(url, params, clientParams)
                break
            case 'PUT':
                response = await client.put(url, params, clientParams)
                break
            case 'PATCH':
                response = await client.patch(url, params, clientParams)
                break
            case 'DELETE':
                response = await client.delete(url, params, clientParams)
                break
        }

        return response?.data
    } catch (e) {
        const errors = e?.response?.data?.errors || []

        return Promise.reject(errors)
    }
}

export default client
