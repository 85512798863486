import camerasRoutes from '@/router/Home/UserApp/Cameras'
import newsRoutes from '@/router/Home/UserApp/News'
import quizzesRoutes from '@/router/Home/UserApp/Quizzes'
import servicesRoutes from '@/router/Home/UserApp/Services'
import storiesRoutes from '@/router/Home/UserApp/Stories'
import legalDocumentsRoutes from '@/router/Home/UserApp/LegalDocuments'
import chatBotsRoutes from '@/router/Home/UserApp/ChatBots'

const UserAppNavigator = () => import('_navigations/Home/UserAppNavigator')

const userAppRoutes = [
    {
        path: 'user-app',
        name: 'UserAppNavigator',
        component: UserAppNavigator,
        redirect: { name: 'UserAppBuildings' },
        children: [
            ...newsRoutes,
            ...quizzesRoutes,
            ...servicesRoutes,
            ...camerasRoutes,
            ...storiesRoutes,
            ...legalDocumentsRoutes,
            ...chatBotsRoutes,
        ],
    },
]

export default userAppRoutes
