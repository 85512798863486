<template>
    <v-autocomplete
        ref="autocomplete"
        v-bind="$attrs"
        v-model="model"
        v-on="$listeners"
        :clearable="clearable"
        :error-messages="errors"
        :items="items"
        :loading="isFetching"
        :multiple="multiple"
        outlined
        placeholder="Начните ввод.."
        return-object
        :search-input.sync="search"
        @blur="handleBlur"
        @focus="handleFetchData(params)"
        @keydown="fetchData"
    >
        <template v-slot:item="{ item }">
            <slot name="item" :item="item">
                {{ item[$attrs['item-text']] }}
            </slot>
        </template>
    </v-autocomplete>
</template>

<script>
    import { forEach as _forEach, orderBy as _orderBy, map as _map, throttle as _throttle } from 'lodash'
    import { getOrderString } from '_utils/collection'

    export default {
        name: 'UIAutocomplete',

        props: {
            apiFilter: { type: Object },
            apiMethod: { type: Function, required: true },
            clearable: { type: Boolean, default: true },
            fieldName: { type: String, default: '' },
            getEntity: { type: Function },
            multiple: { type: Boolean, default: false },
            sortBy: { type: Array, default: () => ['name'] },
            sortOrders: { type: Array, default: () => ['asc'] },
            value: { type: [Array, Object] },
        },

        data: () => ({
            entities: {},
            errors: [],
            fetchData: null,
            isFetching: false,
            search: null,
        }),

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            items() {
                const sortBy = Array.isArray(this.sortBy) ? this.sortBy : [this.sortBy]
                const sortOrders = Array.isArray(this.sortOrders) ? this.sortOrders : [this.sortOrders]

                return sortBy.length > 1
                    ? _orderBy(this.entities, sortBy, sortOrders)
                    : _orderBy(this.entities, [(o) => getOrderString(o[sortBy[0]])], ['asc'])
            },

            params() {
                return {
                    search: this.search,
                    ...this.apiFilter,
                }
            },

            attrValue() {
                return this.$attrs['item-value'] || 'value'
            },
        },

        watch: {
            apiFilter: { handler: 'handleFetchData' },

            value: {
                handler(value) {
                    if (value) {
                        const emptyEntityIds = []
                        const attrText = this.$attrs['item-text'] || 'text'

                        if (this.multiple) {
                            _forEach(value, (entity) => {
                                this.addedEntity(entity)

                                if (!entity[attrText]) {
                                    emptyEntityIds.push(entity[this.attrValue])
                                }
                            })
                        } else {
                            this.addedEntity(value)

                            if (!value[attrText]) {
                                emptyEntityIds.push(value[this.attrValue])
                            }
                        }

                        if (emptyEntityIds.length > 0) {
                            const params = {
                                id__in: emptyEntityIds.join(','),
                            }

                            this.handleFetchData(params)
                        }
                    }
                },
                immediate: true,
            },
        },

        created() {
            this.fetchData = _throttle(() => {
                this.handleFetchData(this.params)
            }, 500)
        },

        methods: {
            addedEntity(entity) {
                if (entity[this.attrValue]) {
                    this.entities = {
                        ...this.entities,
                        [entity[this.attrValue]]: entity,
                    }
                }
            },

            async handleFetchData(params) {
                if (this.isFetching) {
                    return
                }

                try {
                    this.isFetching = true
                    this.errors = []

                    const response = await this.apiMethod(params)

                    const { results } = response

                    const entities = {}
                    _forEach(results, (entity) => {
                        entities[entity[this.attrValue]] = typeof this.getEntity === 'function' ? this.getEntity(entity) : entity
                    })

                    this.entities = entities
                } catch (e) {
                    this.entities = {}
                    this.errors = _map(e, (error) => {
                        const field = error?.inner_error?.validated_field
                        return error.detail + (field ? ' (' + field + ')' : null)
                    })
                } finally {
                    this.isFetching = false
                }
            },

            handleBlur() {
                const params = {
                    search: this.search,
                    items: this.items,
                }
                this.$emit('blur', params)
            },
        },

    }
</script>
