import Entity from '_entities/base/EntityNew'

import DepartmentLink from '_entities/department/DepartmentLink'
import UserLink from '_entities/user/UserLink'

export const UserWorkerSchema = {
    name: 'UserWorker',
    properties: {
        department: { type: 'DepartmentLink', entity: DepartmentLink },
        internalPhone: { type: 'string' },
        isActive: { type: 'bool', default: true },
        netMonetId: { type: 'string' },
        position: { type: 'string' },
        user: { type: 'UserLink', entity: UserLink },
        workingStatus: { type: 'bool' },
    },
}

class UserWorker extends Entity {
    get schema() {
        return UserWorkerSchema
    }

    get dataMap() {
        return {
            internal_phone: 'internalPhone',
            is_active: 'isActive',
            net_monet_id: 'netMonetId',
            working_status: 'workingStatus',
        }
    }

    static fieldLabels = {
        department: 'Отдел',
        internalPhone: 'Внутренний телефон',
        isActive: 'Статус записи',
        netMonetId: 'ID net monet',
        position: 'Должность',
        workingStatus: 'Рабочий статус',
    }

    get toServer() {
        return {
            department: this.department?.id,
            internal_phone: this.internalPhone,
            is_active: this.isActive,
            net_monet_id: this.netMonetId,
            position: this.position,
        }
    }
}

export default UserWorker
