import API from '_api'

import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'

const actions = {
    async fetchServiceDiscountCardCategory({ commit, state }, { serviceDiscountCardCategoryId }) {
        if (!state.isFetching) {
            commit('fetchServiceDiscountCardCategoryRequest')

            try {
                const response = await API.services.service.getServiceDiscountCardCategoryById(serviceDiscountCardCategoryId)

                const item = response ? new ServiceDiscountCardCategory(response) : null

                commit('fetchServiceDiscountCardCategorySuccess', { item })
            } catch (e) {
                commit('fetchServiceDiscountCardCategoryError', e)

                return Promise.reject(e)
            }
        }
    },

    async saveServiceDiscountCardCategory({ commit, state }, { serviceDiscountCardCategory }) {
        if (!state.isSaving) {
            commit('saveServiceDiscountCardCategoryRequest')

            try {
                const serviceDiscountCardCategoryId = serviceDiscountCardCategory.id
                const data = serviceDiscountCardCategory.toServer()

                if (serviceDiscountCardCategoryId) {
                    await API.services.service.updateServiceDiscountCardCategory({ serviceDiscountCardCategoryId, data })
                } else {
                    await API.services.service.createServiceDiscountCardCategory({ data })
                }

                commit('saveServiceDiscountCardCategorySuccess')
            } catch (e) {
                commit('saveServiceDiscountCardCategoryError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
