import API from '_api'

const actions = {
    async fetchQueueStatus({ commit }, { workerId }) {
        if (workerId) {
            try {
                const response = await API.services.queue.getUserQueue(workerId)

                if (response) {
                    const queueStatus = response?.queue_status || false
                    commit('setQueueStatus', { queueStatus })
                }
            } catch (e) {
                return Promise.reject(e)
            }
        }
    },
}

export default actions
