import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import API from '_api'
import { request } from '_api/base/behemoth/clientKokoc'

export const getAppealDocuments = async (params) => {
    const response = await request('GET', 'documents/DocumentAppeal', params)
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const buildingIds = _map(responseChunk, ({ building_id }) => building_id)
        const companyIds = _map(responseChunk, ({ company_id }) => company_id)
        const declarerIds = _map(responseChunk, ({ declarer_id }) => declarer_id)
        const premiseIds = _map(responseChunk, ({ premise_id }) => premise_id)
        const premiseTypeIds = _map(responseChunk, ({ premise_type_id }) => premise_type_id)
        const ticketIds = _map(responseChunk, ({ ticket_id }) => ticket_id)
        const userIds = _map(responseChunk, ({ created_by }) => created_by?.user_id)

        const buildings = await API.services.building.getBuildings({
            id__in: _compact(buildingIds).join(','),
        })

        const companies = await API.services.company.getCompanies({
            id__in: _compact(companyIds).join(','),
        })

        const declarers = await API.services.user.getUsers({
            id__in: _compact(declarerIds).join(','),
        })

        const primeses = await API.services.premise.getPremises({
            id__in: _compact(premiseIds).join(','),
        })

        const premiseTypes = await API.services.premise.getPremiseTypes({
            id__in: _compact(premiseTypeIds).join(','),
        })

        const tickets = await API.services.ticket.getTickets({
            id__in: _compact(ticketIds).join(','),
        })

        const users = await API.services.user.getUsers({
            id__in: _compact(userIds).join(','),
        })

        for (const response of responseChunk) {
            response.building = buildings.results.find((building) => building.id === response.building_id)
            response.company = companies.results.find((company) => company.id === response.company_id)
            response.declarer = declarers.results.find((declarer) => declarer.id === response.declarer_id)
            response.premise = primeses.results.find((primese) => primese.id === response.premise_id)
            response.premiseType = premiseTypes.results.find((premiseType) => premiseType.id === response.premise_type_id)
            response.ticket = tickets.results.find((ticket) => ticket.id === response.ticket_id)
            response.user = users.results.find((user) => user.id === response.created_by?.user_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getAppealDocumentById = async (appealDocumentId) => {
    const response = await request('GET', `documents/DocumentAppeal/${appealDocumentId}`)
    const errors = []

    if (response.account_id) {
        response.account = await API.services.personalAccount.getAccountDetail(response.account_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.building_id) {
        response.building = await API.services.building.getBuildingById(response.building_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.company_id) {
        response.company = await API.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.declarer_id) {
        response.declarer = await API.services.user.getResidentById(response.declarer_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.premise_id) {
        response.premise = await API.services.premise.getPremiseById(response.premise_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.premise_type_id) {
        response.premiseType = await API.services.premise.getPremiseTypeById(response.premise_type_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.ticket_id) {
        response.ticket = await API.services.ticket.getTicketById(response.ticket_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updateAppealDocument = async (appealDocumentId, data) => {
    return await request('PATCH', `documents/DocumentAppeal/${appealDocumentId}`, data)
}

export const createAppealDocument = async (data) => {
    return await request('POST', 'documents/DocumentAppeal/create', data)
}

export const deleteAppealDocument = async (appealDocumentId) => {
    return await request('DELETE', `documents/DocumentAppeal/${appealDocumentId}`)
}

export const getDocumentAppealResponseTypes = async (params) => {
    return await request('GET', 'documents/AppealResponseType', params)
}

export const getDocumentAppealSourceTypes = async (params) => {
    return await request('GET', 'documents/AppealResponseSourceType', params)
}

export const getDocumentAppealResponses = async (params) => {
    return await request('GET', 'documents/DocumentAppealResponse', params)
}

export const getDocumentAppealResponseById = async (documentAppealResponseId, params) => {
    return await request('GET', `documents/DocumentAppealResponse/${documentAppealResponseId}`, params)
}

export const updateDocumentAppealResponse = async (documentAppealResponseId, data) => {
    return await request('PATCH', `documents/DocumentAppealResponse/${documentAppealResponseId}`, data)
}

export const deleteDocumentAppealResponse = async (documentAppealResponseId, data) => {
    return await request('DELETE', `documents/DocumentAppealResponse/${documentAppealResponseId}`, data)
}

export const createDocumentAppealResponse = async (data) => {
    return await request('POST', 'documents/DocumentAppealResponse/create', data)
}

export const getAppealDocumentBlank = async (companyId) => {
    const errors = []
    const response = await request(
        'POST',
        'documents/DocumentAppeal/getBlank',
        {
            company_id: companyId,
        },
    )

    if (response.company_id) {
        response.company = await API.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}
