import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/Entity'

import Photo from '_entities/media/Photo'

class Product extends Entity {
    get schema() {
        return {
            id: ['id', uuidV4(), (value) => value], // UUID
            title: ['title', undefined, (value) => value], // String
            price: ['price', undefined, (value) => this.getFloatValue(value)], // Float
            image: ['image', undefined, (value) => new Photo(value)], // Photo
            workTime: ['work_time', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        code: 'Код',
        title: 'Заголовок',
        price: 'Цена',
        image: 'Изображение',
        workTime: 'Длительность услуги, минут',
    }

    toServer(data) {
        return {
            title: data?.title,
            price: data?.price,
            image: data?.image?.id,
            work_time: data?.workTime,
        }
    }
}

export default Product
