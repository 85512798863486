import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementTextInput extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'textinput', () => 'textinput'],
            placeholder: ['placeholder', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        placeholder: 'Placeholder',
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            placeholder: data?.placeholder,
        }
    }
}

export default ServiceElementTextInput
