<template>
    <UIPage>
        <UIPageHeader :title="pageTitle" />

        <v-form v-model="isValidForm" :disabled="isFormDisabled" lazy-validation ref="pushMessageForm">
            <CSection>
                <v-alert v-for="error in errors" :key="`error_${error.code}`" type="error">
                    {{ error.detail }}
                </v-alert>

                <v-card :loading="isFetching">
                    <CContainer>
                        <v-text-field
                            v-model="pushMessage.title"
                            clearable
                            :label="fieldLabels.title"
                            outlined
                            :rules="[rules.required]"
                        />
                        <UITextarea
                            v-model="pushMessage.text"
                            clearable
                            :label="fieldLabels.text"
                            outlined
                            :rules="[rules.required]"
                        />
                        <CPushMessageTypesSelect
                            v-model="pushMessage.type"
                            :rules="[rules.required]"
                        />
                        <FServicePicker
                            v-if="pushMessage.type === 'service'"
                            v-model="pushMessage.service"
                            :rules="[isServiceRequired]"
                        />
                        <UIDateTimePicker
                            v-model="pushMessage.date"
                            :label="fieldLabels.date"
                            :rules="[rules.required]"
                        />
                        <UIDateTimePicker
                            v-model="pushMessage.dateCreateAccount"
                            :label="fieldLabels.dateCreateAccount"
                        />
                        <FCCompaniesSelect
                            v-model="pushMessage.company"
                            :rules="[rules.requiredObject]"
                        />
                        <FBuildingPicker
                            v-model="pushMessage.buildings"
                            :label="fieldLabels.buildings"
                            multiple
                        />
                        <FSectionPicker
                            v-model="pushMessage.location.sections"
                            :apiFilter="sectionFilter"
                            :disabled="!pushMessage.buildings || pushMessage.buildings.length === 0"
                            :label="locationFieldLabels.sections"
                            multiple
                        />
                        <FPipePicker
                            v-model="pushMessage.location.pipes"
                            :apiFilter="pipeFilter"
                            :disabled="notSectionPresent"
                            :label="locationFieldLabels.pipes"
                            multiple
                        />
                        <v-select
                            v-model="pushMessage.location.floors"
                            :disabled="notSectionPresent"
                            :items="floors"
                            :label="locationFieldLabels.floors"
                            multiple
                            outlined
                        />
                        <v-autocomplete
                            v-model="pushMessage.location.premises"
                            clearable
                            item-text="number"
                            item-value="id"
                            :items="filteredPremises"
                            :label="locationFieldLabels.premises"
                            multiple
                            outlined
                            return-object
                        />
                        <v-switch
                            v-model="pushMessage.isPersonal"
                            :label="fieldLabels.isPersonal"
                        />
                    </CContainer>
                </v-card>
            </CSection>
        </v-form>

        <CFooter>
            <CContainer>
                <v-row justify="space-between" no-gutters>
                    <v-col cols="auto"></v-col>
                    <v-col cols="auto">
                        <v-btn color="primary" text @click="goBack()">
                            Назад
                        </v-btn>
                        <v-btn
                            class="ml-2"
                            color="primary"
                            :disabled="!isValidForm || isFormDisabled"
                            :loading="isSaving"
                            @click="save()"
                        >
                            Сохранить
                        </v-btn>
                    </v-col>
                </v-row>
            </CContainer>
        </CFooter>
    </UIPage>
</template>

<script>
    import API from '_api'

    import {
        filter as _filter,
        forEach as _forEach,
        map as _map,
        max as _max,
        min as _min,
        range as _range,
    } from 'lodash'

    import Premise from '_entities/premise/Premise'
    import PushMessage from '_entities/pushMessage/PushMessage'
    import PushMessageLocation from '_entities/pushMessage/PushMessageLocation'

    import CPushMessageTypesSelect from '_features/pushMessages/components/CPushMessageTypesSelect'
    import FCCompaniesSelect from '_features/companies/components/FCCompaniesSelect'
    import FBuildingPicker from '_features/housingStock/buildings/components/FBuildingPicker'
    import FPipePicker from '_features/housingStock/pipes/components/FPipePicker'
    import FSectionPicker from '_features/housingStock/sections/components/FSectionPicker'
    import FServicePicker from '_features/service/services/components/FServicePicker'

    import CContainer from '_common/components/CContainer'
    import CFooter from '_common/components/CFooter'
    import CSection from '_common/components/CSection'

    import UIDateTimePicker from '_ui/forms/UIDateTimePicker'
    import UIPage from '_ui/layout/UIPage'
    import UIPageHeader from '_ui/layout/UIPageHeader'
    import UITextarea from '_ui/forms/UITextarea'

    export default {
        name: 'PushMessageEditScreen',

        components: {
            CContainer,
            CFooter,
            CPushMessageTypesSelect,
            CSection,
            FBuildingPicker,
            FCCompaniesSelect,
            FPipePicker,
            FSectionPicker,
            FServicePicker,
            UIDateTimePicker,
            UIPage,
            UIPageHeader,
            UITextarea,
        },

        data: () => ({
            errors: [],
            isFetching: false,
            isSaving: false,
            isValidForm: true,
            pushMessage: new PushMessage(),
            premises: [],
            rules: {
                required: (value) => (!!value && !!value.length) || 'Обязательное поле.',
                requiredObject: (value) => (value && typeof value === 'object') || 'Обязательное поле.',
            },
        }),

        computed: {
            fieldLabels() {
                return PushMessage.fieldLabels
            },

            floors() {
                let max = 1
                let min = 1

                _forEach(this.pushMessage?.location?.sections, (section) => {
                    max = _max([max, _max(section.floors)])
                    min = _min([min, _min(section.floors)])
                })

                return _range(min, max + 1)
            },

            isCopy() {
                return this.$route.params?.isCopy
            },

            isFormDisabled() {
                return (this.pushMessageId && !this.pushMessage) || this.isFetching || this.isSaving
            },

            isServiceRequired() {
                return (this.pushMessage.type !== 'service') || (this.pushMessage.type === 'service' && this.pushMessage.service !== null) || 'Обязательное поле'
            },

            locationFieldLabels() {
                return PushMessageLocation.fieldLabels
            },

            notSectionPresent() {
                return !this.pushMessage.location.sections || this.pushMessage.location.sections.length === 0
            },

            pageTitle() {
                if (this.pushMessageId) {
                    return 'Редактирование Push сообщения'
                } else {
                    return 'Новое Push сообщение'
                }
            },

            filteredPremises() {
                const floors = this.pushMessage.location.floors
                const pipes = _map(this.pushMessage.location.pipes, (pipe) => pipe.id)
                const sections = _map(this.pushMessage.location.sections, (section) => section.id)

                const hasFloors = floors && floors.length > 0
                const hasPipes = pipes && pipes.length > 0
                const hasSections = sections && sections.length > 0

                let premiseInFloors
                let premiseInPipes
                let premiseInSections

                return _filter(this.premises, (premise) => {
                    premiseInSections = !hasSections || sections.indexOf(premise.section?.id) !== -1
                    premiseInPipes = !hasPipes || pipes.indexOf(premise.pipe?.id) !== -1
                    premiseInFloors = !hasFloors || floors.indexOf(premise.floor) !== -1

                    if (premiseInSections && premiseInPipes && premiseInFloors) {
                        return premise
                    }
                })
            },

            pushMessageId() {
                return this.$route.params?.pushMessageId
            },

            sectionFilter() {
                const buildingsId = _map(this.pushMessage.buildings, (building) => building.id)
                return buildingsId?.length > 0 ? { building__in: buildingsId.join(',') } : null
            },

            pipeFilter() {
                const sectionsId = _map(this.pushMessage.location.sections, (section) => section.id)
                return sectionsId?.length > 0 ? { section__in: sectionsId.join(',') } : null
            },
        },

        watch: {
            pushMessageId: {
                handler() {
                    this.fetchPushMessage()
                },
                immediate: true,
            },
            'pushMessage.buildings': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.sections = []
                    this.pushMessage.location.pipes = []
                    this.pushMessage.location.floors = []
                    this.pushMessage.location.premises = []
                }
                this.fetchPremiseHandler()
            },
            'pushMessage.location.sections': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.pipes = []
                    this.pushMessage.location.floors = []
                    this.pushMessage.location.premises = []
                }
            },
            'pushMessage.location.pipes': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.premises = []
                }
            },
            'pushMessage.location.floors': function (newValue, oldValue) {
                if (oldValue?.length) {
                    this.pushMessage.location.premises = []
                }
            },

            'pushMessage.type': {
                handler() {
                    if (this.pushMessage.type !== 'service') {
                        this.pushMessage.service = null
                    }
                    setTimeout(() => this.$refs.pushMessageForm.validate(), 100)
                },
            },
        },

        mounted() {
            this.$refs.pushMessageForm.validate()
        },

        methods: {
            async fetchPremiseHandler() {
                this.premises = []
                this.errors = []
                for (const building of this.pushMessage.buildings) {
                    await this.fetchPremises(building.id)
                }
            },

            async fetchPremises(buildingId) {
                try {
                    const params = {
                        page: 1,
                        page_size: Number.MAX_SAFE_INTEGER,
                        building: buildingId,
                    }

                    const response = await API.services.premise.getPremises(params)

                    const { results } = response

                    _forEach(results, (premise) => {
                        this.premises.push(new Premise(premise))
                    })
                } catch (e) {
                    this.errors = [...this.errors, ...e]
                }
            },

            async fetchPushMessage() {
                if (this.pushMessageId) {
                    this.isFetching = true
                    try {
                        const response = await API.services.newsletter.getPushMessageById(this.pushMessageId)

                        this.pushMessage = response ? new PushMessage(response) : new PushMessage()

                        this.errors = []
                        this.isFetching = false
                    } catch (e) {
                        this.errors = e
                        this.isFetching = false
                    }
                }
            },

            goBack() {
                const route = {
                    name: 'NewslettersPush',
                }
                this.$router.push(route)
            },

            async save() {
                if (this.$refs.pushMessageForm.validate()) {
                    this.isSaving = true

                    try {
                        const params = this.pushMessage.toArray()

                        if (this.pushMessage.type !== 'nps_rate') {
                            if (this.pushMessageId && !this.isCopy) {
                                await API.services.newsletter.updatePushMessage(this.pushMessageId, params)
                            } else {
                                await API.services.newsletter.createPushMessage(params)
                            }
                        } else {
                            if (this.pushMessageId && !this.isCopy) {
                                await API.services.newsletter.updateNPSPushMessage(this.pushMessageId, params)
                            } else {
                                await API.services.newsletter.createNPSPushMessage(params)
                            }
                        }

                        this.errors = []
                        this.isSaving = false

                        this.goBack()
                    } catch (e) {
                        this.errors = e
                        this.isSaving = false
                    }
                }
            },
        },
    }
</script>
