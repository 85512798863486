import Entity from '_entities/base/Entity'
import DepartmentLink from '_entities/department/DepartmentLink'
import UserOld from '_entities/user/UserOld'

class Worker extends Entity {
    get schema() {
        return {
            department: ['department', undefined, (value) => this.getEntityValue(DepartmentLink, value)],
            id: ['id', undefined, (value) => value], // UUID
            internalPhone: ['internal_phone', undefined, (value) => value],
            isActive: ['is_active', true, (value) => !!value], // Boolean
            netMonetId: ['net_monet_id', undefined, (value) => value],
            position: ['position', undefined, (value) => value], // String
            user: ['user', undefined, (value) => this.getEntityValue(UserOld, value)],
            workingStatus: ['working_status', undefined, (value) => !!value], // Bioolean
            queueNumber: ['queue_number', undefined, (value) => this.getIntegerValue(value)],
        }
    }

    static fieldLabels = {
        department: 'Отдел',
        id: 'ID сотрудника',
        internalPhone: 'Внутренний номер',
        isActive: 'Активный',
        netMonetId: 'Код НетМонет',
        position: 'Должность',
        user: 'Пользователь',
        workingStatus: 'Рабочий статус',
        queueNumber: 'Номер очереди',
    }

    toServer() {
        return {
            department_id: this.department?.id,
            internal_phone: this.internalPhone,
            is_active: this.isActive,
            net_monet_id: this.netMonetId,
            position: this.position,
            working_status: this.workingStatus,
            // User
            email: this.user?.email,
            first_name: this.user?.firstName,
            last_name: this.user?.lastName,
            middle_name: this.user?.middleName,
            phone_number: this.user?.phone,
            queue_number: this.queueNumber === '' ? null : this.queueNumber,
            user: this.user?.id,
        }
    }
}

export default Worker
