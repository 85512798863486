import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementTitle1 extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'title_1', () => 'title_1'],
        }
    }
}

export default ServiceElementTitle1
