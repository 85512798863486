import store from '@/store'

import { filter as _filter, forEach as _forEach, orderBy as _orderBy } from 'lodash'
import moment from 'moment'

import { request } from '_api/base/calendar/client'
import { getBuildings } from '_api/services/building'
import { getDepartments } from '_api/services/department'
import { getWorkers } from '_api/services/user'
import { getTicketDepartments } from '_api/services/ticketDepartment'
import { getTicketKinds } from '_api/services/ticketKind'

import CalendarSlot from '_entities/calendar/CalendarSlot'
import CalendarSlotColor from '_entities/calendar/CalendarSlotColor'

// Calendar

export const getCalendars = async (params) => {
    return await request('GET', 'calendar/', params)
}

export const createCalendar = async (params) => {
    return await request('POST', 'calendar/', params)
}

export const getCalendarById = async (calendarId) => {
    return await request('GET', `calendar/${calendarId}/`)
}

export const updateCalendar = async ({ calendarId, params }) => {
    return await request('PUT', `calendar/${calendarId}/`, params)
}

export const deleteCalendar = async (calendarId) => {
    return await request('DELETE', `calendar/${calendarId}/`)
}

export const getCalendarSlotVacancies = async (calendarId, params) => {
    const vacancies = []
    const response = await request('POST', `calendar/${calendarId}/vacancies/`, params)

    /* структура данных:
        [{'time_start':time_start, 'time_end': time_end,'slot': slot_id}, {...}, ...]
    */

    if (response) {
        _forEach(response, (timeRange) => {
            const date = moment.unix(timeRange.time_start).locale('ru').format('DD MMMM')
            const from = moment.unix(timeRange.time_start).locale('ru').format('HH:mm')
            const to = moment.unix(timeRange.time_end).locale('ru').format('HH:mm')

            vacancies.push({
                date: date,
                text: `${date}: ${from} - ${to}`,
                value: {
                    from: timeRange.time_start,
                    to: timeRange.time_end,
                    slot: timeRange.slot,
                },
            })
        })
    }

    return _orderBy(vacancies, ['from'], ['asc'])
}

// Calendar slot

export const getCalendarSlots = async (params) => {
    let response
    const slots = []
    let calendarSlot
    let isCorrectData
    const _params = {
        page: 1,
        page_size: Number.MAX_SAFE_INTEGER,
    }

    // Подгрузка справочников
    response = await getCalendars(_params)
    const calendars = response?.results

    response = await getDepartments(_params)
    const departments = response?.results

    response = await getWorkers(_params)
    const workers = response?.results

    response = await getBuildings(_params)
    const buildings = response?.results

    response = await getTicketDepartments(_params)
    const ticketDepartments = response?.results

    response = await getTicketKinds(_params)
    const ticketKinds = response?.results

    response = await request('GET', 'calendar_slot/', params)
    const { results } = response

    for (const slot of results) {
        // Вместо объектов в полях приходят их ID.
        // Заполняем их сущностями.

        isCorrectData = true

        // Данные календаря
        if (slot.calendar) {
            slot.calendar = _filter(calendars, (calendar) => calendar.id === slot.calendar)[0]

            if (!slot.calendar) {
                isCorrectData = false
            }
        }

        // Данные исполнителя
        if (slot.executor) {
            slot.executor = _filter(workers, (worker) => worker.id === slot.executor)[0]

            if (!slot.executor) {
                isCorrectData = false
            }
        }

        // Подразделение исполнителя
        if (slot.executorDepartament) {
            slot.executorDepartament = _filter(departments, (department) => department.id === slot.executorDepartament)[0]
        }

        // Данные исполнителя
        if (slot.created_by) {
            slot.created_by = _filter(workers, (worker) => worker.id === slot.created_by)[0]

            if (!slot.executor) {
                isCorrectData = false
            }
        }

        slot.projects = []

        // Данные домов
        if (slot?.buildings?.length) {
            _forEach(slot.buildings, (buildingId, i) => {
                slot.buildings[i] = _filter(buildings, (dictionaryBuilding) => dictionaryBuilding.id === buildingId)[0]

                if (slot.buildings[i]) {
                    if (_filter(slot.projects, (project) => project.id === slot.buildings[i]?.project?.id).length === 0) {
                        slot.projects.push(slot.buildings[i].project)
                    }
                } else {
                    isCorrectData = false
                }
            })
        }

        // Данные типизации
        if (slot.ticket_departments?.length) {
            _forEach(slot.ticket_departments, (ticket_department_id, i) => {
                slot.ticket_departments[i] = _filter(ticketDepartments, (ticketDepartment) => ticketDepartment.id === ticket_department_id)[0]

                if (!slot.ticket_departments[i]) {
                    isCorrectData = false
                }
            })
        }
        if (slot.ticket_kinds?.length) {
            _forEach(slot.ticket_kinds, (ticket_kindId_id, i) => {
                slot.ticket_kinds[i] = _filter(ticketKinds, (ticketKind) => ticketKind.id === ticket_kindId_id)[0]

                if (!slot.ticket_kinds[i]) {
                    isCorrectData = false
                }
            })
        }

        if (slot.ticket_types?.length) {
            _forEach(slot.ticket_types, (ticket_type_id, i) => {
                slot.ticket_types[i] = store.getters['ticketTypes/getTicketTypeById'](ticket_type_id)

                if (!slot.ticket_types[i]) {
                    isCorrectData = false
                }
            })
        }

        // Создание полноценной сущности слота
        calendarSlot = new CalendarSlot(slot)

        // Добавление в результатирующий массив
        if (isCorrectData) {
            slots.push({
                id: slot.id,
                data: calendarSlot,
                color: calendarSlot?.calendar?.color || '#2196F3',
                start: calendarSlot.timeStart,
                end: calendarSlot.timeEnd,
                timed: true,
            })
        }
    }

    return _orderBy(slots, ['start'], ['desc'])
}

export const createCalendarSlot = async (params) => {
    return await request('POST', 'calendar_slot/', params)
}

export const getCalendarSlotById = async (slotId) => {
    return await request('GET', `calendar_slot/${slotId}/`)
}

export const updateCalendarSlot = async ({ slotId, params }) => {
    return await request('PUT', `calendar_slot/${slotId}/`, params)
}

export const deleteCalendarSlot = async (slotId) => {
    return await request('DELETE', `calendar_slot/${slotId}/`)
}

// Calendar event

export const getCalendarEvents = async (params) => {
    return await request('GET', 'calendar_event/', params)
}

export const createCalendarEvent = async (params) => {
    return await request('POST', 'calendar_event/', params)
}

export const getCalendarEventById = async (eventId) => {
    return await request('GET', `calendar_event/${eventId}/`)
}

export const updateCalendarEvent = async ({ eventId, params }) => {
    return await request('PUT', `calendar_event/${eventId}/`, params)
}

export const deleteCalendarEvent = async (eventId) => {
    return await request('DELETE', `calendar_event/${eventId}/`)
}

// Calendar slot color

export const getCalendarSlotColors = async (params) => {
    let response
    const calendarSlotColors = []
    let isCorrectData
    const _params = {
        page: 1,
        page_size: Number.MAX_SAFE_INTEGER,
    }

    // Подгрузка справочников
    response = await getWorkers(_params)
    const workers = response?.results

    response = await getBuildings(_params)
    const buildings = response?.results

    response = await getTicketDepartments(_params)
    const ticketDepartments = response?.results

    response = await getTicketKinds(_params)
    const ticketKinds = response?.results

    response = await request('GET', 'calendar_slot_color/', params)

    const { results } = response

    for (const calendarSlotColor of results) {
        // Вместо объектов в полях приходят их ID.
        // Заполняем их сущностями.

        isCorrectData = true

        // ЖК нет в исходных данных
        calendarSlotColor.projects = []

        // Дома и ЖК
        if (calendarSlotColor?.buildings?.length) {
            _forEach(calendarSlotColor?.buildings, (buildingId, i) => {
                calendarSlotColor.buildings[i] = _filter(buildings, (dictionaryBuilding) => dictionaryBuilding.id === buildingId)[0]

                if (calendarSlotColor.buildings[i]) {
                    if (_filter(calendarSlotColor.projects, (project) => project.id === calendarSlotColor.buildings[i]?.project?.id).length === 0) {
                        calendarSlotColor.projects.push(calendarSlotColor.buildings[i].project)
                    }
                }

                if (!calendarSlotColor.buildings[i]) {
                    isCorrectData = false
                }
            })
        }

        // Исполнители
        if (calendarSlotColor?.executors?.length) {
            _forEach(calendarSlotColor?.executors, (executorId, i) => {
                calendarSlotColor.executors[i] = _filter(workers, (worker) => worker.id === executorId)[0]

                if (!calendarSlotColor.executors[i]) {
                    isCorrectData = false
                }
            })
        }

        // Данные типизации
        if (calendarSlotColor.ticket_departments?.length) {
            _forEach(calendarSlotColor.ticket_departments, (ticket_department_id, i) => {
                calendarSlotColor.ticket_departments[i] = _filter(ticketDepartments, (ticketDepartment) => ticketDepartment.id === ticket_department_id)[0]

                if (!calendarSlotColor.ticket_departments[i]) {
                    isCorrectData = false
                }
            })
        }
        if (calendarSlotColor.ticket_kinds?.length) {
            _forEach(calendarSlotColor.ticket_kinds, (ticket_kindId_id, i) => {
                calendarSlotColor.ticket_kinds[i] = _filter(ticketKinds, (ticketKind) => ticketKind.id === ticket_kindId_id)[0]

                if (!calendarSlotColor.ticket_kinds[i]) {
                    isCorrectData = false
                }
            })
        }
        if (calendarSlotColor.ticket_types?.length) {
            _forEach(calendarSlotColor.ticket_types, (ticket_type_id, i) => {
                calendarSlotColor.ticket_types[i] = store.getters['ticketTypes/getTicketTypeById'](ticket_type_id)

                if (!calendarSlotColor.ticket_types[i]) {
                    isCorrectData = false
                }
            })
        }

        if (isCorrectData) {
            calendarSlotColor.color = '#' + calendarSlotColor.color
            calendarSlotColors.push(new CalendarSlotColor(calendarSlotColor))
        }
    }

    return calendarSlotColors
}

export const getCalendarSlotColorById = async (calendarColorId) => {
    return await request('GET', `calendar_slot_color/${calendarColorId}/`)
}

export const createCalendarSlotColor = async (params) => {
    return await request('POST', 'calendar_slot_color/', params)
}

export const updateCalendarSlotColor = async ({ calendarColorId, params }) => {
    return await request('PUT', `calendar_slot_color/${calendarColorId}/`, params)
}

export const deleteCalendarSlotColor = async (calendarColorId) => {
    return await request('DELETE', `calendar_slot_color/${calendarColorId}/`)
}
