import { request } from '_api/base/behemoth/client'

import { chunk as _chunk } from 'lodash'

export const getChats = async (params) => {
    return await request('GET', 'chat/', params)
}

export const getChatById = async (chatId) => {
    return await request('GET', `chat/${chatId}/`)
}

export const getChatMessages = async (params) => {
    if (params?.chat_id__in) {
        const chatIds = params.chat_id__in.split(',')
        let results = []

        const chatIdChunks = _chunk(chatIds, 30)

        for (const chatIdChunk of chatIdChunks) {
            params.chat_id__in = chatIdChunk.join(',')

            const response = await request('GET', 'chat/messages/', params)

            results = [...results, ...response]
        }

        return results
    } else {
        return await request('GET', 'chat/messages/', params)
    }
}

export const getMessages = async (chatId, params) => {
    return await request('GET', `chat/${chatId}/message/`, params)
}

export const markAsReadMessages = async (chatId) => {
    return await request('DELETE', `chat/${chatId}/unread/`)
}

export const sendMessage = async (chatId, params) => {
    return await request('POST', `chat/${chatId}/message/`, params)
}

export const createChatForTicket = async (ticketId) => {
    return await request('POST', `chat/ticket/${ticketId}/`)
}

export const editMessage = async (chatId, messageId, params) => {
    return await request('PUT', `chat/${chatId}/message/${messageId}/`, params)
}

export const deleteMessage = async (chatId, messageId) => {
    return await request('DELETE', `chat/${chatId}/message/${messageId}/`)
}

export const getChanges = async (chatId) => {
    return await request('GET', `chat/${chatId}/change/`)
}

export const getTypicalAnswers = async () => {
    return await request('GET', 'chat/typical_answer/')
}

export const updateTypicalAnswer = async (typicalAnswerId, params) => {
    return await request('PUT', `chat/typical_answer/${typicalAnswerId}/`, params)
}

export const createTypicalAnswer = async (params) => {
    return await request('POST', 'chat/typical_answer/', params)
}

export const deleteTypicalAnswer = async (typicalAnswerId) => {
    return await request('DELETE', `chat/typical_answer/${typicalAnswerId}/`)
}

export const getChatReport = async (params) => {
    return await request('GETFILE', 'chat/report/', params)
}

// Регламентные сроки ответа
export const getChatAnswerTimeLimitSettings = async (params) => {
    return await request('GET', 'chat/chat_answer_time_limit_setting/', params)
}

export const getChatAnswerTimeLimitSettingById = async (chatAnswerTimeLimitSettingId) => {
    return await request('GET', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`)
}

export const createChatAnswerTimeLimitSettings = async (params) => {
    return await request('POST', 'chat/chat_answer_time_limit_setting/', params)
}

export const deleteChatAnswerTimeLimitSetting = async (chatAnswerTimeLimitSettingId) => {
    return await request('DELETE', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`)
}

export const updateChatAnswerTimeLimitSetting = async (chatAnswerTimeLimitSettingId, params) => {
    return await request('PUT', `chat/chat_answer_time_limit_setting/${chatAnswerTimeLimitSettingId}/`, params)
}
