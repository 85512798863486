import Entity from '_entities/base/EntityNew'

export const TimeEntrySchema = {
    name: 'TimeEntry',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        type: { type: 'string' },
    },
}

class TimeEntry extends Entity {
    get schema() {
        return TimeEntrySchema
    }
}

export default TimeEntry
