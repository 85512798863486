<template>
    <div>
        <v-subheader>{{ label }}</v-subheader>
        <v-row dense>
            <v-col cols="12" sm="6">
                <v-menu
                    v-model="dateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="dateFormatted"
                            v-bind="attrs"
                            clearable
                            label="Дата"
                            outlined
                            prepend-inner-icon="$calendarDay"
                            readonly
                            :rules="$attrs.rules"
                            v-on="on"
                            @click:clear="dateModel = null"
                        />
                    </template>
                    <v-date-picker
                        v-model="dateModel"
                        v-bind="$attrs"
                        :first-day-of-week="1"
                        @input="dateMenu = false"
                    />
                </v-menu>
            </v-col>
            <v-col cols="12" sm="6">
                <v-menu
                    ref="menu"
                    v-model="timeMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="time"
                            :disabled="!date"
                            label="Время"
                            outlined
                            prepend-inner-icon="$calendarDay"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        />
                    </template>
                    <v-time-picker
                        v-if="timeMenu"
                        v-model="timeModel"
                        format="24hr"
                        full-width
                        :min="minTime"
                        @click:minute="$refs.menu.save(timeModel)"
                    />
                </v-menu>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { formatDate, toUnix } from '@/plugins/moment'

    export default {
        name: 'UIDateTimePicker',

        props: {
            label: { type: String },
            value: { type: Number },
        },

        data: () => ({
            dateMenu: false,
            timeMenu: false,
            originalDate: null,
            originalTime: null,
        }),

        computed: {
            dateTime() {
                return formatDate(this.value, 'YYYY-MM-DD HH:mm')
            },

            dateParts() {
                if (this.dateTime) {
                    return this.dateTime.split(' ')
                }

                return []
            },

            date() {
                if (this.dateParts.length === 2) {
                    return this.dateParts[0]
                }

                return null
            },

            dateFormatted() {
                return formatDate(toUnix(this.date), 'LL')
            },

            dateModel: {
                get() {
                    if (this.dateParts.length === 2) {
                        return this.dateParts[0]
                    }

                    return null
                },
                set(date) {
                    if (date) {
                        const time = (this.time || '00:00') + ':00'

                        const dateTime = `${date} ${time}`

                        this.value = toUnix(dateTime)
                    } else {
                        this.value = null
                    }
                },
            },

            time() {
                if (this.dateParts.length === 2) {
                    return this.dateParts[1]
                }

                return null
            },

            timeModel: {
                get() {
                    return this.time
                },
                set(value) {
                    const time = value ? value + ':00' : null

                    if (time) {
                        const date = this.date

                        const dateTime = `${date} ${time}`

                        this.value = toUnix(dateTime)
                    }
                },
            },

            minTime() {
                if (this.originalDate === this.dateModel) {
                    return this.originalTime
                }

                return null
            },
        },

        watch: {
            // v-model
            value() {
                this.onInput()
            },

            dateModel: {
                handler(value) {
                    if (!this.originalDate) {
                        this.originalDate = this.dateModel
                    }
                    if (!this.originalTime) {
                        this.originalTime = this.timeModel
                    }

                    if ((this.originalDate === this.dateModel) && (this.originalTime > this.timeModel)) {
                        this.timeModel = this.originalTime
                    }
                },
                immediate: true,
            },
        },

        methods: {
            // v-model
            onInput() {
                this.$emit('input', this.value)
            },
        },
    }
</script>
