import { map as _map } from 'lodash'
import moment from 'moment'

import BuildingLink from '_entities/building/BuildingLink'
import CityLink from '_entities/city/CityLink'
import DatetimeStringRange from '_entities/range/DatetimeStringRange'
import Entity from '_entities/base/EntityNew'
import PremiseLink from '_entities/premise/PremiseLink'
import PremiseType from '_entities/premise/PremiseType'
import ProjectLink from '_entities/project/ProjectLink'
import Range from '_entities/range/Range'
import Section from '_entities/section/Section'
import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketKindLink from '_entities/ticket/TicketKindLink'
import TicketSourceLink from '_entities/ticket/TicketSourceLink'
import TicketTypeLink from '_entities/ticket/TicketTypeLink'
import UserLink from '_entities/user/UserLink'

export const TicketRouteSchema = {
    name: 'Premise',
    primaryKey: 'id',
    properties: {
        auditors: { type: 'UserLink[]', default: [], entity: UserLink },
        buildingValues: { type: 'BuildingLink[]', default: [], entity: BuildingLink },
        cityValues: { type: 'CityLink[]', default: [], entity: CityLink },
        datetimeValues: { type: 'DatetimeStringRange[]', default: [], entity: DatetimeStringRange },
        dateValues: { type: 'Range[]', default: [], entity: Range },
        dayOfWeekValues: { type: 'string[]', default: [] },
        executors: { type: 'UserLink[]', default: [], entity: UserLink },
        floorValues: { type: 'Range[]', default: [], entity: Range },
        id: { type: 'string' },
        isExecutorFromTimesheet: { type: 'bool', default: false },
        loadToBitrix: { type: 'bool', default: true },
        name: { type: 'string', default: '' },
        premiseTypes: { type: 'PremiseType[]', default: [], entity: PremiseType },
        premiseValues: { type: 'PremiseLink[]', default: [], entity: PremiseLink },
        projectValues: { type: 'ProjectLink[]', default: [], entity: ProjectLink },
        responsible: { type: 'UserLink', entity: UserLink },
        sectionValues: { type: 'Section[]', default: [], entity: Section },
        sourceValues: { type: 'TicketSourceLink[]', default: [], entity: TicketSourceLink },
        ticketDepartmentValues: { type: 'TicketDepartmentLink[]', default: [], entity: TicketDepartmentLink },
        ticketKindValues: { type: 'TicketKindLink[]', default: [], entity: TicketKindLink },
        ticketTypeValues: { type: 'TicketTypeLink[]', default: [], entity: TicketTypeLink },
        timeValues: { type: 'Range[]', default: [], entity: Range },
        whoAppointsExecutor: { type: 'UserLink', entity: UserLink },
    },
}

class TicketRoute extends Entity {
    get schema() {
        return TicketRouteSchema
    }

    get dataMap() {
        return {
            building_values: 'buildingValues',
            city_values: 'cityValues',
            datetime_values: 'datetimeValues',
            date_values: 'dateValues',
            day_of_week_values: 'dayOfWeekValues',
            department_values: 'ticketDepartmentValues',
            floor_values: 'floorValues',
            is_executor_from_timesheet: 'isExecutorFromTimesheet',
            kind_values: 'ticketKindValues',
            load_to_bitrix: 'loadToBitrix',
            premise_types: 'premiseTypes',
            premise_values: 'premiseValues',
            project_values: 'projectValues',
            section_values: 'sectionValues',
            source_values: 'sourceValues',
            time_values: 'timeValues',
            type_values: 'ticketTypeValues',
            who_appoints_executor: 'whoAppointsExecutor',
        }
    }

    static fieldLabels = {
        auditors: 'Наблюдатели',
        buildingValues: 'Дома',
        cityValues: 'Города',
        datetimeValues: 'Дата время',
        dateValues: 'Даты',
        dayOfWeekValues: 'Дни недели',
        executors: 'Исполнители',
        floorValues: 'Этажи',
        id: 'ID',
        isExecutorFromTimesheet: 'Исполнитель из начавших смену',
        loadToBitrix: 'Выгружать в Битрикс',
        name: 'Название',
        premiseTypes: 'Типы помещений',
        premiseValues: 'Квартиры',
        projectValues: 'ЖК',
        responsible: 'Ответственный',
        sectionValues: 'Подъезды',
        sourceValues: 'Источники',
        ticketDepartmentValues: 'Отделы',
        ticketKindValues: 'Виды',
        ticketTypeValues: 'Типы',
        timeValues: 'Часы',
        whoAppointsExecutor: 'Назначающий исполнителей',
    }

    toArray() {
        return {
            ...super.toArray(),
            auditors: _map(this.auditors, (auditor) => auditor.id),
            building_values: _map(this.buildingValues, (building) => building.id),
            city_values: _map(this.cityValues, (city) => city.id),
            date_values: _map(this.dateValues, (dates) => ({
                from: moment.unix(dates.from).locale('ru').format('YYYY-MM-DD'),
                to: moment.unix(dates.to).locale('ru').format('YYYY-MM-DD'),
            })),
            datetime_values: _map(this.datetimeValues, (dates) => {
                return {
                    from: moment(dates.from).format('YYYY-MM-DDTHH:mmZ'),
                    to: moment(dates.to).format('YYYY-MM-DDTHH:mmZ'),
                }
            }),
            day_of_week_values: this.dayOfWeekValues || [],
            department_values: _map(this.ticketDepartmentValues, (ticketDepartment) => ticketDepartment.id),
            executors: _map(this.executors, (executor) => executor.id),
            floor_values: this.floorValues || '',
            is_executor_from_timesheet: this.isExecutorFromTimesheet,
            kind_values: _map(this.ticketKindValues, (ticketKind) => ticketKind.id),
            load_to_bitrix: this.loadToBitrix,
            name: this.name,
            premise_types: _map(this.premiseTypes, (type) => type.id),
            premise_values: _map(this.premiseValues, (premise) => premise.id),
            project_values: _map(this.projectValues, (project) => project.id),
            responsible: this.responsible?.id || null,
            section_values: _map(this.sectionValues, (section) => section.id),
            source_values: _map(this.sourceValues, (source) => source.id),
            time_values: this.timeValues || '',
            type_values: _map(this.ticketTypeValues, (ticketType) => ticketType.id),
            who_appoints_executor: this.whoAppointsExecutor?.id || null,
        }
    }
}

export default TicketRoute
