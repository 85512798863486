import API from '_api'

import CameraGroup from '_entities/camera/CameraGroup'

const actions = {
    clearCameraGroups({ commit }) {
        commit('clearCameraGroups')
    },

    async fetchCameraGroups({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchCameraGroupsRequest')

            try {
                const response = await API.services.camera.getCameraGroups(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new CameraGroup(result))
                })

                commit('fetchCameraGroupsSuccess', { items })
            } catch (e) {
                commit('fetchCameraGroupsError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
