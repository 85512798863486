import API from '_api'

import TicketType from '_entities/ticket/TicketType'

const actions = {
    async fetchTicketTypes({ commit }, params) {
        commit('fetchTicketTypesRequest')

        const _params = {
            ...params,
            page: 1,
            page_size: Number.MAX_SAFE_INTEGER,
        }

        try {
            const response = await API.services.ticketType.getTicketTypes(_params)

            const { results } = response

            const items = []
            results.forEach((result) => {
                items.push(new TicketType(result))
            })

            commit('fetchTicketTypesData', { items })
            commit('fetchTicketTypesSuccess')
        } catch (e) {
            commit('fetchTicketTypesError', e)

            return Promise.reject(e)
        }
    },
}

export default actions
