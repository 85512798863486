import { request } from '_api/base/behemoth/client'

export const getDepartments = async (params) => {
    return await request('GET', 'department/', params)
}

export const getDepartmentById = async (departmentId) => {
    return await request('GET', `department/${departmentId}`)
}

export const updateDepartment = async ({ departmentId, data }) => {
    return await request('PUT', `department/${departmentId}/`, data)
}

export const createDepartment = async ({ data }) => {
    return await request('POST', 'department/', data)
}

export const deleteDepartment = async (departmentId) => {
    return await request('DELETE', `department/${departmentId}/`)
}

export const getDepartmentOffices = async () => {
    return await request('GET', 'department/office/')
}
