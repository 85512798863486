import { request } from '_api/base/behemoth/client'

export const getApprovals = async (params) => {
    return await request('GET', 'department/approval/', params)
}

export const getApprovalById = async (approvalId) => {
    return await request('GET', `department/approval/${approvalId}/`)
}

export const setApproval = async (approvalId, params) => {
    return await request('POST', `department/approval/${approvalId}/`, params)
}
