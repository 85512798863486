import API from '_api'

import ServiceDiscountCardCategory from '_entities/service/ServiceDiscountCardCategory'

const actions = {
    async fetchServiceDiscountCardCategories({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchServiceDiscountCardCategoriesRequest')

            try {
                const response = await API.services.service.getServiceDiscountCardCategories(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new ServiceDiscountCardCategory(result))
                })

                commit('fetchServiceDiscountCardCategoriesSuccess', { items })
            } catch (e) {
                commit('fetchServiceDiscountCardCategoriesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
