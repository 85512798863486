import { map as _map } from 'lodash'

import Entity from '_entities/base/Entity'

import BuildingLink from '_entities/building/BuildingLink'
import CompanyLink from '_entities/company/CompanyLink'
import NewsCategoryLink from '_entities/news/NewsCategoryLink'
import Photo from '_entities/media/Photo'

class News extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            title: ['title', undefined, (value) => value], // String
            shortText: ['short_text', undefined, (value) => value], // String
            text: ['text', undefined, (value) => value], // String
            isActive: ['is_active', undefined, (value) => !!value], // Boolean
            date: ['date', undefined, (value) => this.getIntegerValue(value)],
            image: ['image', undefined, (value) => this.getEntityValue(Photo, value)],
            companies: ['companies', [], (value) => value.map((item) => this.getEntityValue(CompanyLink, item))],
            buildings: ['buildings', [], (value) => value.map((item) => this.getEntityValue(BuildingLink, item))],
            extLink: ['ext_link', undefined, (value) => value], // String
            category: ['category', undefined, (value) => this.getEntityValue(NewsCategoryLink, value)],
            gallery: ['gallery', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        shortText: 'Короткий текст',
        text: 'Текст',
        isActive: 'Статус активности',
        date: 'Дата публикации',
        image: 'Изображение',
        companies: 'Организации',
        buildings: 'Дома',
        category: 'Категория',
        extLink: 'Внешняя ссылка',
        gallery: 'Галерея',
    }

    toServer() {
        return {
            title: this.title,
            short_text: this.shortText,
            text: this.text,
            date: this.date,
            image: this.image?.id,
            is_active: this.isActive,
            ext_link: this.extLink,
            companies: _map(this.companies, (item) => item?.id),
            buildings: _map(this.buildings, (item) => item?.id),
            category: this.category?.id,
            gallery: _map(this.gallery, (item) => item?.id),
        }
    }
}

export default News
