import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementTime extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'time', () => 'time'],
        }
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            timezone: this.timezone,
        }
    }
}

export default ServiceElementTime
