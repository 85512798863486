import { request } from '_api/base/behemoth/client'

export const getWorkers = async (params) => {
    return await request('GET', 'department/worker/', params)
}

export const getWorkerUsers = async (params) => {
    return await request('GET', 'department/worker/users/', params)
}

export const getWorkerById = async (workerId) => {
    return await request('GET', `department/worker/${workerId}/`)
}

export const updateWorker = async ({ workerId, data }) => {
    return await request('PUT', `department/worker/${workerId}/`, data)
}

export const createWorker = async ({ data }) => {
    return await request('POST', 'department/worker/', data)
}

export const getWorkerGroups = async (workerId) => {
    return await request('GET', `department/worker/${workerId}/group/`)
}

export const updateWorkerGroups = async ({ workerId, data }) => {
    return await request('PUT', `department/worker/${workerId}/group/`, data)
}

export const getCurrentWorker = async () => {
    return await request('GET', 'department/worker/current/')
}

export const changeWorkStatus = async () => {
    return await request('PUT', 'department/worker/change_status_for_current_worker/')
}
