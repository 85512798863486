import API from '_api'

import Worker from '_entities/worker/Worker'

const actions = {
    async fetchWorkers({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchWorkersRequest')

            try {
                const response = await API.services.worker.getWorkers(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new Worker(result))
                })

                commit('fetchWorkersSuccess', { items })
            } catch (e) {
                commit('fetchWorkersError', e)
            }
        }
    },
}

export default actions
