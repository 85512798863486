import { request } from '_api/base/behemoth/client'

export const getBuildings = async (params) => {
    return await request('GET', 'building/', params)
}
export const getBuildingById = async (buildingId) => {
    return await request('GET', `building/${buildingId}/`)
}

export const updateBuilding = async ({ buildingId, data }) => {
    return await request('PUT', `building/${buildingId}/`, data)
}
