<template>
    <v-dialog
        v-model="isShownDialog"
        max-width="500px"
        persistent
        scrollable="false"
    >
        <v-card flat>

            <v-card-text>
                <v-row dense align="center">
                    <v-col cols="auto">
                        <v-card-title class="text-h5">Входящий звонок</v-card-title>
                    </v-col>
                    <v-spacer />
                    <v-col cols="auto">
                        <v-btn icon @click="hideDialog">
                            <v-icon v-text="'$minus'" />
                        </v-btn>
                    </v-col>
                    <v-col cols="auto">
                        <v-btn icon @click="closeDialog">
                            <v-icon v-text="'$close'" />
                        </v-btn>
                    </v-col>
                </v-row>

                <v-divider class="mb-10" />

                <v-alert v-for="error in errors" :key="`error_${error.code}`" type="error" dense>
                    {{ error.detail }}
                </v-alert>

                <v-row>
                    <v-col class="d-flex justify-center">
                        <v-avatar>
                            <img
                                v-if="applicantPhotoUrl"
                                alt="Avatar"
                                :src="applicantPhotoUrl"
                            />
                            <v-icon v-else v-text="'$userCircle'" />
                        </v-avatar>
                    </v-col>
                </v-row>

                <v-row v-if="resident">
                    <v-list-item
                        target="_blank"
                        :to="{ name: 'ApplicantView', params: { applicantId: resident.id } }"
                    >
                        <v-list-item-content>
                            <v-row dense>
                                <v-col class="d-flex justify-center">
                                    {{ residentName }}
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col class="d-flex justify-center">
                                    {{ residentPhone }}
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
                <template v-else>
                    <v-row dense>
                        <v-col class="d-flex justify-center">
                            {{ residentName }}
                        </v-col>
                    </v-row>
                    <v-row dense>
                        <v-col class="d-flex justify-center">
                            {{ residentPhone }}
                        </v-col>
                    </v-row>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="primary"
                    @click="createTicket"
                >
                    Создать заявку
                </v-btn>
                <v-spacer />
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'FIncomingCallDialog',

        data: () => ({
            errors: [],
            isFetching: false,
        }),

        computed: {
            ...mapState('incomingCall', {
                resident: (state) => state.resident,
                callerId: (state) => state.callerId,
                callerPhone: (state) => state.callerPhone,
                isShownDialog: (state) => state.isShownDialog,
            }),

            residentPhotoUrl() {
                return this.resident?.photo?.getSize('l')?.url
            },

            residentName() {
                return this.resident?.fullName || 'Клиент не определен'
            },

            residentPhone() {
                return this.resident?.phone || this.callerPhone || 'Телефонный номер не определен'
            },
        },

        methods: {
            closeDialog() {
                this.$store.commit('incomingCall/resetData')
            },

            createTicket() {
                this.hideDialog()
                this.$router.push({ name: 'TicketCreate', params: { isFromCall: true } })
            },

            hideDialog() {
                this.$store.commit('incomingCall/hideDialog')
            },
        },
    }
</script>
