<template>
    <v-switch
        v-if="isAllowCall"
        v-model="queueStatusModel"
        :disabled="isChanging"
        label="Прием звонков"
        hide-details
        @click="changeCallState()"
    />
</template>

<script>
    import { forEach as _forEach } from 'lodash'
    import { mapState } from 'vuex'

    import API from '_api'

    import { PERMISSIONS } from '_features/permissions/constants'

    export default {
        name: 'UIIncomeCalls',

        data: () => ({
            isChanging: false,
            getIncomeCalls: false,
        }),

        computed: {
            ...mapState('calls', {
                queueStatus: (state) => state.queueStatus,
            }),
            ...mapState('auth', {
                currentWorker: (state) => state.currentWorker,
            }),

            queueStatusModel: {
                get() {
                    return this.queueStatus
                },
                set(value) {
                    this.$store.commit('calls/setQueueStatus', { queueStatus: value })
                },
            },

            isAllowCall() {
                return this.$hasPermission([PERMISSIONS.CAN_ADD_CALL]) && this.currentWorker.queueNumber
            },
        },

        methods: {
            async changeCallState(state) {
                if (!this.currentWorker?.queueNumber) {
                    return
                }

                this.isChanging = true

                try {
                    const doQueueExit = state === 'finish' || (!state && !this.queueStatusModel)
                    const doQueueEnter = state === 'start' || (!state && this.queueStatusModel)

                    if (doQueueEnter) {
                        await API.services.queue.addUser()

                        this.$root.pushNotification({
                            text: 'Успешный вход в очередь звонков',
                            color: 'green',
                        })
                    }

                    if (doQueueExit) {
                        await API.services.queue.removeUser()

                        this.$root.pushNotification({
                            text: 'Успешный выход из очереди звонков',
                            color: 'green',
                        })
                    }

                    await this.$store.dispatch('calls/fetchQueueStatus', { workerId: this.currentWorker?.id })
                } catch (e) {
                    _forEach(e, (error) => {
                        const message = error?.detail || error?.message
                        this.$root.pushNotification({
                            text: 'Не удалось изменить статус в очереди: ' + message,
                            color: 'red',
                        })
                    })

                    this.queueStatusModel = !this.queueStatusModel
                }

                this.isChanging = false
            },
        },
    }
</script>
