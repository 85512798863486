import { map as _map } from 'lodash'

import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'
import TicketCancelReason from '_entities/ticket/TicketCancelReason'
import TicketKindLink from '_entities/ticket/TicketKindLink'

export const TicketTypeSchema = {
    name: 'TicketType',
    primaryKey: 'id',
    properties: {
        assignmentType: { type: 'string' }, // 'assigned' || 'planned' || 'emergency'
        cancelReasons: { type: 'TicketCancelReason[]', entity: TicketCancelReason, default: [] },
        code: { type: 'string' },
        id: { type: 'string' },
        instruction: { type: 'Doc', entity: Doc, optional: true },
        name: { type: 'string' },
        order: { type: 'int' },
        requestCancelReason: { type: 'boolean' },
        ticketKind: { type: 'TicketKindLink', entity: TicketKindLink },
        timeToRefine: { type: 'int', default: 0 },
        timeToRefineType: { type: 'string', default: 'minutes' },
    },
}

class TicketType extends Entity {
    get schema() {
        return TicketTypeSchema
    }

    get dataMap() {
        return {
            assignment_type: 'assignmentType',
            cancel_reasons: 'cancelReasons',
            request_cancel_reason: 'requestCancelReason',
            ticket_kind: 'ticketKind',
            time_to_refine: 'timeToRefine',
        }
    }

    static fieldLabels = {
        assignmentType: 'Класс заявки',
        cancelReasons: 'Причины отмены',
        code: 'Код',
        id: 'ID',
        instruction: 'Инструкция',
        name: 'Название',
        order: 'Порядок',
        requestCancelReason: 'Запрос причины отмены',
        ticketKind: 'Вид заявки',
        timeToRefine: 'Кол-во времени',
    }

    static timeToRefineTypes = [
        { value: 'minutes', text: 'минуты' },
        { value: 'hours', text: 'часы' },
        { value: 'days', text: 'дни' },
    ]

    toArray() {
        return {
            ...super.toArray(),
            instruction: this.instruction?.id || null,
            ticket_kind: this.ticketKind?.id,
            time_to_refine: this.timeToRefine,
            request_cancel_reason: this.requestCancelReason,
            cancel_reasons: _map(this.cancelReasons, (cancelReason) => {
                return {
                    code: cancelReason.code,
                    order: cancelReason.order,
                }
            }),
        }
    }
}

export default TicketType
