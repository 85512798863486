import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/Entity'

import FilterField from '_entities/filter/FilterField'

class FilterPreset extends Entity {
    get schema() {
        return {
            id: ['id', uuidV4(), (value) => value],
            code: ['code', undefined, (value) => value],
            name: ['name', undefined, (value) => value],
            fields: [
                'fields', [], (value) => value.map((o) => {
                    const entity = this.getEntityValue(FilterField, o)

                    return entity?.simplified
                }),
            ],
            isSystem: ['isSystem', undefined, (value) => !!value],
        }
    }

    static fieldLabels = {
        name: 'Название',
        code: 'Символьный код',
        fields: 'Поля',
        isSystem: 'Системный',
    }

    get simplified() {
        if (!this.isSystem) {
            return {
                id: this.id,
                code: this.code,
                name: this.name,
                fields: this.fields,
            }
        }
    }
}

export default FilterPreset
