<template>
    <UIAutocomplete
        v-bind="$attrs"
        v-model="model"
        :apiMethod="method"
        :filter="customFilter"
        :getEntity="getEntity"
        item-text="name"
        item-value="id"
        :label="label"
        :sort-by="['name']"
        :sort-orders="['asc']"
    >
        <template slot="item" slot-scope="{ item }">
            <v-list-item-content>
                <v-list-item-title>{{ item.displayName }}</v-list-item-title>
                <v-list-item-subtitle>{{ item.internalName || '-' }}</v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </UIAutocomplete>
</template>

<script>
    import API from '_api'

    import Building from '_entities/building/Building'

    import UIAutocomplete from '_ui/forms/UIAutocomplete.vue'

    export default {
        name: 'FBuildingPicker',

        components: {
            UIAutocomplete,
        },

        props: {
            label: { type: String, default: 'Дом' },
            value: { type: [Array, Object] },
        },

        computed: {
            model: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                },
            },

            method() {
                return API.services.building.getBuildings
            },
        },

        methods: {
            customFilter(item, queryText) {
                if (queryText) {
                    const fullText = (item.displayName + ' ' + (item.internalName || '')).toLocaleLowerCase()

                    // формируем регулярное выражение поиска подстрок.
                    // учитываем только текст и цифры во всех вариациях имени.
                    let clearQueryText = queryText.trim().replace(/[^a-zA-Zа-яА-Я0-9]/g, ' ').toLocaleLowerCase()

                    // чистка от двойных пробелов, чтобы не сломать регулярку
                    clearQueryText = clearQueryText.replace(/\s{2}/g, ' ')

                    const regText = '(' + clearQueryText.split(' ').join('|') + ')'
                    const regEx = new RegExp(regText, 'g')

                    // поиск всех подстрок
                    return fullText.match(regEx)
                }

                return true
            },

            getEntity(data) {
                return new Building(data)
            },
        },
    }
</script>
