import '@/plugins/jquery'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify/'
import './scss/main.scss'

import CDebug from './common/components/CDebug'

Vue.config.productionTip = false

// TODO: Vuetify bug
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <svg>.'
Vue.config.warnHandler = function (msg, vm, trace) {
    // `trace` is the component hierarchy trace
    if (msg === ignoreWarnMessage) {
        msg = null
        vm = null
        trace = null
    }
}

// For debug
Vue.component('c-debug', CDebug)

// yandex metrica
Vue.prototype.$goal = function (goal, params) {
    if (process.env.NODE_ENV === 'production') {
        if (params) {
            const strParams = JSON.stringify(params)
            // eslint-disable-next-line no-eval
            eval(`ym(85778472,'reachGoal','${goal}',${strParams})`)
        } else {
            // eslint-disable-next-line no-eval
            eval(`ym(85778472,'reachGoal','${goal}')`)
        }
    }
}

Vue.prototype.$hasPermission = function (checkPermissions) {
    return store.getters['permissions/hasPermission'](checkPermissions)
}

new Vue({
    router,
    store,
    vuetify,
    methods: {
        async pushNotification(data) {
            await store.dispatch('notifications/pushNotification', data)
        },
    },
    render: (h) => h(App),
}).$mount('#app')
