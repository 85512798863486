<template>
    <v-card color="yellow" flat>
        <v-btn
            color="primary"
            text
            @click="restoreDialog"
        >
            <v-row
                align="baseline"
                dense
            >
                <v-col cols="auto">
                    <v-icon
                        v-text="'$phoneAlt'"
                        color="red"
                        :size="iconSize"
                    />
                </v-col>
                <v-col cols="auto">
                    {{ caption }}
                </v-col>
            </v-row>
        </v-btn>
    </v-card>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'FIncomingCallButton',

        data: () => ({
            iconSize: 20,
            animateDirection: 1,
            interval: null,
        }),

        computed: {
            ...mapState('incomingCall', {
                resident: (state) => state.resident,
            }),

            caption() {
                return this.resident?.fullName || 'Неизвестный клиент'
            },
        },

        mounted() {
            this.interval = setInterval(this.iconAnimator, 100)
        },

        destroy() {
            clearInterval(this.interval)
        },

        methods: {
            iconAnimator() {
                this.iconSize -= this.animateDirection

                if (this.iconSize === 15 || this.iconSize === 25) {
                    this.animateDirection = -this.animateDirection
                }
            },

            restoreDialog() {
                this.$store.commit('incomingCall/showDialog')
            },
        },
    }
</script>
