import absenceScheduleRoutes from '@/router/Home/AbsenceSchedule'
import buildingsRoutes from '@/router/Home/Buildings'
import calendarRoutes from '@/router/Home/Calendar'
import chatRoutes from '@/router/Home/Chat'
import citiesRoutes from '@/router/Home/Cities'
import companiesRoutes from '@/router/Home/Companies'
import dashboardRoutes from '@/router/Home/Dashboard'
import experimentalFeaturesRoutes from '@/router/Home/ExperimentalFeatures'
import documentRoutes from '@/router/Home/Document'
import imRoutes from '@/router/Home/IM'
import indicationRoutes from '@/router/Home/Indications'
import newslettersRoutes from '@/router/Home/Newsletters'
import profileRoutes from '@/router/Home/Profile'
import projectsRoutes from '@/router/Home/Projects'
import userAppRoutes from '@/router/Home/UserApp'
import ticketsRoutes from '@/router/Home/Tickets'
import ticketsSetupRoutes from '@/router/Home/TicketsSetup'
import workersRoutes from '@/router/Home/Workers'
import departmentsRoutes from '@/router/Home/Departments'
import residentialFundRoutes from '@/router/Home/ResidentialFund'
import telephonyRoutes from '@/router/Home/Telephony'
import analyticsRoutes from '@/router/Home/Analytics'
import knowledgeBase from '@/router/Home/KnowledgeBase'
import storage from '@/router/Home/Storage'
import users from '@/router/Home/Users'

const HomeNavigator = () => import('_navigations/HomeNavigator')

const homeRoutes = [
    {
        path: '/home',
        name: 'Home',
        component: HomeNavigator,
        redirect: { name: 'Dashboard' },
        children: [
            ...absenceScheduleRoutes,
            ...buildingsRoutes,
            ...calendarRoutes,
            ...chatRoutes,
            ...citiesRoutes,
            ...companiesRoutes,
            ...dashboardRoutes,
            ...experimentalFeaturesRoutes,
            ...documentRoutes,
            ...imRoutes,
            ...indicationRoutes,
            ...newslettersRoutes,
            ...profileRoutes,
            ...projectsRoutes,
            ...ticketsRoutes,
            ...ticketsSetupRoutes,
            ...userAppRoutes,
            ...workersRoutes,
            ...departmentsRoutes,
            ...residentialFundRoutes,
            ...telephonyRoutes,
            ...analyticsRoutes,
            ...knowledgeBase,
            ...storage,
            ...users,
        ],
    },
]

export default homeRoutes
