import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementDateTime extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'datetime', () => 'datetime'],
            timezone: ['timezone', null, (value) => value],
        }
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            timezone: this.timezone,
        }
    }
}

export default ServiceElementDateTime
