import Entity from '_entities/base/Entity'

class CompanyEmail extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            email: ['email', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        id: 'ID',
        email: 'Email',
    }

    getEmailText() {
        return this.email
    }

    toServer() {
        return {
            id: this.id,
            email: this.email,
        }
    }
}

export default CompanyEmail
