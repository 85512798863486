import { forEach as _forEach } from 'lodash'

import { request } from '_api/base/behemoth/client'

import UserOld from '_entities/user/UserOld'

export const getExternalUser = async (userExtId) => {
    return await request('GET', `user/user/external/${userExtId}/`)
}

export const addResident = async (params) => {
    return await request('POST', 'user/resident/', params)
}

export const getResidents = async (params) => {
    try {
        const response = await request('GET', 'user/resident/', params)

        const { count, results } = response

        const entities = []
        _forEach(results, (result) => {
            entities.push(new UserOld(result))
        })

        return { count, results: entities }
    } catch (errors) {
        return Promise.reject(errors)
    }
}

export const getResidentById = async (residentId) => {
    return await request('GET', `user/resident/${residentId}/`)
}

export const updateResident = async (residentId, params) => {
    return await request('PUT', `user/resident/${residentId}/`, params)
}

export const getUserGroups = async () => {
    return await request('GET', 'user/group/')
}

export const getUserOwners = async (params) => {
    return await request('GET', 'user/owner/', params)
}

// User (new)

export const getUsers = async (params) => {
    return await request('GET', 'user/user/', params)
}

export const getUserById = async (userId) => {
    return await request('GET', `user/user/${userId}/`)
}

export const changeUserPhone = async (userId, params) => {
    return await request('POST', `/user/user/${userId}/change_phone/`, params)
}

// Workers

export const getWorkers = async (params) => {
    const extParams = {
        ...params,
        workers: true,
    }
    return await request('GET', 'user/user/', extParams)
}
