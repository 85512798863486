import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementSendMessage extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'send_message', () => 'send_message'],
            deliveryMethod: ['delivery_method', undefined, (value) => value], // String: 'push' || 'chat'
            sendSms: ['send_sms', false, (value) => !!value], // boolean
            smsText: ['sms_text', undefined, (value) => value], // String
            text: ['text', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        deliveryMethod: 'Метод доставки',
        sendSms: 'Отправлять СМС при отсутсвии МПК',
        smsText: 'Текст СМС-сообщения',
        text: 'Текст сообщения',
    }

    toServer() {
        return {
            ...super.toServer(this),
            delivery_method: this.deliveryMethod,
            send_sms: this.sendSms,
            sms_text: this.smsText,
            text: this.text,
        }
    }
}

export default ServiceElementSendMessage
