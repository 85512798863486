import API from '_api'

import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

const actions = {
    async fetchServicePartnerCompanies({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchServicePartnerCompaniesRequest')

            try {
                const response = await API.services.service.getServicePartnerCompanies(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new ServicePartnerCompany(result))
                })

                commit('fetchServicePartnerCompaniesSuccess', { items })
            } catch (e) {
                commit('fetchServicePartnerCompaniesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
