import Entity from '_entities/base/Entity'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'

/*
export const CameraGroupSchema = {
    name: 'CameraGroup',
    primaryKey: 'id',
    properties: {
        building: { type: 'Building', entity: Building },
        company: { type: 'Company', entity: Company },
        id: { type: 'int' },
        isActive: { type: 'bool', default: false },
        name: { type: 'string' },
    },
}
*/

class CameraGroup extends Entity {
    get schema() {
        return {
            building: ['building', undefined, (value) => this.getEntityValue(BuildingLink, value)],
            company: ['company', undefined, (value) => this.getEntityValue(CompanyLink, value)],
            id: ['id', undefined, (value) => this.getIntegerValue(value)],
            isActive: ['is_active', false, (value) => !!value], // Boolean
            name: ['name', undefined, (value) => value], // String
        }
    }

    get dataMap() {
        return {
            is_active: 'isActive',
        }
    }

    static fieldLabels = {
        building: 'Дом',
        company: 'Компания',
        id: 'ID',
        isActive: 'Активность',
        name: 'Название',
    }

    toServer() {
        return {
            building: this.building?.id,
            company: this.company?.id,
            is_active: this.isActive,
            name: this.name,
        }
    }
}

export default CameraGroup
