import { request } from '_api/base/behemoth/client'

export const getStories = async (params) => {
    return await request('GET', 'story/', params)
}

export const getStoryById = async (storyId) => {
    return await request('GET', `story/${storyId}/`)
}

export const createStory = async ({ data }) => {
    return await request('POST', 'story/', data)
}

export const updateStory = async ({ storyId, data }) => {
    return await request('PUT', `story/${storyId}/`, data)
}

export const deleteStory = async (storyId) => {
    return await request('DELETE', `story/${storyId}/`)
}
