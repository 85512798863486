<template>
    <div>
        <v-switch
            v-if="htmlEditor"
            v-model="showEditor"
            label="Редактор"
        />
        <v-card v-if="htmlEditor && showEditor" class="rounded-5 rounded-card" outlined>
            <div>
                <v-subheader class="card-label">
                    {{ $attrs.label }}
                </v-subheader>
                <UIEditor
                    v-bind="$attrs"
                    v-model="value"
                    :config="editorConfig"
                    :editor="editor"
                />
            </div>
        </v-card>
        <v-textarea v-else v-bind="$attrs" v-model="value" />
    </div>
</template>

<script>
    import CKEditor from '@ckeditor/ckeditor5-vue'

    import Editor from '@/plugins/ckeditor/ckeditor.js'

    export default {
        name: 'UITextarea',

        components: {
            UIEditor: CKEditor.component,
        },

        props: {
            value: { type: String },
            htmlEditor: { type: Boolean },
        },

        data: () => ({
            editor: Editor,
            showEditor: true,
            editorConfig: {
                toolbar: [
                    'heading',
                    '|',
                    'bold',
                    'italic',
                    'link',
                    'bulletedList',
                    'numberedList',
                    // 'imageUpload',
                    'blockQuote',
                    'undo',
                    'redo',
                ],
            },
        }),

        watch: {
            // v-model
            value() {
                this.onInput()
            },
        },

        methods: {
            onInput() {
                this.$emit('input', this.value)
            },
        },
    }
</script>

<style scoped>
    .rounded-card {
        padding: 10px;
        border-color: #9e9e9e;
    }

    .card-label {
        position: absolute;
        left: 8px;
        top: -25px;
        background: white;
        padding: 0px 4px;
        font-size: 12px;
    }
</style>
