import { request } from '_api/base/behemoth/client'

export const getProxies = async (params) => {
    return await request('GET', 'proxy/', params)
}

export const getProxyById = async (proxyId) => {
    return await request('GET', `proxy/${proxyId}/`)
}

export const getArchiveProxies = async (params) => {
    return await request('GET', 'proxy/archive/', params)
}

export const deleteProxy = async (proxyId) => {
    return await request('DELETE', `proxy/${proxyId}/`)
}

export const updateProxy = async (proxyId, params) => {
    return await request('PUT', `proxy/${proxyId}/`, params)
}

export const addProxy = async (params) => {
    return await request('POST', 'proxy/', params)
}
