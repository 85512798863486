import { request } from '_api/base/behemoth/clientKokoc'

export const getDocumentFileById = async (fileId) => {
    return await request('GET', `files/${fileId}`)
}

export const uploadDocumentFile = async (data) => {
    return await request('POST', 'files/upload', data, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
