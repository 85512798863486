import API from '_api'

import NewsCategory from '_entities/news/NewsCategory'

const actions = {
    async fetchNewsCategories({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchNewsCategoriesRequest')

            try {
                const response = await API.services.news.getNewsCategories(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new NewsCategory(result))
                })

                commit('fetchNewsCategoriesSuccess', { items })
            } catch (e) {
                commit('fetchNewsCategoriesError', e)
            }
        }
    },

    async deleteNewsCategory({ commit }, params) {
        commit('deleteNewsCategoriesRequest')

        try {
            const newsCategoryId = params?.newsCategoryId || null
            if (newsCategoryId) {
                await API.services.news.deleteNewsCategory({ newsCategoryId })
            }

            commit('deleteNewsCategoriesSuccess', { newsCategoryId })
        } catch (e) {
            commit('deleteNewsCategoriesError', e)
        }
    },
}

export default actions
