import Vue from 'vue'

const mutations = {
    fetchTicketDepartmentsClear(state) {
        state.items = {}
    },

    fetchTicketDepartmentsRequest(state) {
        state.isFetching = true
    },

    fetchTicketDepartmentsData(state, { items }) {
        items.forEach((item) => {
            Vue.set(state.items, item.id, item)
        })
    },

    fetchTicketDepartmentsSuccess(state) {
        state.isFetching = false
        state.errors = []
    },

    fetchTicketDepartmentsError(state, errors) {
        state.isFetching = false
        state.errors = errors
    },

    addedTicketDepartment(state, item) {
        Vue.set(state.items, item.id, item)
    },
}

export default mutations
