<template>
    <v-list-item
        :to="route"
        :disabled="!route && !onClick"
        :exact="exact"
        :class="needToHighLight ? 'v-list-item--active' : ''"
        @click="onClick ? onClick() : null"
    >
        <v-list-item-avatar v-if="type === 'groupItem'">
            <template v-if="avatar">
                {{ avatar }}
            </template>
        </v-list-item-avatar>
        <v-list-item-icon v-else>
            <v-icon v-if="icon" v-text="`$${icon}`" size="24" />
        </v-list-item-icon>

        <v-list-item-content>
            <v-list-item-title>
                {{ title }}
            </v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
            <v-list-item-action-text>
                {{ actionText }}
            </v-list-item-action-text>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
    export default {
        name: 'CMenuItem',

        props: {
            actionText: { type: String },
            avatar: { type: String },
            type: { type: String },
            exact: { type: Boolean },
            icon: { type: String },
            route: { type: String },
            onClick: { type: Function },
            title: { type: String },
        },

        computed: {
            sameTicketStage() {
                const ticketStage = this.route?.query?.ticketsSection

                return ticketStage && ticketStage === this.$route?.query?.ticketsSection
            },

            needToHighLight() {
                return this.sameTicketStage
            },
        },
    }
</script>
