import { forEach as _forEach } from 'lodash'

import API from '_api'

import CallQueue from '_entities/call/CallQueue'

const actions = {
    async fetchQueues({ commit }) {
        try {
            const response = await API.services.queue.getCallQueues()

            if (response?.results?.length) {
                const queues = {}

                _forEach(response.results, (queue) => {
                    queues[queue.id] = new CallQueue(queue)
                })

                commit('setQueues', { queues })
            }
        } catch (e) {
            return Promise.reject(e)
        }
    },
}

export default actions
