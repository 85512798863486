import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementPushMessage extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'push_message', () => 'push_message'],
            text: ['text', undefined, (value) => value], // String
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        text: 'Текст',
    }

    toServer(data) {
        return {
            ...super.toServer(data),
            text: data?.text,
        }
    }
}

export default ServiceElementPushMessage
