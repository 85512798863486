import store from '@/store'

import { request } from '_api/base/behemoth/client'

import { getTicketDepartments } from '_api/services/ticketDepartment'
import { getTicketKinds } from '_api/services/ticketKind'

import TicketDepartmentLink from '_entities/ticket/TicketDepartmentLink'
import TicketKindLink from '_entities/ticket/TicketKindLink'

import { filter as _filter } from 'lodash'
//
// Service
// -----------------------------------------------------------------------------

export const getServices = async (params) => {
    return await request('GET', 'service/service/', params)
}

export const getServiceById = async (serviceId) => {
    // Подгрузка справочников типизации
    let _response
    const _params = {
        page: 1,
        page_size: Number.MAX_SAFE_INTEGER,
    }
    _response = await getTicketDepartments(_params)
    const ticketDepartments = _response?.results

    _response = await getTicketKinds(_params)
    const ticketKinds = _response?.results

    // Загрузка самого сервиса
    const response = await request('GET', `service/service/${serviceId}/`)

    let typeResponse
    if (response.elements) {
        for (let el = 0; el < response.elements.length; el++) {
            const element = response.elements[el]

            if (element.variants) {
                for (let vr = 0; vr < element.variants.length; vr++) {
                    const variant = element.variants[vr]

                    if (variant.ticket_department) {
                        typeResponse = _filter(ticketDepartments, (ticketDepartment) => ticketDepartment.id === variant.ticket_department)[0]
                        response.elements[el].variants[vr].ticket_department = new TicketDepartmentLink(typeResponse)
                    }
                    if (variant.ticket_kind) {
                        typeResponse = _filter(ticketKinds, (ticketKind) => ticketKind.id === variant.ticket_kind)[0]
                        response.elements[el].variants[vr].ticket_kind = new TicketKindLink(typeResponse)
                    }
                    if (variant.ticket_type) {
                        typeResponse = store.getters['ticketTypes/getTicketTypeById'](variant.ticket_type)
                        response.elements[el].variants[vr].ticket_type = typeResponse
                    }
                }
            }
        }
    }

    return response
}

export const createService = async ({ data }) => {
    return await request('POST', 'service/service/', data)
}

export const updateService = async ({ serviceId, data }) => {
    return await request('PUT', `service/service/${serviceId}/`, data)
}

export const deleteService = async (serviceId) => {
    return await request('DELETE', `service/service/${serviceId}/`)
}

//
// Section
// -----------------------------------------------------------------------------

export const getServiceSections = async (params) => {
    return await request('GET', 'service/section/', params)
}

export const getServiceSectionById = async (serviceSectionId) => {
    return await request('GET', `service/section/${serviceSectionId}/`)
}

export const createServiceSection = async ({ data }) => {
    return await request('POST', 'service/section/', data)
}

export const updateServiceSection = async ({ serviceSectionId, data }) => {
    return await request('PUT', `service/section/${serviceSectionId}/`, data)
}

export const deleteServiceSection = async (serviceSectionId) => {
    return await request('DELETE', `service/section/${serviceSectionId}/`)
}

//
// Subsection
// -----------------------------------------------------------------------------

export const getServiceSubsections = async (params) => {
    return await request('GET', 'service/subsection/', params)
}

export const getServiceSubsectionById = async (serviceSubsectionId) => {
    return await request('GET', `service/subsection/${serviceSubsectionId}/`)
}

export const createServiceSubsection = async ({ data }) => {
    return await request('POST', 'service/subsection/', data)
}

export const updateServiceSubsection = async ({ serviceSubsectionId, data }) => {
    return await request('PUT', `service/subsection/${serviceSubsectionId}/`, data)
}

export const deleteServiceSubsection = async (serviceSubsectionId) => {
    return await request('DELETE', `service/subsection/${serviceSubsectionId}/`)
}

//
// Element
// -----------------------------------------------------------------------------

export const getServiceElementCalendarDates = async (serviceElementId) => {
    return await request('GET', `service/element/calendar_date/${serviceElementId}/`)
}

export const getServiceElementCalendarTimes = async (serviceElementId, date) => {
    return await request('GET', `service/element/calendar_date/${serviceElementId}/${date}/`)
}

//
// Discount card
// -----------------------------------------------------------------------------

export const getServiceDiscountCards = async (params) => {
    return await request('GET', 'service/bonus/card/', params)
}

export const getServiceDiscountCardById = async (serviceDiscountCardId) => {
    return await request('GET', `service/bonus/card/${serviceDiscountCardId}/`)
}

export const createServiceDiscountCard = async ({ data }) => {
    return await request('POST', 'service/bonus/card/', data)
}

export const updateServiceDiscountCard = async ({ serviceDiscountCardId, data }) => {
    return await request('PUT', `service/bonus/card/${serviceDiscountCardId}/`, data)
}

//
// Discount card category
// -----------------------------------------------------------------------------

export const getServiceDiscountCardCategories = async (params) => {
    return await request('GET', 'service/bonus/category/', params)
}

export const getServiceDiscountCardCategoryById = async (serviceDiscountCardCategoryId) => {
    return await request('GET', `service/bonus/category/${serviceDiscountCardCategoryId}/`)
}

export const createServiceDiscountCardCategory = async ({ data }) => {
    return await request('POST', 'service/bonus/category/', data)
}

export const updateServiceDiscountCardCategory = async ({ serviceDiscountCardCategoryId, data }) => {
    return await request('PUT', `service/bonus/category/${serviceDiscountCardCategoryId}/`, data)
}

//
// Partner company
// -----------------------------------------------------------------------------

export const getServicePartnerCompanies = async (params) => {
    return await request('GET', 'service/bonus/company/', params)
}

export const getServicePartnerCompanyById = async (servicePartnerCompanyId) => {
    return await request('GET', `service/bonus/company/${servicePartnerCompanyId}/`)
}

export const createServicePartnerCompany = async ({ data }) => {
    return await request('POST', 'service/bonus/company/', data)
}

export const updateServicePartnerCompany = async ({ servicePartnerCompanyId, data }) => {
    return await request('PUT', `service/bonus/company/${servicePartnerCompanyId}/`, data)
}

//
// Promotion
// -----------------------------------------------------------------------------

export const getServicePromotions = async (params) => {
    return await request('GET', 'service/bonus/promotion/', params)
}

export const getServicePromotionById = async (servicePromotionId) => {
    return await request('GET', `service/bonus/promotion/${servicePromotionId}/`)
}

export const createServicePromotion = async ({ data }) => {
    return await request('POST', 'service/bonus/promotion/', data)
}

export const updateServicePromotion = async ({ servicePromotionId, data }) => {
    return await request('PUT', `service/bonus/promotion/${servicePromotionId}/`, data)
}
