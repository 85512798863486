import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementCheckbox extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'checkbox', () => 'checkbox'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementCheckbox
