import { difference as _difference, forEach as _forEach, keys as _keys, uniq as _uniq, values as _values } from 'lodash'
import moment from 'moment'

class Entity {
    /**
     * The schema of the model.
     *
     * @type {Object}
     */
    get schema() {
        throw new Error('Missing schema')
    }

    /**
     * Maps names used in sets and gets against unique names within the class,
     * allowing independence from database column names.
     *
     * Example:
     *  get dataMap() {
     *      api_name => 'className',
     *  };
     */
    get dataMap() {
        return {}
    }

    /**
     * Generate Model classes based on JSON Schema definition.
     * @class
     *
     * @param {Object} data The (optional) initial data to set.
     */
    constructor(data = {}) {
        this.init()
        this.fill(data)
    }

    init() {
        _forEach(this.schema?.properties, (property, key) => {
            this[key] = property.default
        })
    }

    /**
     * Takes an array of key/value pairs and sets them as
     * class properties, using any `setCamelCasedProperty()` methods
     * that may or may not exist.
     *
     * @param {Object} data
     */
    fill(data = {}) {
        for (const key in data) {
            const value = data[key]

            this.set(key, value)
        }
    }

    /**
     * General method that will return all public and protected
     * values of this entity as an array. All values are accessed
     * through the get() method.
     */
    toArray() {
        const result = {}

        let keys = _keys(this.schema?.properties)
        if (_keys(this.dataMap).length) {
            keys = _difference(keys, _values(this.dataMap))
            keys = _uniq([...keys, ..._keys(this.dataMap)])
        }

        _forEach(keys, (key) => {
            result[key] = this.get(key)
        })

        return result
    }

    get(key) {
        key = this.mapProperty(key)

        return this[key]
    }

    /**
     * Set a bunch of properties.
     *
     * @param key
     * @param value
     */
    set(key, value) {
        key = this.mapProperty(key)

        // Property
        const property = this.schema?.properties[key]

        if (!property) {
            return
        }

        // Cast
        value = this.castAs(value, property)

        if (this[key] !== value) {
            this[key] = value
        }
    }

    /**
     * Checks the dataMap to see if this column name is being mapped,
     * and returns the mapped name, if any, or the original name.
     *
     * @param key
     */
    mapProperty(key) {
        if (!_keys(this.dataMap).length) {
            return key
        }

        if (this.dataMap[key]) {
            return this.dataMap[key]
        }

        return key
    }

    /**
     * Provides the ability to cast an item as a specific data type.
     * Add ? at the beginning of $type  (i.e. ?string) to get NULL instead of casting $value if $value === null
     */
    castAs(value, property) {
        let type = property.type
        const entity = property.entity

        if (!type) {
            throw new Error('Missing type')
        }

        // Optional
        if (property.optional === true) {
            if (value === null) {
                return null
            }
        }

        // Value - array
        if (type.toLowerCase().indexOf('[]') !== -1) {
            type = type.substr(0, type.length - 2)

            const arrayValue = []
            _forEach(value, (_v, _k) => {
                arrayValue.push(this.handleCastAs(_v, type, entity))
            })

            return arrayValue
        }

        return this.handleCastAs(value, type, entity)
    }

    handleCastAs(value, type, Entity) {
        switch (type) {
            case 'string':
                if (value !== null && value !== undefined) {
                    value = `${value}`
                }
                break
            case 'datetimeString':
                if (value !== null && value !== undefined) {
                    const stillUtc = moment.utc(value).toDate()
                    const local = moment(stillUtc).local().format('YYYY-MM-DD HH:mm')
                    value = `${local}`
                }
                break
            case 'datetimeUnix':
                if (value !== null && value !== undefined) {
                    // В базе Unix в секундах. Нужны миллисекунды
                    value = value * 1000

                    const stillUtc = moment.utc(value).toDate()
                    const local = moment(stillUtc).local().unix() * 1000

                    value = local
                }
                break
            case 'int':
                if (value !== null && value !== undefined) {
                    value = parseInt(value, 10)
                }
                break
            case 'float':
                if (value !== null && value !== undefined) {
                    value = parseFloat(value)
                }
                break
            case 'bool':
                value = !!value
                break
            case 'self': // alias for same entity
                value = new this.constructor(value)
                break
            default:
                // For Entities
                if (typeof Entity === 'function' && value && typeof value === 'object') {
                    value = new Entity(value)
                }
                break
        }

        return value
    }
}

export default Entity
