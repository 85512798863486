import Entity from '_entities/base/EntityNew'

export const ProjectSchema = {
    name: 'Project',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class Project extends Entity {
    get schema() {
        return ProjectSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        order: 'Порядок',
    }
}

export default Project
