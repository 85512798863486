import moment from 'moment'

export const convertTime = (time, from = 'minutes', to = 'hours') => {
    if (from === 'days' && to === 'hours') {
        return time * 24
    }
    if (from === 'days' && to === 'minutes') {
        return time * 24 * 60
    }
    if (from === 'days' && to === 'seconds') {
        return time * 24 * 60 * 60
    }

    if (from === 'hours' && to === 'days') {
        return time / 24
    }
    if (from === 'hours' && to === 'minutes') {
        return time * 60
    }
    if (from === 'hours' && to === 'seconds') {
        return time * 60 * 60
    }

    if (from === 'minutes' && to === 'days') {
        return time / 60 / 24
    }
    if (from === 'minutes' && to === 'hours') {
        return time / 60
    }
    if (from === 'minutes' && to === 'seconds') {
        return time * 60
    }

    if (from === 'seconds' && to === 'days') {
        return time / 60 / 60 / 24
    }
    if (from === 'seconds' && to === 'hours') {
        return time / 60 / 60
    }
    if (from === 'seconds' && to === 'minutes') {
        return time / 60
    }

    return time
}

export const dateDifferenceLabel = (unix) => {
    const now = moment().format('DD')
    const date = moment.unix(unix).format('DD')
    const diff = now - date

    if (diff === 0) {
        return 'Сегодня'
    } else if (diff === 1) {
        return 'Вчера'
    }

    return moment.unix(unix).locale('ru').format('LL')
}

export const getStartOfDay = (unix) => {
    if (!unix) {
        unix = moment().unix()
    }

    return moment.unix(unix).startOf('day').unix()
}

export const getEndOfDay = (unix) => {
    if (!unix) {
        unix = moment().unix()
    }

    return moment.unix(unix).endOf('day').unix()
}

export const decodeFromSeconds = (seconds) => {
    const days = Math.round(seconds / (60 * 60 * 24))

    seconds = seconds % (60 * 60 * 24)
    const hours = Math.round(seconds / (60 * 60))

    seconds = seconds % (60 * 60)
    const minutes = Math.round(seconds / 60)

    return {
        days,
        hours,
        minutes,
        seconds: seconds % 60,
    }
}

export const encodeInSeconds = (items) => {
    if (!items || typeof items !== 'object') {
        return 0
    }

    let seconds = 0

    if (items?.days) {
        seconds += items.days * 60 * 60 * 24
    }

    if (items?.hours) {
        seconds += items.hours * 60 * 60
    }

    if (items?.minutes) {
        seconds += items.minutes * 60
    }

    if (items?.seconds) {
        seconds += items.seconds
    }

    return Math.round(seconds)
}
