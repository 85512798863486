import { request } from '_api/base/behemoth/client'

export const getNewsList = async (params) => {
    return await request('GET', 'news/', params)
}

export const getNewsById = async (newsId) => {
    return await request('GET', `news/${newsId}/`)
}

export const createNews = async ({ data }) => {
    return await request('POST', 'news/', data)
}

export const updateNews = async ({ newsId, data }) => {
    return await request('PUT', `news/${newsId}/`, data)
}

export const getNewsCategories = async (params) => {
    return await request('GET', 'news/category/', params)
}

export const getNewsCategoryById = async (newsCategoryId) => {
    return await request('GET', `news/category/${newsCategoryId}/`)
}

export const createNewsCategory = async ({ data }) => {
    return await request('POST', 'news/category/', data)
}

export const updateNewsCategory = async ({ newsCategoryId, data }) => {
    return await request('PUT', `news/category/${newsCategoryId}/`, data)
}

export const deleteNewsCategory = async ({ newsCategoryId }) => {
    return await request('DELETE', `news/category/${newsCategoryId}/`)
}

export const getServiceCompanyReportById = async (buildingId) => {
    return await request('GET', `news/service_company_report/${buildingId}/`)
}
