import {
    map as _map,
    filter as _filter,
} from 'lodash'
import { localeMoment } from '@/plugins/moment'
import moment from 'moment'

import Entity from '_entities/base/Entity'

class FilterField extends Entity {
    get schema() {
        return {
            code: ['code', undefined, (value) => value],
            codes: ['codes', undefined, (value) => value],
            default: ['default', undefined, (value) => value],
            entity: ['entity', undefined, (value) => value],
            hideDetails: ['hideDetails', false, (value) => value],
            items: ['items', [], (value) => value],
            method: ['method', undefined, (value) => value],
            item: ['item', undefined, (value) => value],
            itemSubtext: ['itemSubtext', undefined, (value) => value],
            itemText: ['itemText', undefined, (value) => value],
            itemValue: ['itemValue', undefined, (value) => value],
            label: ['label', undefined, (value) => value],
            multiple: ['multiple', undefined, (value) => !!value],
            primaryKey: ['primaryKey', undefined, (value) => value],
            props: ['props', undefined, (value) => value],
            type: ['type', undefined, (value) => value],
            value: ['value', undefined, (value) => value],
        }
    }

    static fieldLabels = {
        code: 'Название поля для запроса в API у фильтруемой сущности',
        default: 'Значение по умолчанию',
        entity: 'Сущность',
        items: 'Список значений',
        method: 'Метод API для получения данных',
        itemText: 'Ключ текстового значения элемента',
        itemValue: 'Ключ значения элемента',
        label: 'Заголовок поля',
        multiple: 'Множественный выбор',
        primaryKey: 'Ключ для получения данных в Autocomplete и Select',
        type: 'Тип поля | autocomplete | select | string | number | date',
        value: 'Выбранное значение',
    }

    get simplified() {
        if (this.value !== null && (!!this.value || !!this.value?.length || !!this.value?.date)) {
            return {
                code: this.code,
                value: this.value,
            }
        }
    }

    getValues() {
        let result = []
        let value = null

        if (this.type === 'select' || this.type === 'autocomplete') {
            value = this.asSelect(this)
        }

        // String
        if (this.type === 'string') {
            value = this.asString(this)
        }

        // Number
        if (this.type === 'number') {
            value = this.asNumber(this)
        }

        // DateTimeRange
        if (this.type === 'dateTimeRange') {
            const codeTime = this.codes.timeRange
            const codeDate = this.codes.date

            if (this.value.page === 0) {
                result = [...result, ...this.asTimeRange(codeTime, this.value.timeRange)]
                result = [...result, ...this.asDate(codeDate, this.value.date)]
            } else {
                const start = moment(this.value.timeRange.from).unix()
                const end = moment(this.value.timeRange.to).unix()

                const date = {
                    date: [start, end],
                    dateType: 'none',
                }

                result = [...result, ...this.asDate(codeDate, date)]
            }
        }

        // TimeRange
        if (this.type === 'timeRange') {
            result = [...result, ...this.asTimeRange(this.code, this.value)]
        }

        // Добавляем данные в массив только если есть значение (для даты отдельно)
        if (this.type === 'date') {
            result = [...result, ...this.asDate(this.code, this.value)]
        } else {
            if ((this.multiple && value?.length) || (!this.multiple && value)) {
                result.push({
                    code: this.code + (this.multiple ? '__in' : ''),
                    value: this.multiple ? _filter(value, (val) => val).join(',') : value,
                })
            }
        }

        return result
    }

    asSelect(item) {
        const itemValue = item.itemValue
        if (item.multiple) {
            return _map(item.value, (val) => {
                return val[itemValue]
            })
        } else if (item.value) {
            return item.value[itemValue]
        }
    }

    asString(item) {
        return item.value
    }

    asNumber(item) {
        return item.value
    }

    asTimeRange(code, value) {
        const result = []

        if (value) {
            result.push({
                code: code + '_start',
                value: value.from,
            })
            result.push({
                code: code + '_end',
                value: value.to,
            })
        }

        return result
    }

    asDate(code, value) {
        const result = []

        if (value?.date?.length) {
            let startDate
            let endDate

            switch (value.dateType) {
                case 'yesterday' :
                    startDate = localeMoment().subtract(1, 'day').startOf('day').unix()
                    endDate = localeMoment().subtract(1, 'day').endOf('day').unix()
                    break
                case 'currentDay' :
                    startDate = localeMoment().startOf('day').unix()
                    endDate = localeMoment().endOf('day').unix()
                    break
                case 'tomorrow' :
                    startDate = localeMoment().add(1, 'day').startOf('day').unix()
                    endDate = localeMoment().add(1, 'day').endOf('day').unix()
                    break
                case 'currentWeek' :
                    startDate = localeMoment().startOf('week').unix()
                    endDate = localeMoment().endOf('week').unix()
                    break
                case 'currentMonth' :
                    startDate = localeMoment().startOf('month').unix()
                    endDate = localeMoment().endOf('month').unix()
                    break
                case 'currentQuarter' :
                    startDate = localeMoment().startOf('quarter').unix()
                    endDate = localeMoment().endOf('quarter').unix()
                    break
                case 'lastDays7' :
                    startDate = localeMoment().subtract(7, 'day').startOf('day').unix()
                    endDate = localeMoment().endOf('day').unix()
                    break
                case 'lastDays30' :
                    startDate = localeMoment().subtract(30, 'day').startOf('day').unix()
                    endDate = localeMoment().endOf('day').unix()
                    break
                case 'lastDays60' :
                    startDate = localeMoment().subtract(60, 'day').startOf('day').unix()
                    endDate = localeMoment().endOf('day').unix()
                    break
                case 'lastDays90' :
                    startDate = localeMoment().subtract(90, 'day').startOf('day').unix()
                    endDate = localeMoment().endOf('day').unix()
                    break
                case 'lastWeek' :
                    startDate = localeMoment().subtract(1, 'week').startOf('week').unix()
                    endDate = localeMoment().subtract(1, 'week').endOf('week').unix()
                    break
                case 'lastMonth' :
                    startDate = localeMoment().subtract(1, 'month').startOf('month').unix()
                    endDate = localeMoment().subtract(1, 'month').endOf('month').unix()
                    break
                case 'nextWeek' :
                    startDate = localeMoment().add(1, 'week').startOf('week').unix()
                    endDate = localeMoment().add(1, 'week').endOf('week').unix()
                    break
                case 'nextMonth' :
                    startDate = localeMoment().add(1, 'month').startOf('month').unix()
                    endDate = localeMoment().add(1, 'month').endOf('month').unix()
                    break
                default:
                    startDate = value.date[0]
                    endDate = value.date[1] || null
                    break
            }

            result.push({
                code: code + '_start',
                value: startDate,
            })
            result.push({
                code: code + '_end',
                value: endDate,
            })
        }

        return result
    }
}

export default FilterField
