import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import API from '_api'
import { request } from '_api/base/behemoth/clientKokoc'

export const getPremiseDocuments = async (params) => {
    const response = await request('GET', 'documents/PremiseDocuments', params)
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const primeseIds = _map(responseChunk, ({ premise_id }) => premise_id)
        const userIds = _map(responseChunk, ({ created_by }) => created_by?.user_id)

        const primeses = await API.services.premise.getPremises({
            id__in: _compact(primeseIds).join(','),
        })

        const users = await API.services.user.getUsers({
            id__in: _compact(userIds).join(','),
        })

        for (const response of responseChunk) {
            response.premise = primeses.results.find((primese) => primese.id === response.premise_id)
            response.user = users.results.find((user) => user.id === response.created_by?.user_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getPremiseDocumentById = async (premiseDocumentId) => {
    const response = await request('GET', `documents/PremiseDocument/${premiseDocumentId}`)
    const errors = []

    if (response.account_id) {
        response.account = await API.services.personalAccount.getPersonalAccountById(response.account_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.premise_id) {
        response.premise = await API.services.premise.getPremiseById(response.premise_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.created_by && response.created_by.user_id) {
        response.user = await API.services.user.getUserById(response.created_by.user_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updatePremiseDocument = async ({ premiseDocumentId, data }) => {
    return await request('PATCH', `documents/PremiseDocument/${premiseDocumentId}`, data)
}

export const createPremiseDocument = async (data) => {
    return await request('POST', 'documents/createPremiseDocument', data)
}

export const deletePremiseDocument = async (premiseDocumentId) => {
    return await request('DELETE', `documents/PremiseDocument/${premiseDocumentId}`)
}

export const getDocumentPremiseTypes = async (params) => {
    return await request('GET', 'documents/DocumentPremiseTypes', params)
}
