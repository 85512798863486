import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementUploadImage extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'upload_images', () => 'upload_images'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementUploadImage
