<template>
    <v-app-bar app elevate-on-scroll>
        <v-btn icon @click="toggleNavigationDrawer">
            <v-icon v-text="'$bars'" />
        </v-btn>

        <v-toolbar-title>{{ title }}</v-toolbar-title>

        <v-spacer />

        <v-btn
            v-if="isAvailableSoftwareUpdate"
            color="primary"
            @click="updateSoftware"
        >
            Обновить ПО
        </v-btn>

        <FIncomingCallButton
            v-if="callerPhone"
            class="mr-3"
        />

        <UIWorkStatus class="mr-3" />
        <UIIncomeCalls v-if="timeEntry" ref="incomeCalls" class="mr-3" />

        <v-btn icon>
            <v-badge
                v-if="hasNewMessages"
                color="red"
                offset-x="5"
                offset-y="5"
                dot
            >
                <v-icon @click="showNotificationCenter" v-text="'$bell'" />
            </v-badge>
            <v-icon v-else @click="showNotificationCenter" v-text="'$bell'" />
        </v-btn>

        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon v-text="'$question'" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item :href="changeLogUrl" target="_blank">
                    <v-list-item-title>
                        История изменений
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>

        <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    icon
                    v-bind="attrs"
                    v-on="on"
                >
                    <v-icon v-text="'$userCircle'" />
                </v-btn>
            </template>
            <v-list>
                <v-list-item @click="signOut()">
                    <v-list-item-title>
                        Выйти
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>
    import { mapState } from 'vuex'

    import FIncomingCallButton from '_features/incomingCall/components/FIncomingCallButton'

    import UIIncomeCalls from '_ui/UIIncomeCalls'
    import UIWorkStatus from '_ui/UIWorkStatus'

    export default {
        name: 'CAppBar',

        components: {
            FIncomingCallButton,
            UIIncomeCalls,
            UIWorkStatus,
        },

        data: () => ({
            changeLogUrl: 'https://docs.google.com/document/d/1Hp_EszXzkw5YeSLUSi1dP4r63JOUrhLOdP5GsoRAQnU',
        }),

        computed: {
            ...mapState('global', {
                navigationDrawer: (state) => state.navigationDrawer,
            }),

            ...mapState('incomingCall', {
                callerPhone: (state) => state.callerPhone,
            }),

            hasNewMessages() {
                return this.$store.state.notificationCenter.hasNew
            },

            timeEntry() {
                return this.$store.state.timeManagement.timeEntry
            },

            title() {
                let title
                this.$route.matched.forEach((route) => {
                    const screenOptions = route.meta?.screenOptions

                    if (screenOptions?.title) {
                        title = screenOptions.title
                    }
                })

                return title
            },

            isAvailableSoftwareUpdate() {
                return this.$store.state.softwareUpdate.isAvailable
            },
        },

        watch: {
            timeEntry: {
                handler() {
                    if (!this.timeEntry) {
                        this.$refs?.incomeCalls?.changeCallState('finish')
                    }
                },
            },
        },

        methods: {
            signOut() {
                this.$store.dispatch('auth/signOut')
            },

            showNotificationCenter() {
                this.$store.commit('notificationCenter/show')
            },

            toggleNavigationDrawer() {
                const navigationDrawer = this.navigationDrawer

                this.$store.commit('global/setNavigationDrawer', {
                    ...navigationDrawer,
                    isShown: !this.navigationDrawer.isShown,
                })

                this.$store.commit('global/setShownNavigationDrawer', {
                    isShown: this.navigationDrawer.isShown,
                })
            },

            updateSoftware() {
                const serviceWorkerRegistration = this.$store.state.softwareUpdate.serviceWorkerRegistration

                this.$store.dispatch('softwareUpdate/update', {
                    serviceWorkerRegistration,
                })
            },
        },
    }
</script>
