import { map as _map } from 'lodash'
import Entity from '_entities/base/Entity'

import CompanyLink from '_entities/company/CompanyLink'
import BuildingLink from '_entities/building/BuildingLink'
import Photo from '_entities/media/Photo'
import NewsCategoryLink from '_entities/news/NewsCategoryLink'

class Quiz extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            isActive: ['is_active', undefined, (value) => !!value], // Boolean
            startDate: ['start_date', undefined, (value) => this.getIntegerValue(value)],
            endDate: ['end_date', undefined, (value) => this.getIntegerValue(value)],
            status: ['status', undefined, (value) => value], // String
            title: ['title', undefined, (value) => value], // String
            description: ['description', undefined, (value) => value], // String
            category: ['category', undefined, (value) => this.getEntityValue(NewsCategoryLink, value)],
            image: ['image', undefined, (value) => this.getEntityValue(Photo, value)],
            buildings: ['buildings', [], (value) => value.map((item) => this.getEntityValue(BuildingLink, item))],
            company: ['company', undefined, (value) => this.getEntityValue(CompanyLink, value)],
            gallery: ['gallery', [], (value) => value.map((item) => this.getEntityValue(Photo, item))],
            finishProgress: ['finish_progress', undefined, (value) => this.getIntegerValue(value)],
            isSendToEmail: ['is_send_to_email', undefined, (value) => value],
        }
    }

    static fieldLabels = {
        id: 'ID',
        isActive: 'Статус активности',
        startDate: 'Дата публикации',
        endDate: 'Дата окончания',
        status: 'Статус',
        title: 'Заголовок',
        description: 'Описание',
        category: 'Категория',
        image: 'Изображение',
        buildings: 'Дома',
        company: 'Организация',
        gallery: 'Фотогалерея',
        finishProgress: 'Автозавершение',
        isSendToEmail: 'Отправка ответа на email',
    }

    toServer() {
        return {
            is_active: this.isActive,
            start_date: this.startDate,
            end_date: this.endDate,
            status: this.status,
            title: this.title,
            description: this.description,
            category: this.category?.id,
            image: this.image?.id,
            buildings: _map(this.buildings, (item) => item.id),
            company: this.company?.id,
            gallery: _map(this.gallery, (item) => item.id),
            finish_progress: this.finishProgress,
            is_send_to_email: this.isSendToEmail,
        }
    }
}

export default Quiz
