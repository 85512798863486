import Entity from '_entities/base/Entity'

import Doc from '_entities/media/Doc'
import Photo from '_entities/media/Photo'
import UserLink from '_entities/user/UserLink'

class ChatMessage extends Entity {
    get schema() {
        return {
            answerTime: ['answer_time', undefined, (value) => this.getIntegerValue(value)],
            answeredBy: ['answered_by', undefined, (value) => this.getEntityValue(UserLink, value)],
            id: ['id', undefined, (value) => value], // UUID
            chatId: ['chat_id', undefined, (value) => value], // UUID
            text: ['text', undefined, (value) => value], // String
            date: ['date', undefined, (value) => this.getIntegerValue(value)],
            files: [
                'files', [], (value) => {
                    if (value) {
                        return value.map((item) => this.getEntityValue(Doc, item))
                    }
                },
            ],
            images: [
                'images', [], (value) => {
                    if (value) {
                        return value.map((item) => this.getEntityValue(Photo, item))
                    }
                },
            ],
            isUnread: ['is_unread', undefined, (value) => !!value], // Boolean
            author: ['author', undefined, (value) => this.getEntityValue(UserLink, value)],
        }
    }

    get isUnanswered() {
        return !this.answerTime
    }

    static fieldLabels = {
        id: 'ID',
    }

    toServer() {
        return {
            // id: data?.id,
        }
    }
}

export default ChatMessage
