import API from '_api'

import TicketStage from '_entities/ticket/TicketStage'

const actions = {
    async fetchTicketStages({ commit, state }) {
        if (!state.isFetching) {
            commit('fetchTicketStagesRequest')

            try {
                const response = await API.services.ticketStage.getTicketStages()

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new TicketStage(result))
                })

                commit('fetchTicketStagesSuccess', { items })
            } catch (e) {
                commit('fetchTicketStagesError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
