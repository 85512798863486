import { chunk as _chunk, compact as _compact, map as _map } from 'lodash'

import API from '_api'
import { request } from '_api/base/behemoth/clientKokoc'

export const getIncomingDocuments = async (params) => {
    const response = await request('GET', 'documents/IncomingDocument', params)
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companyIds = _map(responseChunk, ({ company_id }) => company_id)
        const ticketIds = _map(responseChunk, ({ ticket_id }) => ticket_id)

        const companies = await API.services.company.getCompanies({
            id__in: _compact(companyIds).join(','),
        })

        const tickets = await API.services.ticket.getTickets({
            id__in: _compact(ticketIds).join(','),
        })

        for (const response of responseChunk) {
            response.company = companies.results.find((company) => company.id === response.company_id)
            response.ticket = tickets.results.find((ticket) => ticket.id === response.ticket_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getIncomingDocumentById = async (incomingDocumentId) => {
    const response = await request('GET', `documents/IncomingDocument/${incomingDocumentId}`)
    const errors = []

    if (response.company_id) {
        response.company = await API.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.ticket_id) {
        response.ticket = await API.services.ticket.getTicketById(response.ticket_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updateIncomingDocument = async (incomingDocumentId, data) => {
    return await request('PATCH', `documents/IncomingDocument/${incomingDocumentId}`, data)
}

export const createIncomingDocument = async (data) => {
    return await request('POST', 'documents/createIncomingDocument', data)
}

export const deleteIncomingDocument = async (incomingDocumentId) => {
    return await request('DELETE', `documents/IncomingDocument/${incomingDocumentId}`)
}

export const getDocumentIncomingTypes = async (params) => {
    return await request('GET', 'documents/DocumentIncomingTypes', params)
}

export const getIncomingDocumentBlank = async (companyId) => {
    const errors = []
    const response = await request(
        'POST',
        'documents/IncomingDocument/getBlank',
        {
            company_id: companyId,
        },
    )

    if (response.company_id) {
        response.company = await API.services.company.getCompanyById(response.company_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}
