import API from '_api'

import ServicePartnerCompany from '_entities/service/ServicePartnerCompany'

const actions = {
    async fetchServicePartnerCompany({ commit, state }, { servicePartnerCompanyId }) {
        if (!state.isFetching) {
            commit('fetchServicePartnerCompanyRequest')

            try {
                const response = await API.services.service.getServicePartnerCompanyById(servicePartnerCompanyId)

                const item = response ? new ServicePartnerCompany(response) : null

                commit('fetchServicePartnerCompanySuccess', { item })
            } catch (e) {
                commit('fetchServicePartnerCompanyError', e)

                return Promise.reject(e)
            }
        }
    },

    async saveServicePartnerCompany({ commit, state }, { servicePartnerCompany }) {
        if (!state.isSaving) {
            commit('saveServicePartnerCompanyRequest')

            try {
                const servicePartnerCompanyId = servicePartnerCompany.id
                const data = servicePartnerCompany.toServer()

                if (servicePartnerCompanyId) {
                    await API.services.service.updateServicePartnerCompany({ servicePartnerCompanyId, data })
                } else {
                    await API.services.service.createServicePartnerCompany({ data })
                }

                commit('saveServicePartnerCompanySuccess')
            } catch (e) {
                commit('saveServicePartnerCompanyError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
