import { forEach as _forEach } from 'lodash'

import ServiceElement from '_entities/service/ServiceElement'
import ServiceElementCalendarEventItem from '_entities/service/ServiceElementCalendarEventItem'

class ServiceElementCalendarEvent extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'calendar_event', () => 'calendar_event'],
            events: ['events', [], (value) => value.map((item) => new ServiceElementCalendarEventItem(item))],
            timezone: ['timezone', 'Asia/Yekaterinburg', (value) => value],
            accountStatus: ['account_status', undefined, (value) => value],
            sendText: ['send_text', undefined, (value) => value], // string
            isSend: ['is_send', false, (value) => !!value], // boolean
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        sendText: 'Текст сообщения',
        isSend: 'Отправлять сообщение по СМС',
    }

    toServer(data) {
        const events = []

        _forEach(data?.events, (event) => {
            events.push(event.toServer(event))
        })

        return {
            ...super.toServer(data),
            events: events,
            timezone: this.timezone,
            account_status: this.accountStatus,
            send_text: this.sendText,
            is_send: this.isSend,
        }
    }
}

export default ServiceElementCalendarEvent
