import API from '_api'

import ChatMessage from '_entities/chat/ChatMessage'

const actions = {
    async fetchMessages({ commit, state }, chatId) {
        if (!state.isFetching) {
            commit('fetchMessagesRequest')

            try {
                const response = await API.services.chat.getMessages(chatId)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new ChatMessage({
                        ...result,
                        chat_id: chatId,
                    }))
                })

                commit('fetchMessagesSuccess', { items })
            } catch (e) {
                commit('fetchMessagesError', e)
            }
        }
    },

    async sendMessage({ commit, state }, { chatId, params }) {
        if (!state.isSending) {
            commit('sendMessageRequest')

            try {
                const response = await API.services.chat.sendMessage(chatId, params)

                commit('sendMessageSuccess', new ChatMessage(response))
            } catch (e) {
                commit('sendMessageError', e)
            }
        }
    },

    async editMessage({ commit, state }, { message, params }) {
        if (!state.isEditing) {
            const chatId = message.chatId

            commit('editMessageRequest')

            try {
                const response = await API.services.chat.editMessage(chatId, message.id, params)

                commit('editMessageSuccess', new ChatMessage(response))
            } catch (e) {
                commit('editMessageError', e)
            }
        }
    },

    async deleteMessage({ commit, state }, { chatId, messageId }) {
        if (!state.isDeleting) {
            commit('deleteMessageRequest')

            try {
                await API.services.chat.deleteMessage(chatId, messageId)

                commit('deleteMessageSuccess', messageId)
            } catch (e) {
                commit('deleteMessageError', e)
            }
        }
    },
}

export default actions
