import Entity from '_entities/base/Entity'

class ServiceDiscountCardCategory extends Entity {
    get schema() {
        return {
            code: ['code', undefined, (value) => value],
            id: ['id', undefined, (value) => value], // UUID
            name: ['name', undefined, (value) => value],
        }
    }

    static fieldLabels = {
        code: 'Символьный код',
        id: 'ID',
        name: 'Название',
    }

    toServer() {
        return {
            code: this.code,
            name: this.name,
        }
    }
}

export default ServiceDiscountCardCategory
