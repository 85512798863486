import {
    compact as _compact,
    intersection as _intersection,
    map as _map,
    orderBy as _orderBy,
    uniq as _uniq,
} from 'lodash'

const getters = {
    getPermissions: (state) => {
        return _orderBy(state.items)
    },

    getPermissionModules: (state, getters) => {
        const modules = _map(getters.getPermissions, (permission) => {
            const permissionParts = permission.split('.')

            return permissionParts[0]
        })

        return _compact(_uniq(modules))
    },

    hasPermission: (state, getters) => (checkPermissions) => {
        if (!Array.isArray(checkPermissions) || !checkPermissions?.length) {
            return false
        }

        if (!getters.getPermissions?.length) {
            return true
        }

        const intersection = _intersection(getters.getPermissions, checkPermissions)

        return Boolean(intersection?.length)
    },

    hasModulePermission: (state, getters) => (checkPermissionModules) => {
        if (!Array.isArray(checkPermissionModules) || !checkPermissionModules?.length) {
            return false
        }

        if (!getters.getPermissionModules?.length) {
            return true
        }

        const intersection = _intersection(getters.getPermissionModules, checkPermissionModules)

        return Boolean(intersection?.length)
    },
}

export default getters
