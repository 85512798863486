import { map as _map, orderBy as _orderBy } from 'lodash'

import { request } from '_api/base/behemoth/client'

export const getCalls = async (params) => {
    const response = await request('GET', 'call/', params)

    let { results } = response

    if (results) {
        results = _map(results, (call) => {
            call.comments = _orderBy(call.comments, ['date_time'], ['desc'])
            return call
        })
    }

    return response
}

export const removeFromRecallSection = async (phone) => {
    return await request('GET', `call/remove_from_recall/${phone}/`)
}

export const getCallRecord = async (callId) => {
    return await request('GETFILE', `call/${callId}/record/`)
}

export const addCallComment = async (callId, params) => {
    return await request('POST', `call/${callId}/comment/`, params)
}

export const getCallsReport = async (params) => {
    return await request('GETFILE', 'call/report/', params)
}

export const getUnrecallCalls = async (params) => {
    return await request('GET', 'call/unrecall/', params)
}
