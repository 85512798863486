<template>
    <v-navigation-drawer
        v-model="drawerModel"
        app
        color="secondary"
        :expand-on-hover="drawerMiniVariant"
        :mini-variant.sync="drawerMiniVariant"
        :temporary="!isDesktop"
        :width="330"
        @mouseenter.native="onEnterMouseOnDrawer"
        @mouseleave.native="onLeaveMouseOnDrawer"
    >
        <v-list nav dark>
            <v-list-item :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'">
                <v-list-item-avatar>
                    SCW
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>
                        Service Company Web
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav dark>
            <v-list-item
                :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'"
                link
                :to="{ name: 'Profile', params: { userId } }"
                two-line
            >
                <v-list-item-avatar>
                    <CAvatar :value="currentWorkerPhoto || null" size="36" />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ currentWorker ? currentWorker.user.fullName : '-' }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        {{ currentWorker ? currentWorker.position : '-' }}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider />

        <v-list nav dark>
            <template v-for="item in menu">
                <template v-if="item.children && item.children.length">
                    <v-list-group :key="item.code" :value="item.isOpen" color="white">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-badge
                                    color="red"
                                    :content="item.count > 99 ? '99+' : item.count"
                                    offset-x="12"
                                    offset-y="12"
                                    :value="item.count ? item.count : 0"
                                >
                                    <v-icon
                                        v-if="item.icon"
                                        v-text="`$${item.icon}`"
                                        size="24"
                                    />
                                </v-badge>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>
                                    <router-link v-if="item.route" :to="item.route" class="text-decoration-none white--text">
                                        {{ item.name }}
                                    </router-link>
                                    <span v-else> {{ item.name }}</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <CMenuItem
                            v-for="child in item.children"
                            :key="child.code"
                            :actionText="child.count"
                            :avatar="drawerMiniVariant && isLeftMouseOnDrawer && getAvatar(child)"
                            :class="drawerMiniVariant && isLeftMouseOnDrawer && 'px-0'"
                            :exact="$route.name === 'Tickets'"
                            :icon="child.icon"
                            :route="child.route"
                            :onClick="child.onClick"
                            :title="child.name"
                            type="groupItem"
                        />
                    </v-list-group>
                </template>
                <template v-else>
                    <CMenuItem
                        :key="item.code"
                        :actionText="item.count"
                        :icon="item.icon"
                        :route="item.route"
                        :title="item.name"
                    />
                </template>
            </template>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    // MD and Down - Hide
    // LG and Up - Show

    import API from '_api'
    import {
        cloneDeep as _cloneDeep,
        compact as _compact,
        filter as _filter,
        forEach as _forEach,
        map as _map,
    } from 'lodash'
    import { mapState, mapGetters } from 'vuex'

    import { host } from '_api/base/behemoth/config'

    import { PERMISSION_MODULES, PERMISSIONS } from '_features/permissions/constants'

    import CMenuItem from '_common/components/NavigationDrawer/CMenuItem'
    import CAvatar from '_common/components/CAvatar'

    export default {
        name: 'CNavigationDrawer',

        components: {
            CMenuItem,
            CAvatar,
        },

        data: () => ({
            approvalsCount: 0,
            auditorTicketsCount: 0,
            isEnteredMouseOnDrawer: false,
            isLeftMouseOnDrawer: true,
            assignedTicketsCount: 0,
            assignedTicketsUnreadCount: 0,
            workTicketsCount: 0,
            newTicketsOverdueCount: 0,
            workTicketsUnreadCount: 0,
            onAdditionalCheckTicketsCount: 0,
            onAdditionalCheckTicketsUnreadCount: 0,
            onCheckTicketsCount: 0,
            onCheckTicketsUnreadCount: 0,
            newTicketsCount: 0,
            ticketsNeedExecutor: 0,
            ticketsNeedExecutorUnread: 0,
            newTicketsUnreadCount: 0,
            //
            notificationIntervalId: null,
        }),

        computed: {
            ...mapState('global', {
                navigationDrawer: (state) => state.navigationDrawer,
            }),

            ...mapState('auth', {
                userId: (state) => state.userId,
                currentWorker: (state) => state.currentWorker,
            }),

            ...mapGetters({
                isShownNavigationDrawer: 'global/getShownNavigationDrawer',
            }),

            isDesktop() {
                return this.$vuetify.breakpoint.lgAndUp
            },

            drawerModel: {
                get() {
                    if (this.isDesktop) {
                        return true
                    } else {
                        return this.navigationDrawer?.isShown
                    }
                },
                set(value) {
                    this.toggleNavigationDrawer(value)
                },
            },

            drawerMiniVariant() {
                if (this.isDesktop) {
                    return !this.navigationDrawer?.isShown
                } else {
                    return false
                }
            },

            openedTicketsCount() {
                return this.assignedTicketsCount + this.workTicketsCount + this.onCheckTicketsCount + this.newTicketsCount
            },

            ticketsFilter() {
                return this.$store.getters['filter/getFilterByCode']('tickets')
            },

            ticketsFilterPresets() {
                const items = []

                const filter = _cloneDeep(this.ticketsFilter)

                _forEach(filter?.presets, (preset) => {
                    let count
                    let name = preset.name

                    switch (preset.code) {
                        case 'assigned':
                            count = this.assignedTicketsUnreadCount
                            name += ` (${this.assignedTicketsCount})`
                            break

                        case 'work':
                            count = this.workTicketsUnreadCount
                            name += ` (${this.workTicketsCount})`
                            break

                        case 'new':
                            count = this.newTicketsUnreadCount
                            name += ` (${this.newTicketsCount})`
                            break
                    }

                    items.push({
                        code: preset.code,
                        count,
                        isAllowed: this.$hasPermission([
                            PERMISSIONS.CAN_VIEW_TICKET,
                        ]),
                        name,
                        onClick: () => {
                            this.$store.dispatch('filter/applyFilterPreset', { filter, filterPreset: preset })
                            this.$router.push({ name: 'Tickets' })
                        },
                    })
                })

                return items
            },

            menu() {
                const menu = []

                // Dashboard --------------------------------------------------------

                menu.push({
                    code: 'dashboard',
                    icon: 'newspaper',
                    isAllowed: true,
                    name: 'Рабочий стол',
                    route: {
                        name: 'Dashboard',
                    },
                })

                // Tickets -----------------------------------------------------

                menu.push({
                    code: 'tickets',
                    count: this.assignedTicketsUnreadCount + this.newTicketsUnreadCount,
                    icon: 'clipboardList',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.TICKET,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'TicketsNavigator'),
                    name: `Заявки (${this.newTicketsCount + this.ticketsNeedExecutor + this.workTicketsCount})`,
                    children: [
                        {
                            code: 'ticketsAll',
                            isAllowed: true,
                            name: 'Все',
                            onClick: () => {
                                const filter = _cloneDeep(this.ticketsFilter)

                                this.$store.dispatch('filter/resetFilter', { filter })

                                this.$router.push({ name: 'Tickets' })
                            },
                        },
                        {
                            code: 'overdue',
                            isAllowed: true,
                            name: `Просроченные (${this.newTicketsOverdueCount})`,
                            route: {
                                name: 'TicketOverdue',
                            },
                        },
                        {
                            code: 'onNew',
                            // TODO: Скрыто до момента, пока не будет вычисляться корректно
                            // count: this.newTicketsUnreadCount,
                            isAllowed: true,
                            name: `Новые (${this.newTicketsCount})`,
                            route: {
                                name: 'TicketOnNew',
                            },
                        },
                        {
                            code: 'createdByMe',
                            isAllowed: true,
                            name: 'Я заявитель',
                            route: {
                                name: 'TicketCreatedByMe',
                            },
                        },
                        {
                            code: 'need-executor',
                            count: this.ticketsNeedExecutorUnread,
                            isAllowed: true,
                            name: `Назначить (${this.ticketsNeedExecutor})`,
                            route: {
                                name: 'TicketOnNeedExecutor',
                            },
                        },
                        {
                            code: 'onInWork',
                            count: this.workTicketsUnreadCount,
                            isAllowed: true,
                            name: `Мои в работе (${this.workTicketsCount})`,
                            route: {
                                name: 'TicketOnInWork',
                            },
                        },
                        ...this.ticketsFilterPresets,
                        {
                            code: 'auditor',
                            isAllowed: true,
                            name: `Наблюдаю  (${this.auditorTicketsCount})`,
                            route: {
                                name: 'TicketAuditor',
                            },
                        },
                        {
                            code: 'onCheck',
                            count: this.onCheckTicketsUnreadCount + this.onAdditionalCheckTicketsUnreadCount,
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET_ON_CHECK,
                            ]),
                            name: `На проверке (${this.onCheckTicketsCount}/${this.onAdditionalCheckTicketsCount})`,
                            route: {
                                name: 'TicketOnCheck',
                            },
                        },
                        {
                            code: 'approval',
                            count: this.approvalsCount,
                            isAllowed: true,
                            name: 'Согласование',
                            route: {
                                name: 'TicketApproval',
                            },
                        },
                        {
                            code: 'ticketsNotifications',
                            isAllowed: false,
                            name: 'Уведомления',
                        },
                    ],
                })

                // Tickets setup -------------------------------------------------

                menu.push({
                    code: 'tickets_setup',
                    icon: 'cog',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_TICKET_SETTINGS,
                    ]),
                    name: 'Настройки заявки',
                    children: [
                        {
                            code: 'ticketsRouter',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_ROUTER_TICKET_ROUTE,
                            ]),
                            name: 'Маршрутизатор',
                            route: {
                                name: 'TicketRoutes',
                            },
                        },
                        {
                            code: 'typing',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET_DEPARTMENT,
                                PERMISSIONS.CAN_VIEW_TICKET_KIND,
                                PERMISSIONS.CAN_VIEW_TICKET_TYPE,
                            ]),
                            name: 'Типизация',
                            route: {
                                name: 'TicketTyping',
                            },
                        },
                        {
                            code: 'additionalCheck',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_TICKET,
                            ]),
                            name: 'Установка проверки',
                            route: {
                                name: 'TicketAdditionalCheck',
                            },
                        },
                        {
                            code: 'chatTimeLimits',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_CHAT_TIME_SETTING,
                            ]),
                            name: 'Установка сроков ответа',
                            route: {
                                name: 'ChatTimeLimits',
                            },
                        },
                    ],
                })

                // Надо ответить ----------------------------------------------------

                menu.push({
                    code: 'chats',
                    icon: 'comments',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_RESPONSIBLE_UNANSWERED_CHATS,
                        PERMISSIONS.CAN_VIEW_UNANSWERED_CHATS,
                    ]),
                    name: 'Надо ответить',
                    route: {
                        name: 'Chats',
                    },
                })

                // Residential fund --------------------------------------------

                menu.push({
                    code: 'residential_fund',
                    icon: 'houseUser',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.LOCATION,
                    ]),
                    name: 'Жилой фонд',
                    children: [
                        // {
                        //     code: 'contacts',
                        //     name: 'Контакты',
                        // },
                        {
                            code: 'personalAccounts',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_PERSONAL_ACCOUNT,
                            ]),
                            name: 'Лицевые счета',
                            route: {
                                name: 'PersonalAccounts',
                            },
                        },
                        // {
                        //     code: 'acts',
                        //     name: 'Акты',
                        // },
                        // {
                        //     code: 'cars',
                        //     name: 'Автомобили',
                        // },
                    ],
                })

                // Knowledge base ----------------------------------------------

                menu.push({
                    code: 'knowledgeBase',
                    icon: 'book',
                    isAllowed: true,
                    name: 'База знаний',
                    route: {
                        name: 'KnowledgeBase',
                    },
                })

                // Indications ----------------------------------------------

                menu.push({
                    code: 'indications',
                    icon: 'plug',
                    isAllowed: true,
                    name: 'Передача показаний',
                    children: [
                        {
                            code: 'MyCountryCompany',
                            isAllowed: true,
                            name: 'УК МОЯ СТРАНА',
                            route: {
                                name: 'MyCountryCompany',
                            },
                        },
                        {
                            code: 'HorizonCompany',
                            isAllowed: true,
                            name: 'УК ГОРИЗОНТ',
                            route: {
                                name: 'HorizonCompany',
                            },
                        },
                    ],
                })

                // Inventory ---------------------------------------------------

                // menu.push({
                //     code: 'inventory',
                //     name: 'ТМЦ',
                //     icon: 'boxesAlt',
                //     children: [
                //         {
                //             code: 'contacts',
                //             name: 'Склад',
                //         },
                //         {
                //             code: 'receivingInventory',
                //             name: 'Получение ТМЦ',
                //         },
                //         {
                //             code: 'purchaseInventory',
                //             name: 'Закупка ТМЦ',
                //         },
                //         {
                //             code: 'purchaseServices',
                //             name: 'Закупка услуг',
                //         },
                //     ],
                // })

                // Company -----------------------------------------------------

                menu.push({
                    code: 'company',
                    icon: 'building',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.DEPARTMENT,
                    ]),
                    name: 'Компания',
                    children: [
                        {
                            code: 'departments',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_DEPARTMENT,
                            ]),
                            name: 'Отделы',
                            route: {
                                name: 'Departments',
                            },
                        },
                        {
                            code: 'workers',
                            icon: 'user',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_DEPARTMENT_WORKER,
                            ]),
                            name: 'Сотрудники',
                            route: {
                                name: 'Workers',
                            },
                        },
                        {
                            code: 'absenceSchedule',
                            icon: 'user',
                            isAllowed: this.$hasPermission([PERMISSIONS.CAN_VIEW_ABSENT_SCHEDULE]),
                            name: 'График отсутствий',
                            route: {
                                name: 'AbsenceSchedule',
                            },
                        },
                        // {
                        //     code: 'phones',
                        //     name: 'Телефоны',
                        // },
                        // {
                        //     code: 'keys',
                        //     name: 'Ключи',
                        // },
                        // {
                        //     code: 'incomingEmail',
                        //     name: 'Входящие письма',
                        // },
                        // {
                        //     code: 'outgoingEmails',
                        //     name: 'Исходящие письма',
                        // },
                        // {
                        //     code: 'powerOfAttorney',
                        //     name: 'Доверенности',
                        // },
                    ],
                })

                // Users -----------------------------------------------------

                menu.push({
                    code: 'user',
                    icon: 'user',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_AUTH_GROUP,
                    ]),
                    name: 'Пользователи',
                    children: [
                        {
                            code: 'userGroups',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_AUTH_GROUP,
                            ]),
                            name: 'Группы пользователей',
                            route: {
                                name: 'UserGroups',
                            },
                        },
                    ],
                })

                // Reports -----------------------------------------------------

                // menu.push({
                //     code: 'reports',
                //     name: 'Отчёты',
                //     icon: 'chartPie',
                //     children: [
                //         {
                //             code: 'tickets',
                //             name: 'Заявки',
                //         },
                //         {
                //             code: 'inspections',
                //             name: 'Обходы',
                //         },
                //         {
                //             code: 'guarantee',
                //             name: 'Гарантия',
                //         },
                //     ],
                // })

                // Telephony --------------------------------------------

                menu.push({
                    code: 'telephony',
                    icon: 'phoneOffice',
                    isAllowed: this.hasModulePermission([PERMISSION_MODULES.CALL]),
                    name: 'Телефония',
                    children: [
                        {
                            code: 'accident',
                            isAllowed: true, // this.$hasPermission([PERMISSIONS.CAN_VIEW_PERSONAL_ACCOUNT]),
                            name: 'Автоинформатор',
                            route: {
                                name: 'Accident',
                            },
                        },
                        {
                            code: 'phoneCalls',
                            isAllowed: true, // this.$hasPermission([PERMISSIONS.CAN_VIEW_PERSONAL_ACCOUNT]),
                            name: 'Звонки',
                            route: {
                                name: 'PhoneCalls',
                            },
                        },
                    ],
                })

                // Newsletters -------------------------------------------------

                menu.push({
                    code: 'newsletters',
                    icon: 'mailBulk',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.NEWSLETTER,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'NewslettersNavigator'),
                    name: 'Рассылки',
                    children: [
                        // {
                        //     code: 'email',
                        //     name: 'Email',
                        // },
                        {
                            code: 'push',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWSLETTER_PUSH_MESSAGE,
                            ]),
                            name: 'Push',
                            route: {
                                name: 'NewslettersPush',
                            },
                        },
                    ],
                })

                // User App ----------------------------------------------------

                menu.push({
                    code: 'user_app',
                    icon: 'pencilPaintbrush',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.CHAT_BOT,
                        PERMISSION_MODULES.DOCUMENT,
                        PERMISSION_MODULES.LOCATION,
                        PERMISSION_MODULES.NEWS,
                        PERMISSION_MODULES.SERVICE,
                    ]),
                    isOpen: this.$route.matched.some((o) => o.name === 'UserAppNavigator'),
                    name: 'Приложение жителя',
                    children: [
                        {
                            code: 'news',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS,
                            ]),
                            name: 'Новости',
                            route: {
                                name: 'UserAppNewsList',
                            },
                        },
                        {
                            code: 'quizzes',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS_QUIZ,
                            ]),
                            name: 'Опросы',
                            route: {
                                name: 'UserAppQuizzes',
                            },
                        },
                        {
                            code: 'services',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_SERVICE,
                            ]),
                            name: 'Сервисы',
                            route: {
                                name: 'UserAppServices',
                            },
                        },
                        {
                            code: 'cameras',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_CAMERA,
                            ]),
                            name: 'Камеры',
                            route: {
                                name: 'UserAppCameras',
                            },
                        },
                        {
                            code: 'stories',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_NEWS_STORY,
                            ]),
                            name: 'Stories',
                            route: {
                                name: 'UserAppStories',
                            },
                        },
                        {
                            code: 'legalDocuments',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LEGAL_DOCUMENT,
                            ]),
                            name: 'Правовые документы',
                            route: {
                                name: 'UserAppLegalDocuments',
                            },
                        },
                        {
                            code: 'chatBots',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_CHAT_BOT,
                            ]),
                            name: 'Чат-боты',
                            route: {
                                name: 'UserAppChatBots',
                            },
                        },
                    ],
                })

                // Компании ----------------------------------------------------

                menu.push({
                    code: 'companies',
                    icon: 'userTie',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_DEPARTMENT_COMPANY,
                    ]),
                    name: 'Компании',
                    route: {
                        name: 'Companies',
                    },
                })

                // ЖК ----------------------------------------------------------

                menu.push({
                    code: 'projects',
                    icon: 'city',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_LOCATION_PROJECT,
                    ]),
                    name: 'ЖК',
                    route: {
                        name: 'Projects',
                    },
                })

                // Дома --------------------------------------------------------

                menu.push({
                    code: 'buildings',
                    icon: 'house',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_LOCATION_BUILDING,
                    ]),
                    name: 'Дома',
                    route: {
                        name: 'Buildings',
                    },
                })

                // Хранение ключей ---------------------------------------------

                menu.push({
                    code: 'storage',
                    icon: 'warehouse',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.STORAGE,
                    ]),
                    name: 'Хранение',
                    children: [
                        {
                            code: 'storageKeyIssuance',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_KEY_ISSUANCE,
                            ]),
                            name: 'Выдачи',
                            route: {
                                name: 'StorageKeyIssuance',
                            },
                        },
                        {
                            code: 'storageKey',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_KEY,
                            ]),
                            name: 'Ключи',
                            route: {
                                name: 'StorageKey',
                            },
                        },
                        {
                            code: 'storageProxy',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_STORAGE_PROXY,
                            ]),
                            name: 'Доверенности',
                            route: {
                                name: 'StorageProxy',
                            },
                        },
                    ],
                })

                // Документы ---------------------------------------------------

                menu.push({
                    code: 'documents',
                    icon: 'fileInvoice',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_DOCUMENTS,
                    ]),
                    name: 'Документы',
                    children: [
                        {
                            code: 'premiseDocuments',
                            isAllowed: true,
                            name: 'Документы по квартире',
                            route: {
                                name: 'PremiseDocuments',
                            },
                        },
                        {
                            code: 'appealDocuments',
                            isAllowed: true,
                            name: 'Обращения',
                            route: {
                                name: 'AppealDocuments',
                            },
                        },
                        {
                            code: 'incomingDocuments',
                            isAllowed: true,
                            name: 'Входящие',
                            route: {
                                name: 'IncomingDocuments',
                            },
                        },
                        {
                            code: 'outgoingDocuments',
                            isAllowed: true,
                            name: 'Исходящие',
                            route: {
                                name: 'OutgoingDocuments',
                            },
                        },
                        {
                            code: 'commercialAppealDocuments',
                            isAllowed: true,
                            name: 'Коммерческие обращения',
                            route: {
                                name: 'CommercialAppealDocuments',
                            },
                        },
                    ],
                    route: {
                        name: 'PremiseDocuments',
                    },
                })

                // Отчеты ------------------------------------------------------

                menu.push({
                    code: 'analytics',
                    icon: 'analytics',
                    isAllowed: this.hasModulePermission([
                        PERMISSION_MODULES.ANALYTICS,
                    ]),
                    name: 'Отчеты',
                    children: [
                        {
                            code: 'serviceCompany',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_ANALYTICS,
                            ]),
                            name: 'Управляющая компания',
                            route: {
                                name: 'ServiceCompany',
                            },
                        },
                    ],
                })

                // Calendar -------------------------------------------------

                menu.push({
                    code: 'calendar',
                    icon: 'calendarDay',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_SLOT,
                    ]),
                    name: 'Календарь',
                    route: {
                        name: 'Calendar',
                    },
                })

                // Development -------------------------------------------------
                menu.push({
                    code: 'experimentalFeatures',
                    icon: 'code',
                    isAllowed: this.$hasPermission([
                        PERMISSIONS.CAN_VIEW_EXPERIMENTAL_FEATURES,
                    ]) || process.env.NODE_ENV === 'development',
                    name: 'Тестирование',
                    children: [
                        {
                            code: 'NotificationCenterTest',
                            isAllowed: true,
                            name: 'Центр уведомлений',
                            route: {
                                name: 'notificationCenterTest',
                            },
                        },
                        {
                            code: 'Cities',
                            isAllowed: this.$hasPermission([
                                PERMISSIONS.CAN_VIEW_LOCATION_CITY,
                            ]),
                            name: 'Города',
                            route: {
                                name: 'Cities',
                            },
                        },
                        {
                            code: 'FilterTest',
                            isAllowed: true,
                            name: 'Filter',
                            route: {
                                name: 'FilterTest',
                            },
                        },
                        {
                            code: 'ScrollTableTestScreen',
                            isAllowed: true,
                            name: 'Скролл таблицы',
                            route: {
                                name: 'ScrollTableTestScreen',
                            },
                        },
                    ],
                })

                const resolvedMenu = _map(menu, (menuItem1) => {
                    if (menuItem1.isAllowed) {
                        const children = _filter(menuItem1.children, (menuItem2) => {
                            return Boolean(menuItem2.isAllowed)
                        })

                        return { ...menuItem1, children }
                    }

                    return null
                })

                return _compact(resolvedMenu)
            },

            currentWorkerPhoto() {
                const photo = this.currentWorker?.user?.photo
                if (photo) {
                    // if photo url is not absolute
                    // eslint-disable-next-line prefer-regex-literals
                    const AbsoluteUrlRgExp = new RegExp('^(?:[a-z]+:)?//', 'i')
                    if (!AbsoluteUrlRgExp.test(photo.getSmall().url)) {
                        photo.getSmall().url = `${host}/${photo.getSmall().url}`
                    }
                }
                return photo
            },
        },

        watch: {
            isDesktop: {
                handler(isDesktop) {
                    this.toggleNavigationDrawer(isDesktop)
                },
                immediate: true,
            },
        },

        mounted() {
            this.startFetchNotificationsInterval()
        },

        methods: {
            getTicketStageByCode(stageCode) {
                return this.$store.getters['ticketStages/getTicketStageByCode'](stageCode)
            },

            startFetchNotificationsInterval() {
                if (this.notificationIntervalId) {
                    window.clearInterval(this.notificationIntervalId)
                    this.notificationIntervalId = null
                }

                this.fetchNotifications()
                this.notificationIntervalId = window.setInterval(() => {
                    this.fetchNotifications()
                }, 1000 * 60 * 5) // Обновление каждые 5 минут
            },

            async fetchNotifications() {
                try {
                    let response = await API.services.notification.getUnreadMessagesCount()

                    const { result } = response

                    // Новые [Назначен исполнитель]
                    this.assignedTicketsCount = result?.tickets_executor_appointed
                    this.assignedTicketsUnreadCount = result?.tickets_executor_appointed_unread

                    // В работе [В работе]
                    this.workTicketsCount = result?.tickets_in_progress
                    this.workTicketsUnreadCount = result?.tickets_in_progress_unread

                    // Принять [На проверке]
                    this.onCheckTicketsCount = result?.tickets_on_check
                    this.onCheckTicketsUnreadCount = result?.tickets_on_check_unread

                    // Принять [На доппроверке]
                    this.onAdditionalCheckTicketsCount = result?.tickets_on_additional_check
                    this.onAdditionalCheckTicketsUnreadCount = result?.tickets_on_additional_check_unread

                    // Назначить исполнителя
                    this.ticketsNeedExecutorUnread = result?.tickets_need_executor_unread || 0
                    this.ticketsNeedExecutor = result?.tickets_need_executor || 0

                    // Просроченные
                    this.newTicketsOverdueCount = result?.tickets_overdue_count || 0

                    // Наблюдаю
                    this.auditorTicketsCount = result?.tickets_audit_count || 0

                    // Назначить [Новые] (альтернативный метод)
                    const ticketStage = this.getTicketStageByCode('executor_appointed')

                    response = await API.services.ticket.getTickets({
                        ticket_stage__in: ticketStage?.id,
                        executors__in: this.currentWorker?.id,
                    })

                    this.newTicketsCount = response?.count || 0
                    this.newTicketsUnreadCount = 0 // response?.count || 0

                    // Получение количества согласований
                    const approvalParams = {
                        responsible__in: this.currentWorker?.id,
                        status__in: 'open',
                    }
                    const approvalResponse = await API.services.approval.getApprovals(approvalParams)
                    if (approvalResponse) {
                        this.approvalsCount = approvalResponse.count || 0
                    }
                } catch (e) {
                    //
                }
            },

            // TODO: remove simulate
            getRandomNotificationsCount(max) {
                return Math.floor(Math.random() * Math.floor(max))
            },

            toggleNavigationDrawer() {
                const navigationDrawer = this.navigationDrawer

                this.$store.commit('global/setNavigationDrawer', {
                    ...navigationDrawer,
                    isShown: this.isShownNavigationDrawer,
                })
            },

            onEnterMouseOnDrawer() {
                this.isEnteredMouseOnDrawer = true
                this.isLeftMouseOnDrawer = false
            },

            onLeaveMouseOnDrawer() {
                this.isEnteredMouseOnDrawer = false
                this.isLeftMouseOnDrawer = true
            },

            getAvatar(child) {
                let name = child.name

                name = name.replace(/[^A-Za-zА-Яа-я]/g, '')

                return name.substring(0, 2).toUpperCase()
            },

            hasModulePermission(checkPermissionModules) {
                const per = this.$store.getters['permissions/hasModulePermission'](checkPermissionModules)
                if (!per) {
                    console.log('PERMISSION MODULE ', checkPermissionModules, ': ', per)
                }
                return this.$store.getters['permissions/hasModulePermission'](checkPermissionModules)
            },
        },
    }
</script>
