import { chunk as _chunk, map as _map, compact as _compact } from 'lodash'

import API from '_api'
import { request } from '_api/base/behemoth/clientKokoc'

export const getCommercialAppealDocuments = async (params) => {
    const response = await request('GET', 'documents/CommercialAppealDocument', params)
    const responseChunks = _chunk(response.results, 25)
    const results = []

    for (const responseChunk of responseChunks) {
        const companyRecipientIds = _map(responseChunk, ({ company_recipient_id }) => company_recipient_id)
        const userIds = _map(responseChunk, ({ created_by }) => created_by?.user_id)

        const companies = await API.services.company.getCompanies({
            id__in: _compact(companyRecipientIds).join(','),
        })

        const users = await API.services.user.getUsers({
            id__in: _compact(userIds).join(','),
        })

        for (const response of responseChunk) {
            response.companyRecipient = companies.results.find((company) => company.id === response.company_recipient_id)
            response.user = users.results.find((user) => user.id === response.created_by?.user_id)

            results.push(response)
        }
    }

    response.results = results

    return response
}

export const getCommercialAppealDocumentById = async (commercialAppealDocumentId) => {
    const response = await request('GET', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`)
    const errors = []

    if (response.company_recipient_id) {
        response.companyRecipient = await API.services.company.getCompanyById(response.company_recipient_id).catch((err) => {
            errors.push(...err)
        })
    }

    if (response.created_by && response.created_by.user_id) {
        response.user = await API.services.user.getUserById(response.created_by.user_id).catch((err) => {
            errors.push(...err)
        })
    }

    response.errors = errors

    return response
}

export const updateCommercialAppealDocument = async (commercialAppealDocumentId, data) => {
    return await request('PATCH', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`, data)
}

export const createCommercialAppealDocument = async (data) => {
    return await request('POST', 'documents/CommercialAppealDocument/create', data)
}

export const deleteCommercialAppealDocument = async (commercialAppealDocumentId) => {
    return await request('DELETE', `documents/CommercialAppealDocument/${commercialAppealDocumentId}`)
}

export const getCommercialAppealDocumentTypes = async (params) => {
    return await request('GET', 'documents/CommercialAppealType', params)
}
