import Entity from '_entities/base/EntityNew'

import Doc from '_entities/media/Doc'

export const TicketDepartmentSchema = {
    name: 'TicketDepartment',
    primaryKey: 'id',
    properties: {
        code: { type: 'string' },
        color: { type: 'string', optional: true },
        id: { type: 'string' },
        instruction: { type: 'Doc', entity: Doc, optional: true },
        isInternal: { type: 'bool', default: false },
        name: { type: 'string' },
        order: { type: 'int' },
    },
}

class TicketDepartment extends Entity {
    get schema() {
        return TicketDepartmentSchema
    }

    get dataMap() {
        return {
            is_internal: 'isInternal',
        }
    }

    static fieldLabels = {
        code: 'Код',
        color: 'Цвет',
        instruction: 'Инструкция',
        id: 'ID',
        isInternal: 'Внутренний',
        name: 'Название',
        order: 'Порядок',
    }

    toArray() {
        return {
            ...super.toArray(),
            instruction: this.instruction?.id || null,
        }
    }
}

export default TicketDepartment
