import ServiceElement from '_entities/service/ServiceElement'

class ServiceElementRadio extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'radiobutton', () => 'radiobutton'],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
    }
}

export default ServiceElementRadio
