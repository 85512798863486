import { forEach as _forEach } from 'lodash'

import ServiceElement from '_entities/service/ServiceElement'

import Product from '_entities/service/element/Product'

class ServiceElementCatalog extends ServiceElement {
    get schema() {
        return {
            ...super.schema,
            elementType: ['element_type', 'catalog', () => 'catalog'],
            products: ['products', [], (value) => value.map((item) => new Product(item))],
        }
    }

    static fieldLabels = {
        ...super.fieldLabels,
        products: 'Товары',
    }

    toServer(data) {
        const products = []

        _forEach(data?.products, (product) => {
            products.push(product.toServer(product))
        })

        return {
            ...super.toServer(data),
            products: products,
        }
    }
}

export default ServiceElementCatalog
