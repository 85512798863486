import { request } from '_api/base/behemoth/client'

export const getCities = async (params) => {
    return await request('GET', 'location/city/', params)
}

export const getCityById = async (cityId) => {
    return await request('GET', `location/city/${cityId}/`)
}

export const updateCity = async (cityId, params) => {
    return await request('PUT', `location/city/${cityId}/`, params)
}

export const createCity = async (params) => {
    return await request('POST', 'location/city/', params)
}

export const deleteCity = async (cityId) => {
    return await request('DELETE', `location/city/${cityId}/`)
}
