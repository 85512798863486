import { request } from '_api/base/behemoth/client'

export const getAbsenceSchedules = async (params) => {
    return await request('GET', 'department/absent_schedule/', params)
}

export const getAbsenceScheduleById = async (absenceId) => {
    return await request('GET', `department/absent_schedule/${absenceId}/`)
}

export const updateAbsenceSchedule = async (absenceId, data) => {
    return await request('PUT', `department/absent_schedule/${absenceId}/`, data)
}

export const createAbsenceSchedule = async (data) => {
    return await request('POST', 'department/absent_schedule/', data)
}

export const deleteAbsenceSchedule = async (absenceId) => {
    return await request('DELETE', `department/absent_schedule/${absenceId}/`)
}

// Absence reason

export const getAbsenceReasons = async (params) => {
    return await request('GET', 'department/absent_reason/', params)
}

export const getAbsenceReasonById = async (absenceReasonId) => {
    return await request('GET', `department/absent_reason/${absenceReasonId}/`)
}

export const createAbsenceReason = async (data) => {
    return await request('POST', 'department/absent_reason/', data)
}
export const updateAbsenceReason = async (absenceReasonId, data) => {
    return await request('PUT', `department/absent_reason/${absenceReasonId}/`, data)
}

export const deleteAbsenceReason = async (absenceReasonId) => {
    return await request('DELETE', `department/absent_reason/${absenceReasonId}/`)
}
