<template>
    <v-autocomplete
        v-bind="$attrs"
        v-model="value"
        clearable
        :hide-details="hideDetails"
        :items="pushMessageTypes"
        item-value="code"
        item-text="name"
        label="Тип сообщения"
        outlined
    />
</template>

<script>
    import PushMessage from '_entities/pushMessage/PushMessage'

    export default {
        name: 'CPushMessageTypesSelect',

        props: {
            hideDetails: { type: Boolean },
            multiple: { type: Boolean },
            value: { type: [Array, String] },
        },

        computed: {
            pushMessageTypes() {
                return PushMessage.types
            },
        },

        watch: {
            // v-model
            value() {
                this.onInput()
            },
        },

        methods: {
            // v-model
            onInput() {
                this.$emit('input', this.value)
            },
        },
    }
</script>
