import API from '_api'

import UserOld from '_entities/user/UserOld'

const actions = {
    async fetchCallerInfo({ commit, state }, { callerId, callerPhone }) {
        commit('setCallerInfo', { callerId, callerPhone })

        if (!state.isFetching && callerId) {
            commit('fetchResidentRequest')

            try {
                const response = await API.services.user.getResidentById(callerId)

                const resident = new UserOld(response)

                commit('fetchResidentSuccess', { resident })
            } catch (e) {
                commit('fetchResidentError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
