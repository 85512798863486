import { PERMISSIONS } from '_features/permissions/constants'

import NewslettersNavigator from '_navigations/Home/Newsletters/NewslettersNavigator'
import PushMessagesNavigator from '_navigations/Home/Newsletters/PushMessagesNavigator'

import PushMessagesScreen from '_screens/Home/Newsletters/Push/PushMessagesScreen'
import PushMessageEditScreen from '_screens/Home/Newsletters/Push/PushMessageEditScreen'

const newslettersRoutes = [
    {
        path: 'newsletters',
        name: 'NewslettersNavigator',
        redirect: { name: 'NewslettersPush' },
        component: NewslettersNavigator,
        children: [
            {
                path: 'push',
                component: PushMessagesNavigator,
                children: [
                    {
                        path: '',
                        name: 'NewslettersPush',
                        component: PushMessagesScreen,
                        meta: {
                            screenOptions: {
                                title: 'Push рассылки',
                            },
                            permissions: [PERMISSIONS.CAN_VIEW_NEWSLETTER_PUSH_MESSAGE],
                        },
                    },
                    {
                        path: 'edit/:pushMessageId?',
                        name: 'NewslettersPushEdit',
                        component: PushMessageEditScreen,
                        meta: {
                            showBack: true,
                            screenOptions: {
                                title: 'Push рассылка',
                            },
                            permissions: [PERMISSIONS.CAN_CHANGE_NEWSLETTER_PUSH_MESSAGE],
                        },
                    },
                ],
            },
        ],
    },
]

export default newslettersRoutes
