import { forEach as _forEach } from 'lodash'
import { v4 as uuidV4 } from 'uuid'

import Entity from '_entities/base/Entity'

import ServiceElementVariant from '_entities/service/ServiceElementVariant'

class ServiceElement extends Entity {
    get schema() {
        return {
            id: ['id', uuidV4(), (value) => value], // UUID
            title: ['title', undefined, (value) => value], // String
            elementType: ['element_type', undefined, (value) => value], // String
            isRequired: ['is_required', undefined, (value) => !!value], // Boolean
            dependsOnElement: ['depends_on_element', undefined, (value) => value], // UUID
            dependsOnVariants: ['depends_on_variants', [], (value) => value], // String[]
            order: ['order', undefined, (value) => this.getIntegerValue(value)], // Integer
            variants: ['variants', [], (value) => value.map((item) => new ServiceElementVariant(item))],
            // Default
            default: ['default', undefined, (value) => value], // Any
            defaults: ['defaults', [], (value) => value], // Any[]
        }
    }

    static fieldLabels = {
        id: 'ID',
        title: 'Заголовок',
        elementType: 'Тип',
        isRequired: 'Элемент обязателен для заполнения',
        dependsOnElement: 'Элемент показывается, если этот элемент заполнен',
        dependsOnVariants: 'Элемент показывается, если какое-либо значение из списка выбрано',
        variants: 'Варианты',
        // Default
        default: 'Значение по умолчанию',
        defaults: 'Значения по умолчанию',
        timezone: 'Часовой пояс',
        accountStatus: 'Код статуса лицевого счёта для показа',
    }

    static elementTypes = [
        { code: 'dropdown', name: 'Выпадающий список' },
        { code: 'date', name: 'Дата' },
        { code: 'time', name: 'Время' },
        { code: 'datetime', name: 'Дата / Время' },
        { code: 'title_1', name: 'Заголовок 1' },
        { code: 'title_2', name: 'Заголовок 2' },
        { code: 'upload_images', name: 'Загрузка фотографий' },
        { code: 'upload_files', name: 'Загрузка видео' },
        { code: 'catalog', name: 'Каталог' },
        { code: 'textinput', name: 'Поле ввода' },
        { code: 'text', name: 'Текст' },
        { code: 'calendar_event', name: 'Событие календаря' },
        { code: 'files', name: 'Файлы' },
        { code: 'checkbox', name: 'Checkbox' },
        { code: 'checkbox_tag', name: 'Checkbox (Tag)' },
        { code: 'radiobutton', name: 'Radio' },
        { code: 'radiobutton_with_image', name: 'Radio (с изображением)' },
        { code: 'push_message', name: 'PUSH уведомление' },
        { code: 'send_message', name: 'Отправить сообщение' },
    ]

    toServer(data) {
        const variants = []

        _forEach(data?.variants, (variant, index) => {
            variant.order = index + 1
            variants.push(variant.toServer(variant))
        })

        return {
            id: data?.id,
            title: data?.title,
            element_type: data?.elementType,
            is_required: data?.isRequired,
            depends_on_element: data?.dependsOnElement || null,
            depends_on_variants: data?.dependsOnVariants,
            order: data?.order,
            variants,
            default: data?.default || '',
            defaults: data?.defaults,
        }
    }
}

export default ServiceElement
