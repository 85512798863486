import { request } from '_api/base/behemoth/client'

export const createTicketKind = async (params) => {
    return await request('POST', 'ticket/kind/', params)
}

export const deleteTicketKind = async (ticketKindId) => {
    return await request('DELETE', `ticket/kind/${ticketKindId}/`)
}

export const getTicketKinds = async (params) => {
    return await request('GET', 'ticket/kind/', params)
}

export const getTicketKindById = async (ticketKindId) => {
    return await request('GET', `ticket/kind/${ticketKindId}/`)
}

export const updateTicketKind = async (ticketKindId, params) => {
    return await request('PUT', `ticket/kind/${ticketKindId}/`, params)
}
