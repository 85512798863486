import API from '_api'

import Building from '_entities/building/Building'

const actions = {
    async fetchBuildings({ commit, state }, params) {
        if (!state.isFetching) {
            commit('fetchBuildingsRequest')

            try {
                const response = await API.services.building.getBuildings(params)

                const { results } = response

                const items = []
                results.forEach((result) => {
                    items.push(new Building(result))
                })

                commit('fetchBuildingsSuccess', { items })
            } catch (e) {
                commit('fetchBuildingsError', e)

                return Promise.reject(e)
            }
        }
    },
}

export default actions
