import { forEach as _forEach, map as _map } from 'lodash'

import Entity from '_entities/base/Entity'

import CompanyEmail from '_entities/company/CompanyEmail'
import CompanyPhone from '_entities/company/CompanyPhone'
import CompanyRequisite from '_entities/company/CompanyRequisite'
import Photo from '_entities/media/Photo'
import ServiceLink from '_entities/service/ServiceLink'
import NewsLink from '_entities/news/NewsLink'

class Company extends Entity {
    get schema() {
        return {
            id: ['id', undefined, (value) => value], // UUID
            name: ['name', undefined, (value) => value], // String
            logo: ['logo', undefined, (value) => value ? new Photo(value) : null], // Photo
            address: ['address', undefined, (value) => value], // String
            companyType: ['company_type', undefined, (value) => value], // String
            coordinates: [
                'coordinates', {
                    lat: undefined,
                    lon: undefined,
                }, (value) => this.getCoordinatesValue(value),
            ], // String
            workTime: ['work_time', undefined, (value) => this.getWorkTimeValue(value)],
            instagramLink: ['instagram_link', undefined, (value) => value], // String
            vkLink: ['vk_link', undefined, (value) => value], // String
            siteLink: ['site_link', undefined, (value) => value], // String
            phones: ['phones', [], (value) => value.map((item) => this.getEntityValue(CompanyPhone, item))],
            emails: ['emails', [], (value) => value.map((item) => this.getEntityValue(CompanyEmail, item))],
            serviceForMessage: ['service_for_message', undefined, (value) => this.getEntityValue(ServiceLink, value)],
            greetingNews: ['greeting_news', undefined, (value) => this.getEntityValue(NewsLink, value)],
            requisite: ['requisite', undefined, (value) => this.getEntityValue(CompanyRequisite, value)],
        }
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Название',
        logo: 'Логотип',
        address: 'Адрес',
        companyType: 'Тип компании',
        coordinates: 'Координаты',
        lat: 'Широта',
        lon: 'Долгота',
        workTime: 'Время работы',
        instagramLink: 'Instagram',
        vkLink: 'ВКонтакте',
        siteLink: 'Сайт',
        phones: 'Телефоны',
        emails: 'Email',
        serviceForMessage: 'Сервис - Написать в УК/застройщику',
        greetingNews: 'Приветственная новость',
    }

    getWorkTimeValue(value) {
        const workTime = {
            monday: {
                label: 'Понедельник',
                end: null,
                start: null,
            },
            tuesday: {
                label: 'Вторник',
                end: null,
                start: null,
            },
            wednesday: {
                label: 'Среда',
                end: null,
                start: null,
            },
            thursday: {
                label: 'Четверг',
                end: null,
                start: null,
            },
            friday: {
                label: 'Пятница',
                end: null,
                start: null,
            },
            saturday: {
                label: 'Суббота',
                end: null,
                start: null,
            },
            sunday: {
                label: 'Воскресенье',
                end: null,
                start: null,
            },
        }

        _forEach(value, (item, key) => {
            if (item) {
                workTime[key] = {
                    ...workTime[key],
                    ...item,
                }
            }
        })

        return workTime
    }

    toServer() {
        let coordinates
        if (this.coordinates?.lat && this.coordinates?.lon) {
            coordinates = `${this.coordinates.lat}:${this.coordinates.lon}`
        }

        const workTime = {}
        _forEach(this.workTime, (item, key) => {
            if (item.start && item.end) {
                workTime[key] = {
                    start: item.start,
                    end: item.end,
                }
            } else {
                workTime[key] = null
            }
        })

        return {
            name: this.name,
            address: this.address,
            company_type: this.companyType,
            logo: this.logo?.id,
            coordinates,
            work_time: workTime,
            instagram_link: this.instagramLink,
            vk_link: this.vkLink,
            site_link: this.siteLink,
            // TODO: this.greetingNews должен быть Entity: Service. Сейчас там ID.
            service_for_message: this.serviceForMessage,
            // TODO: this.greetingNews должен быть Entity: News. Сейчас там ID.
            greeting_news: this.greetingNews,
            phones: _map(this.phones, (item) => item.toServer()),
            emails: _map(this.emails, (item) => item.toServer()),
            requisite: this.requisite?.toServer(this.requisite),
        }
    }
}

export default Company
