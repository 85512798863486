import Entity from '_entities/base/EntityNew'

export const PremiseLinkSchema = {
    name: 'PremiseLink',
    primaryKey: 'id',
    properties: {
        id: { type: 'string' },
        name: { type: 'string' },
        number: { type: 'string' },
        shortName: { type: 'string' },
    },
}
class PremiseLink extends Entity {
    get schema() {
        return PremiseLinkSchema
    }

    static fieldLabels = {
        id: 'ID',
        name: 'Адрес',
        number: 'Номер',
        shortName: 'Короткий адрес',
    }
}

export default PremiseLink
