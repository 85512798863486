import { request } from '_api/base/behemoth/client'

export const getCameras = async (params) => {
    return await request('GET', 'camera/', params)
}
export const getCameraById = async (cameraId) => {
    return await request('GET', `camera/${cameraId}/`)
}

export const createCamera = async ({ data }) => {
    return await request('POST', 'camera/', data)
}

export const updateCamera = async ({ cameraId, data }) => {
    return await request('PUT', `camera/${cameraId}/`, data)
}

export const deleteCamera = async (cameraId) => {
    return await request('DELETE', `camera/${cameraId}/`)
}

export const getCameraGroups = async (params) => {
    return await request('GET', 'camera/group/', params)
}
export const getCameraGroupById = async (cameraGroupId) => {
    return await request('GET', `camera/group/${cameraGroupId}/`)
}

export const createCameraGroup = async ({ data }) => {
    return await request('POST', 'camera/group/', data)
}

export const updateCameraGroup = async ({ cameraGroupId, data }) => {
    return await request('PUT', `camera/group/${cameraGroupId}/`, data)
}

export const deleteCameraGroup = async (cameraGroupId) => {
    return await request('DELETE', `camera/group/${cameraGroupId}/`)
}
